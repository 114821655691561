const IconTrash = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.56217 1.76953L8.43717 1.76953C8.66729 1.76953 8.85384 1.95608 8.85384 2.1862C8.85384 2.41632 8.66729 2.60286 8.43717 2.60286L1.56217 2.60287C1.33206 2.60287 1.14551 2.41632 1.14551 2.1862C1.14551 1.95608 1.33206 1.76953 1.56217 1.76953Z"
      fill="#303B57"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.06217 3.64453C4.29229 3.64453 4.47884 3.83108 4.47884 4.0612V6.5612C4.47884 6.79132 4.29229 6.97786 4.06217 6.97786C3.83206 6.97786 3.64551 6.79132 3.64551 6.5612V4.0612C3.64551 3.83108 3.83206 3.64453 4.06217 3.64453Z"
      fill="#303B57"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.93717 3.64453C6.16729 3.64453 6.35384 3.83108 6.35384 4.0612V6.5612C6.35384 6.79132 6.16729 6.97786 5.93717 6.97786C5.70706 6.97786 5.52051 6.79132 5.52051 6.5612V4.0612C5.52051 3.83108 5.70706 3.64453 5.93717 3.64453Z"
      fill="#303B57"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.18717 1.76953C2.41729 1.76953 2.60384 1.95608 2.60384 2.1862V8.01953H7.39551V2.1862C7.39551 1.95608 7.58206 1.76953 7.81217 1.76953C8.04229 1.76953 8.22884 1.95608 8.22884 2.1862V8.1237C8.22884 8.31709 8.15202 8.50255 8.01527 8.6393C7.87853 8.77604 7.69306 8.85286 7.49967 8.85286H2.49967C2.30629 8.85286 2.12082 8.77604 1.98408 8.6393C1.84733 8.50255 1.77051 8.31709 1.77051 8.1237V2.1862C1.77051 1.95608 1.95706 1.76953 2.18717 1.76953Z"
      fill="#303B57"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32561 0.824628C3.52096 0.629278 3.78591 0.519531 4.06217 0.519531H5.93717C6.21344 0.519531 6.47839 0.629278 6.67374 0.824628C6.86909 1.01998 6.97884 1.28493 6.97884 1.5612V2.1862C6.97884 2.41632 6.79229 2.60286 6.56217 2.60286C6.33206 2.60286 6.14551 2.41632 6.14551 2.1862V1.5612C6.14551 1.50594 6.12356 1.45295 6.08449 1.41388C6.04542 1.37481 5.99243 1.35286 5.93717 1.35286H4.06217C4.00692 1.35286 3.95393 1.37481 3.91486 1.41388C3.87579 1.45295 3.85384 1.50594 3.85384 1.5612V2.1862C3.85384 2.41632 3.66729 2.60286 3.43717 2.60286C3.20706 2.60286 3.02051 2.41632 3.02051 2.1862V1.5612C3.02051 1.28493 3.13025 1.01998 3.32561 0.824628Z"
      fill="#303B57"
    />
  </svg>
);

export default IconTrash;
