import {
  IFuelingCommentViewModel,
  TFuelingCommentEditState,
  TFuelingCommentState,
} from "./__types__/IFuelingCommentViewModel.types";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
import { FuelingsRepository } from "@repositories/fuelings";
import { IFuelingsRepository } from "@repositories/fuelings/__types__/repository";

import { AxiosRequestClient } from "@modules/request/libs/axios";
import { RequestStatus } from "@constants/repositories";
import { observable, runInAction } from "mobx";
import _ from "lodash";
import { FuelingCommentStatus, FuelingType } from "@constants/fueling";
import { TranslationService } from "@services/translate";

/**
 * Represents the view model for the fueling comment functionality.
 */
export class FuelingCommentViewModel implements IFuelingCommentViewModel {
  /**
   * The possible statuses for the view model.
   */
  public statuses: Statuses = new Statuses([
    "fetchFuelingComment",
    "declineFuelingComment",
    "saveEditState",
  ]);

  /**
   * The state of the fueling comment.
   */
  public state: TFuelingCommentState;

  /**
   * The edit state of the fueling comment.
   */
  public editState: TFuelingCommentEditState;

  private repository: IFuelingsRepository = new FuelingsRepository(
    new AxiosRequestClient()
  );

  /**
   * Constructs a new instance of the FuelingCommentViewModel class.
   *
   * @param model - The root tree model.
   */
  public constructor(private model: IRootTreeModel) {
    this.state = observable(this.initialState);

    this.editState = observable({
      fuelingRequest: {
        coefficient: this.initialState.fuelingRequest.coefficient,
        contractor: null,
        fuelingType: this.initialState.fuelingRequest.fuelingType,
        number: this.initialState.fuelingRequest.number,
        region: null,
        vehicle: null,
        volume: this.initialState.fuelingRequest.volume,
        weight: this.initialState.fuelingRequest.weight,
      },
      status: 0,
    });
  }

  private get initialState(): TFuelingCommentState {
    return {
      comment: "",
      file: {
        mime: "",
        size: 0,
        url: "",
      },
      fuelingRequest: {
        coefficient: 0,
        contractor: null,
        fuelingType: 1,
        number: "",
        region: null,
        vehicle: null,
        volume: "0",
        weight: "0",
      },
      id: 0,
      status: 0,
    };
  }

  /**
   * Sets the fueling number in the edit state.
   *
   * @param number - The fueling number.
   */
  public setFuelingNumber = (number: string): void => {
    runInAction(() => {
      this.editState.fuelingRequest.number = number;
    });
  };

  /**
   * Sets the vehicle in the edit state.
   *
   * @param vehicle - The vehicle data.
   */
  public setVehicle = (
    vehicle: { id: number; number: string } | null
  ): void => {
    runInAction(() => {
      this.editState.fuelingRequest.vehicle = vehicle;
    });
  };

  /**
   * Sets the weight in the edit state.
   *
   * @param weight - The weight data.
   */
  public setWeight = (weight: string): void => {
    runInAction(() => {
      this.editState.fuelingRequest.weight = weight;
    });
  };

  /**
   * Sets the volume in the edit state.
   *
   * @param volume - The volume data.
   */
  public setVolume = (volume: string): void => {
    runInAction(() => {
      this.editState.fuelingRequest.volume = volume;
    });
  };

  /**
   * Sets the coefficient in the edit state.
   *
   * @param volume - The volume data.
   */
  public setCoefficient = (coefficient: number): void => {
    runInAction(() => {
      this.editState.fuelingRequest.coefficient = coefficient;
    });
  };

  /**
   * Sets the fueling type in the edit state.
   *
   * @param type - The fueling type.
   */
  public setFuelingType = (type: number): void => {
    runInAction(() => {
      this.editState.fuelingRequest.fuelingType = type;
    });
  };

  /**
   * Sets the contractor in the edit state.
   *
   * @param data - The contractor data.
   */
  public setContractor = (data: { id: number; name: string } | null): void => {
    runInAction(() => {
      this.editState.fuelingRequest.contractor = data;
    });
  };

  /**
   * Sets the region in the edit state.
   *
   * @param data - The region data.
   */
  public setRegion = (data: { id: number; name: string } | null): void => {
    runInAction(() => {
      this.editState.fuelingRequest.region = data;
    });
  };

  /**
   * Fetches the fueling comment data.
   *
   * @param id - The fueling comment ID.
   */
  public fetchCommentFuelingData = async (id: number): Promise<void> => {
    try {
      this.statuses.setStatus("fetchFuelingComment", RequestStatus.Pending);

      const response = await this.repository.getFuelingComment(id);

      runInAction(() => {
        _.mergeWith(this.state, {
          comment: response.comment,
          file: {
            mime: response.file?.mime,
            size: response.file?.size,
            url: response.file?.url,
          },
          fuelingRequest: {
            contractor: response.fuelingRequest.contractor
              ? {
                  id: response.fuelingRequest.contractor.id,
                  name: response.fuelingRequest.contractor.name,
                }
              : null,
            fuelingType: response.fuelingRequest.fuelingType,
            number: response.fuelingRequest.number,
            region: response.fuelingRequest.region
              ? {
                  id: response.fuelingRequest.region.id,
                  name: response.fuelingRequest.region.name,
                }
              : null,
            vehicle: response.fuelingRequest.vehicle
              ? {
                  id: response.fuelingRequest.vehicle.id,
                  number: response.fuelingRequest.vehicle.number,
                }
              : null,
            volume: response.fuelingRequest.volume,
            weight: response.fuelingRequest.weight,
          },
          id: response.id,
          status: response.status,
        } as TFuelingCommentState);

        _.merge(this.editState, {
          comment: response.comment,
          file: undefined,
          fuelingRequest: {
            coefficient: response.fuelingRequest.coefficient,
            contractor: response.fuelingRequest.contractor,
            fuelingType: response.fuelingRequest.fuelingType,
            number: response.fuelingRequest.number,
            region: response.fuelingRequest.region,
            vehicle: response.fuelingRequest.vehicle,
            volume: response.fuelingRequest.volume,
            weight: response.fuelingRequest.weight,
          },
          id: response.id,
          status: response.status,
        } as TFuelingCommentEditState);
      });
      this.statuses.setStatus("fetchFuelingComment", RequestStatus.Success);
    } catch (error) {
      this.statuses.setStatus("fetchFuelingComment", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * Declines the fueling comment.
   *
   * @param id - The fueling comment ID.
   * @returns The ID of the declined fueling comment.
   */
  public declineFuelingComment = async (
    id: number
  ): Promise<{ id: number }> => {
    try {
      this.statuses.setStatus("declineFuelingComment", RequestStatus.Pending);

      await this.repository.editFuelingComment(id, {
        status: FuelingCommentStatus.Declined,
      });

      runInAction(() => {
        _.merge(this.state, { status: FuelingCommentStatus.Declined });

        _.merge(this.editState, { status: FuelingCommentStatus.Declined });
      });

      this.statuses.setStatus("declineFuelingComment", RequestStatus.Success);

      return { id };
    } catch (error) {
      this.statuses.setStatus("declineFuelingComment", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * Saves the edit state of the fueling comment.
   *
   * @returns The ID of the saved fueling comment.
   */
  public saveEditState = async (): Promise<{ id: number }> => {
    try {
      this.statuses.setStatus("saveEditState", RequestStatus.Pending);

      if (!this.editState.fuelingRequest.vehicle) {
        throw new Error(TranslationService.t("txt_wrong_vehicle_format"));
      }

      if (
        !this.editState.fuelingRequest.number ||
        this.editState.fuelingRequest.number === null
      ) {
        throw new Error(
          TranslationService.t("txt_wrong_fueling_number_format")
        );
      }

      if (
        !this.editState.fuelingRequest.weight ||
        Number(this.editState.fuelingRequest.weight) <= 0
      ) {
        throw new Error(TranslationService.t("txt_wrong_weight_format"));
      }

      if (
        !this.editState.fuelingRequest.volume ||
        Number(this.editState.fuelingRequest.volume) <= 0
      ) {
        throw new Error(TranslationService.t("txt_wrong_volume_format"));
      }
      if (
        !([FuelingType.General, FuelingType.Transfer] as any[]).includes(
          this.editState.fuelingRequest.fuelingType
        )
      ) {
        throw new Error(TranslationService.t("txt_wrong_fueling_type_format"));
      }

      if (!this.editState.fuelingRequest.contractor)
        throw new Error(TranslationService.t("txt_error_missing_contractor"));

      if (!this.editState.fuelingRequest.region)
        throw new Error(TranslationService.t("txt_error_missing_region"));
      
      await this.repository.editFuelingComment(this.state.id, {
        fuelingRequest: {
          coefficient: this.editState.fuelingRequest.coefficient,
          contractor: this.editState.fuelingRequest.contractor.id,
          fuelingType: this.editState.fuelingRequest.fuelingType,
          number: this.editState.fuelingRequest.number,
          region: this.editState.fuelingRequest.region.id,
          vehicle: this.editState.fuelingRequest.vehicle.id,
          volume: this.editState.fuelingRequest.volume,
          weight: this.editState.fuelingRequest.weight,
        },
        status: FuelingCommentStatus.Fixed,
      });

      this.statuses.setStatus("saveEditState", RequestStatus.Success);

      return { id: this.state.id };
    } catch (error) {
      this.statuses.setStatus("saveEditState", RequestStatus.Error);

      throw error;
    }
  };

  public beforeDestroy(): void {
    this.repository.beforeDestroy();
  }
}
