import { TDtoFuelingTotalsEntry } from "./__types__/TDtoFuelingTotalsEntry.types";
import { TParseDto } from "../__types__/dto";

export const DtoFuelingTotalsParser: TParseDto<TDtoFuelingTotalsEntry> = (
  data: any
): TDtoFuelingTotalsEntry => ({
  totals: {
    totalVolume: data.totals?.totalVolume
      ? String(data.totals?.totalVolume)
      : "0",
    totalWeight: data.totals?.totalWeight
      ? String(data.totals?.totalWeight)
      : "0",
  },
});
