import { INotificationsRepository } from "./__types__/repository";
import { NotificationsRepositoryResponseDto } from "./dtoResponse";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import moment from "moment";
import { TDtoNotificationListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoNotificationListItemEntry.types";

export class NotificationsRepository implements INotificationsRepository {
  public readonly abortControllers = {
    getNotificationsList: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getNotificationsList = async (params: {
    date_from?: number;
    date_to?: number;
    asc?: boolean; // sort by field direction
    page?: number; // page to load
    limit?: number; // number of item per page
    order?: string;
    direction?: "asc" | "desc";
  }): Promise<TDtoPaginationEntry<TDtoNotificationListItemEntry>> => {
    if (this.abortControllers.getNotificationsList) {
      this.abortControllers.getNotificationsList.abort();
    }
    this.abortControllers.getNotificationsList = new AbortController();

    const response: any = await this.requestClient.get(`/notification`, {
      params: {
        asc: params.direction === "asc",
        // deliveryDate:
        //   params.date_from && params.date_to
        //     ? {
        //         end: moment(params.date_to).format("YYYY-MM-DD"),
        //         start: moment(params.date_from).format("YYYY-MM-DD"),
        //       }
        //     : undefined,
        end: moment(params.date_to).format("YYYY-MM-DD"),
        limit: params.limit,
        order: params.order,
        page: params.page,
        start: moment(params.date_from).format("YYYY-MM-DD"),
      },
      signal: this.abortControllers.getNotificationsList.signal,
    });

    return NotificationsRepositoryResponseDto.getListOfNotification(response);
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
