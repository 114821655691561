import { IScreenControllerProfile } from "@screens/ProfileScreen/connector/__types__/UIViewController";
import { IconUpload } from "@shared/controls/icons";
import { useControllers } from "@view/hooks/useControllers";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { observer } from "mobx-react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

export const ProfileImageForm = observer(() => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerProfile;
  }>();

  const windowWidth = useWindowWidth();

  const [files, setFiles] = useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      UIViewController.setImage(acceptedFiles[0]);
      UIViewController.saveProfileImageInfo();
      if (acceptedFiles[0].size < 1048576) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      } else setFiles([]);
    },
  });

  return (
    <FlexGroup>
      {UIViewController.profileState.image ? (
        <aside>
          <div key={files[0]?.name}>
            <RowPreview>
              <img
                src={files[0]?.preview || UIViewController.profileState.image}
                onLoad={() => {
                  URL.revokeObjectURL(files[0]?.preview);
                }}
              />
              <div className="col">
                <p>Фотография загружена</p>
                <ChangePhoto {...getRootProps()}>
                  <input {...getInputProps()} type={"file"} />
                  <IconUpload />
                  <p>Загрузить новую</p>
                </ChangePhoto>
              </div>
            </RowPreview>
          </div>
        </aside>
      ) : (
        <div {...getRootProps()}>
          {!files.length && (
            <>
              <input {...getInputProps()} type={"file"} />
              <RowPreview>
                <ImagePreview>
                  <IconUpload />
                </ImagePreview>
                <div className="col">
                  <p>
                    Загрузите {windowWidth > 768 ? "вашу" : null} фотографию
                  </p>
                  {windowWidth > 768 ? (
                    <p>
                      Чтобы загрузить изображение, нажмите на иконку
                      {windowWidth > 1440 ? <br /> : <></>} или перетащите файл
                      в серую область
                    </p>
                  ) : (
                    <p>PNG или JPG</p>
                  )}
                </div>
              </RowPreview>
            </>
          )}
        </div>
      )}
    </FlexGroup>
  );
});

const FlexGroup = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 60px;
  padding-left: 60px;
  width: calc(50% - 60px);
  height: fit-content;

  @media (max-width: 1024px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const RowPreview = styled(Row)`
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  @media (max-width: 768px) {
    align-items: flex-start;
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 6px;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  .col {
    gap: 8px;
    p: first-child {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;

      @media (max-width: 768px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
      }
    }
    p:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

const ImagePreview = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f2f8;
  border-radius: 6px;
  padding: 19px;

  svg {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const ChangePhoto = styled.div`
  display: flex;
  flex-direction: row;
  background: #dbdce1;
  height: 40px;
  align-items: center;
  gap: 8px;
  padding: 0 16px 0 12px;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
`;
