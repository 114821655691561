import {
  IFuelingRequestCommentParams,
  IFuelingRequestCommentState,
  IFuelingRequestCommentViewModel,
} from "./__types__/IFuelingRequestCommentViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
import { FuelingsRepository } from "@repositories/fuelings";
import { IFuelingsRepository } from "@repositories/fuelings/__types__/repository";

import { TranslationService } from "@services/translate";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import { AxiosRequestClient } from "@modules/request/libs/axios";

export class FuelingRequestCommentViewModel
  implements IFuelingRequestCommentViewModel
{
  // Instantiate the `statuses` property with a new `Statuses` object
  public statuses: Statuses = new Statuses(["createFuelingComment"]);

  // Observable state for the view model
  public state: IFuelingRequestCommentState = observable(this.initialState);

  // Inject the fuelings repository into the view model
  private repository: IFuelingsRepository = new FuelingsRepository(
    new AxiosRequestClient()
  );

  // Constructor for the view model
  public constructor(
    private model: IRootTreeModel,
    params: IFuelingRequestCommentParams
  ) {
    // Merge the initial state with the provided params
    this.state = observable({ ...this.initialState, ...params });
  }

  // Get the initial state for the view model
  private get initialState(): IFuelingRequestCommentState {
    return {
      comment: "", // The comment text
      file: undefined, // The selected file (optional)
      fuelingRequest: 0, // The ID of the fueling request
    };
  }

  // Set the comment text
  public setComment = (comment: string): void => {
    this.state.comment = comment;
  };

  // Set the fueling request ID
  public setFuelingRequest = (id: number): void => {
    this.state.fuelingRequest = id;
  };

  // Set the selected file
  public setFile = (file: File | undefined): void => {
    this.state.file = file;
  };

  // Create a fueling comment
  public createFuelingComment = async (
    data: IFuelingRequestCommentState
  ): Promise<void> => {
    try {
      if (!data.comment.length) {
        throw new Error(TranslationService.t("txt_wrong_comment_format"));
      }

      // Set the status of the createFuelingComment action to "Pending"
      this.statuses.setStatus("createFuelingComment", RequestStatus.Pending);

      // Call the repository method to create the comment
      await this.repository.createFuelingComment({
        comment: data.comment,
        file: data.file,
        fuelingRequest: data.fuelingRequest,
      });

      // Set the status of the createFuelingComment action to "Success"
      this.statuses.setStatus("createFuelingComment", RequestStatus.Success);
    } catch (error) {
      // Set the status of the createFuelingComment action to "Error"
      this.statuses.setStatus("createFuelingComment", RequestStatus.Error);

      // Rethrow the error to be handled by the caller
      throw error;
    }
  };

  // Clear the state of the view model
  public clearState(): void {
    this.state = this.initialState;
  }

  // Cleanup function before destroying the view model instance
  public beforeDestroy(): void {
    // Clean up any subscriptions or reactions here
    // Object.values(this.reactions).forEach((r) => r());

    // Clean up the repository instance
    this.repository.beforeDestroy();
  }
}
