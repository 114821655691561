/* eslint-disable unused-imports/no-unused-vars */
import { IReportsRepository } from "./__types__/repository";
import { ReportsResponseDto } from "./dtoResponse";
import { TDtoFuelsByRegionEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelsByRegionEntry.types";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import { TDtoFuelingTurnoverListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTurnoverListItemEntry.types";
import { TDtoFuelingTurnoverContractListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTurnoverContractListItemEntry.types";
import moment from "moment";

export class ReportsRepository implements IReportsRepository {
  public readonly abortControllers = {
    createFuelTransfer: new AbortController(),
    getFuelingTurnover: new AbortController(),
    getFuelingTurnoverContract: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  // @ts-ignore
  public getFuelsByDeal(params: {
    page: number;
    per_page: number;
    deal_number?: number | undefined;
    provider_id?: number | undefined;
    // @ts-ignore
  }): Promise<TDtoPaginationEntry<TDtoFuelsByRegionEntry>> {}

  // @ts-ignore
  public getFuelsByKVS(params: {
    page: number;
    per_page: number;
    pilot_id?: number | undefined;
    // @ts-ignore
  }): Promise<TDtoPaginationEntry<TDtoFuelsByRegionEntry>> {}

  // @ts-ignore
  public getFuelsByRegion(params: {
    page: number;
    per_page: number;
    region_id?: number | undefined;
    // @ts-ignore
  }): Promise<TDtoPaginationEntry<TDtoFuelsByRegionEntry>> {}

  // @ts-ignore
  public getFuelsByVS(params: {
    page: number;
    per_page: number;
    board_number?: number | undefined;
    // @ts-ignore
  }): Promise<TDtoPaginationEntry<TDtoFuelsByRegionEntry>> {}

  public getFuelingTurnover = async (params: {
    page: number;
    per_page: number;
    search?: string | undefined;
    deal_number?: number;
    provider_id?: number;
    order?: string;
    contract?: number;
    region?: number;
    fueling?: number;
    date_from?: number;
    date_to?: number;
    number?: string;
    direction?: "asc" | "desc";
  }): Promise<TDtoPaginationEntry<TDtoFuelingTurnoverListItemEntry>> => {
    if (this.abortControllers.getFuelingTurnover) {
      this.abortControllers.getFuelingTurnover.abort();
    }
    this.abortControllers.getFuelingTurnover = new AbortController();

    const response: any = await this.requestClient.get(`/dashboard`, {
      params: {
        contract: params.contract,
        dates:
          params.date_from && params.date_to
            ? {
                end: moment(params.date_to).format("YYYY-MM-DD"),
                start: moment(params.date_from).format("YYYY-MM-DD"),
              }
            : undefined,
        direction: params.direction,
        fuelingRequest: params.fueling,
        // fuelingRequest: params.number,
        // end: moment(params.date_to).format("YYYY-MM-DD"),
        limit: params.per_page,
        order: params.order,
        page: params.page,
        region: params.region,

        // search: params.search,
        // start: moment(params.date_from).format("YYYY-MM-DD"),
      },
      signal: this.abortControllers.getFuelingTurnover.signal,
    });

    return ReportsResponseDto.getFuelingTurnover({
      data: Object.entries(response.data) ?? Object.entries(response.list),
    });
  };

  public getFuelingTurnoverContract = async (params: {
    id: number;
    region: number;
    search?: string | undefined;
    deal_number?: number;
    provider_id?: number;
    order?: string;
    contract?: number;
    date_from?: number;
    date_to?: number;
    number?: string;
    direction?: "asc" | "desc";
    fueling?: number;
  }): Promise<TDtoFuelingTurnoverContractListItemEntry> => {
    if (this.abortControllers.getFuelingTurnoverContract) {
      this.abortControllers.getFuelingTurnoverContract.abort();
    }
    this.abortControllers.getFuelingTurnoverContract = new AbortController();

    const response: any = await this.requestClient.get(
      `/dashboard/related/${params.id}/${params.region}`,
      {
        params: {
          contract: params.contract,
          dates:
            params.date_from && params.date_to
              ? {
                  end: moment(params.date_to).format("YYYY-MM-DD"),
                  start: moment(params.date_from).format("YYYY-MM-DD"),
                }
              : undefined,
          direction: params.direction,
          fuelingRequest: params.fueling,
          order: params.order,
          region: params.region,
        },
        signal: this.abortControllers.getFuelingTurnoverContract.signal,
      }
    );

    return ReportsResponseDto.getFuelingTurnoverContract(response.list);
  };

  public createFuelTransfer = async (data: {
    contractor: number;
    regionFrom: number;
    regionTo: number;
    weight: number;
  }): Promise<{ id: number }> => {
    if (this.abortControllers.createFuelTransfer) {
      this.abortControllers.createFuelTransfer.abort();
    }
    this.abortControllers.createFuelTransfer = new AbortController();

    const response: { id: number } = await this.requestClient.post(
      `/fuel-transfer`,
      {
        ...data,
      },
      {
        signal: this.abortControllers.createFuelTransfer.signal,
      }
    );

    return response;
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
