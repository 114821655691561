import {
  IScoresListFilters,
  IScoresListViewModel,
  TScoresFieldsOrder,
} from "./__types__/IScoresListViewModel";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
import {
  DateFilerType,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "../DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { DatePeriodFilterViewModel } from "../DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { ISearchFilterViewModel } from "../SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { SearchFilterViewModel } from "../SearchFilterViewModel/SearchFilterViewModel";
import { IFieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/__types__/IFieldOrderFilterViewModel";
import { FieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/FieldOrderFilterViewModel";
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";
import { ScoresRepository } from "@repositories/scores";
import { IScoresRepository } from "@repositories/scores/__types__/repository";
import {
  ScoreListItemEntity,
  IScoreListItemEntity,
} from "@entities/ScoreListItemEntity";
import _ from "lodash";
import { AxiosRequestClient, Utils } from "@modules/index";

/**
 * View model for the list of scores.
 */
export class ScoresListViewModel implements IScoresListViewModel {
  /**
   * Statuses instance for managing request status.
   */
  public statuses: Statuses = new Statuses(["fetchItems"]);

  /**
   * Debounced function for fetching items in batches.
   */
  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing),
    400
  );

  /**
   * Private filters for contract, score, and region.
   */
  public privateFilters: {
    contract: null | { id: number; number: string };
    score: string | null;
    region: null | { id: number; name: string };
    per_page: number;
    contractor: null | { id: number; name: string };
  } = {
    contract: null,
    contractor: null,
    per_page: 12,
    region: null,
    score: null,
  };

  /**
   * FieldOrderFilterViewModel instance for managing field order filters.
   */
  public FieldOrderFilterViewModel: IFieldOrderFilterViewModel<TScoresFieldsOrder>;

  /**
   * DatePeriodFilterViewModel instance for managing date period filters.
   */
  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  /**
   * SearchFilterViewModel instance for managing search filters.
   */
  private SearchFilterViewModel: ISearchFilterViewModel;

  /**
   * Scores repository for fetching scores data.
   */
  private repository: IScoresRepository = new ScoresRepository(
    new AxiosRequestClient()
  );

  /**
   * Pagination list of items.
   */
  private _pagination: IPagination<IScoreListItemEntity> = new Pagination(
    undefined
  );

  /**
   * Constructor for ScoresListViewModel.
   *
   * @param model - RootTreeModel instance.
   * @param params - Parameters for initializing the view model.
   */
  public constructor(
    private model: IRootTreeModel,
    params: {
      search: string;
      per_page?: number;
      dateType?:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  ) {
    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params.dateType,
    });

    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: params.search,
    });

    this.FieldOrderFilterViewModel =
      new FieldOrderFilterViewModel<TScoresFieldsOrder>("asc", "deliveryDate");

    if (params.per_page) this.privateFilters.per_page = params.per_page;
  }

  /**
   * Getter for the list of filters applied to request.
   *
   * @returns Filters object.
   */
  public get filters(): IScoresListFilters {
    return {
      contract: this.privateFilters.contract,
      contractor: this.privateFilters.contractor,
      date_filter: this.DatePeriodFilterViewModel.state.type,
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,

      direction: this.FieldOrderFilterViewModel.state.direction,
      order: this.FieldOrderFilterViewModel.state.field,
      per_page: this.privateFilters.per_page,
      region: this.privateFilters.region,
      score: this.privateFilters.score,
      search: this.SearchFilterViewModel.state.search,
    };
  }

  /**
   * Getter for the field order.
   *
   * @returns Field order object.
   */
  public get fieldsOrder(): {
    field: TScoresFieldsOrder;
    direction: "asc" | "desc";
  } {
    return {
      direction: this.FieldOrderFilterViewModel.state.direction,
      field: this.FieldOrderFilterViewModel.state.field,
    };
  }

  /**
   * Getter for the pagination meta data.
   *
   * @returns Pagination meta object.
   */
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  /**
   * Getter for the complete metadata.
   *
   * @returns Pagination metadata object.
   */
  public get metadata(): IPagination<IScoreListItemEntity> {
    return this._pagination;
  }

  /**
   * Getter for the list of items.
   *
   * @returns List of score item entities.
   */
  public get list(): IScoreListItemEntity[] {
    return this._pagination.data;
  }

  /**
   * Setter for the contract filter.
   *
   * @param contract - Contract object.
   * @returns Promise.
   */
  public setContract = async (
    contract: {
      id: number;
      number: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contract = contract;
  };

  /**
   * Setter for the score filter.
   *
   * @param score - Score string.
   * @returns Promise.
   */
  public setScore = async (score: string | null): Promise<void> => {
    this.privateFilters.score = score;
  };

  /**
   * Setter for the region filter.
   *
   * @param region - Region object.
   */
  public setRegion = (region: { id: number; name: string } | null): void => {
    this.privateFilters.region = region;
  };

  /**
   * Setter for the field order filter.
   *
   * @param field - Field order.
   * @param direction - Sort direction.
   */
  public setFieldOrder = (
    field: TScoresFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    this.FieldOrderFilterViewModel.setFieldFilter(field);
    this.FieldOrderFilterViewModel.setDirectionFilter(direction);
  };

  /**
   * Setter for the search filter.
   *
   * @param search - Search string.
   */
  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  /**
   * Fetches items in batches based on the given page number.
   *
   * @param page - Page number.
   * @param refreshing - Flag indicating if the request is refreshing.
   * @returns Promise.
   */
  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }

      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      if (refreshing || page === 1) {
        this._pagination.metadata = {};
      }

      const response = await this.repository.getScoresList({
        contract: this.filters.contract?.id,
        contractor: this.filters.contractor?.name || "",
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        direction: this.filters.direction,
        limit: this.filters.per_page,
        number: this.filters.score || undefined,
        order: this.filters.order,
        page,
        region: this.filters.region?.id || undefined,
      });

      if (response.meta.current_page === 1) {
        this._pagination.metadata = {
          data: response.data.map(
            (item: any): IScoreListItemEntity =>
              ScoreListItemEntity.create(item)
          ),
          meta: response.meta,
        };
      } else {
        this._pagination.metadata = {
          data: _.unionBy(
            this._pagination.data,
            response.data.map(
              (item: any): IScoreListItemEntity =>
                ScoreListItemEntity.create(item)
            )
          ),
          meta: response.meta,
        };
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clears the list of items.
   */
  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  /**
   * Clean up method to be called before destroying the view model.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
    this.repository.beforeDestroy();
  }
}
