import { IScreenControllerRecalculations } from "@screens/RecalculationsScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import TableOutput from "@shared/controls/TableOutput/TableOutput";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

const headerList = {
  contract: [TranslationService.t("txt_col_contract")],
  newPricePerTon: [TranslationService.t("txt_col_new_price_per_ton"), true],
  oldPricePerTon: [TranslationService.t("txt_col_old_price_per_ton"), true],
  recalculationDate: [TranslationService.t("txt_col_recalculation_date"), true],
  region: [TranslationService.t("txt_col_region"), true],
};

export const RecalculationsTable = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerRecalculations;
  }>();

  return (
    <TableOutput
      sortBy={UIViewController.listOfRecalculationsFilters.order}
      sortDir={UIViewController.listOfRecalculationsFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
      headerObj={headerList}
      isLoading={UIViewController.isRequestInProgress}
      resetFilters={() => UIViewController.resetFilters()}
      list={UIViewController.listOfRecalculationsMetadata.data.map((score) => ({
        contract: score.contract.number,
        newPricePerTon: score.newPricePerTon,
        oldPricePerTon: score.oldPricePerTon,
        recalculationDate: moment(score.recalculationDate).format("DD.MM.YY"),
        region: score.region.name,
      }))}
    />
  );
});
