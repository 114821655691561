import { IRecalculationsRepositoryResponseDto } from "./__types__/dtoResponse";
import { Utils } from "@modules/index";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import { TDtoRecalculationsListEntry } from "@modules/utils/dto/parsers/__types__/TDtoRecalculationsListEntry.types";

export class RecalculationsRepositoryResponseDto
  implements IRecalculationsRepositoryResponseDto
{
  public static getListOfScore = (
    data: unknown
  ): TDtoPaginationEntry<TDtoRecalculationsListEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.RecalculationsListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(
          data,
          Utils.dto.Parsers.RecalculationsListItem
        )
    ) as TDtoPaginationEntry<TDtoRecalculationsListEntry>;
}
