import { IScreenControllerRecalculations } from "@screens/RecalculationsScreen/connector/__types__/UIViewController";
import { DateFilters } from "@shared/controls/DateFilters/DateFilters";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const RecalculationsDateFilter = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerRecalculations;
  }>();

  return (
    <DateFilters
      type={UIViewController.listOfRecalculationsFilters.date_filter}
      date_to={UIViewController.listOfRecalculationsFilters.date_to}
      date_from={UIViewController.listOfRecalculationsFilters.date_from}
      onChangeDateFilter={UIViewController.setDateFilter}
    />
  );
});
