const IconFilter = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4993 9.32178H14.3451C14.0602 8.21915 13.1037 7.39218 11.9438 7.39218C10.8042 7.39218 9.82735 8.19795 9.54245 9.32178H2.31819C1.97224 9.32178 1.68734 9.61865 1.68734 9.97912C1.68734 10.3396 1.97224 10.6365 2.31819 10.6365H9.5628C9.8477 11.7391 10.8042 12.5661 11.9641 12.5661C13.1241 12.5661 14.0805 11.7391 14.3451 10.6365H17.4993C17.8453 10.6365 18.1302 10.3396 18.1302 9.97912C18.1302 9.61865 17.8453 9.32178 17.4993 9.32178ZM11.9641 11.2514C11.2926 11.2514 10.7635 10.6789 10.7635 10.0003C10.7635 9.30058 11.3129 8.74926 11.9641 8.74926C12.6357 8.74926 13.1648 9.32178 13.1648 10.0003C13.1648 10.6789 12.6357 11.2514 11.9641 11.2514ZM17.3365 15.0894H10.56C10.2751 13.9868 9.3186 13.1598 8.15865 13.1598C7.01905 13.1598 6.04225 13.9656 5.75735 15.0894H2.29784C1.95189 15.0894 1.66699 15.3862 1.66699 15.7467C1.66699 16.1072 1.95189 16.4041 2.29784 16.4041H5.75735C6.04225 17.5067 6.9987 18.3337 8.15865 18.3337C9.29825 18.3337 10.2751 17.5279 10.56 16.4041H17.3365C17.6825 16.4041 17.9674 16.1072 17.9674 15.7467C17.9674 15.3862 17.6825 15.0894 17.3365 15.0894ZM8.15865 17.019C7.50745 17.019 6.958 16.4677 6.958 15.7891V15.7679V15.7467C6.97835 15.0682 7.50745 14.5169 8.15865 14.5169C8.8302 14.5169 9.3593 15.0894 9.3593 15.7679C9.3593 16.4465 8.8302 17.019 8.15865 17.019ZM17.7028 3.59659H10.56C10.2751 2.49396 9.3186 1.66699 8.15865 1.66699C7.01905 1.66699 6.04225 2.47276 5.75735 3.59659H2.50134C2.15539 3.59659 1.87049 3.89346 1.87049 4.25393C1.87049 4.61441 2.15539 4.91127 2.50134 4.91127H5.75735C6.04225 6.0139 6.9987 6.84087 8.15865 6.84087C9.29825 6.84087 10.2751 6.0351 10.56 4.91127H17.7028C18.0488 4.91127 18.3337 4.61441 18.3337 4.25393C18.3337 3.87225 18.0488 3.59659 17.7028 3.59659ZM8.15865 5.50499C7.4871 5.50499 6.958 4.93247 6.958 4.25393C6.958 3.55418 7.50745 3.00287 8.15865 3.00287C8.8302 3.00287 9.3593 3.57539 9.3593 4.25393C9.37965 4.93247 8.8302 5.50499 8.15865 5.50499Z"
      fill="#303B57"
    />
  </svg>
);

export default IconFilter;
