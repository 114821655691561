/* eslint-disable @typescript-eslint/no-empty-interface */
// import { StorageModel } from "./storage";
// import { SystemModel } from "./system";
import { UserTreeModel } from "../UserTreeModel";
import {
  Instance,
  applySnapshot,
  getSnapshot,
  types,
  onSnapshot,
  SnapshotIn,
  SnapshotOut,
} from "mobx-state-tree";
import { throttle } from "lodash";
import { SystemTreeModel } from "@models/SystemTreeModel/SystemTreeModel";
import { StorageTreeModel } from "@models/StorageTreeModel/StorageTreeModel";
import { NotificationsTreeModel } from "@models/NotificationsTreeModel/NotificationsTreeModel";

export const RootTreeModel = types
  .model({
    hydrated: types.optional(types.boolean, false),

    notifications: types.optional(NotificationsTreeModel, {}),
    storage: types.optional(StorageTreeModel, {}),
    system: types.optional(SystemTreeModel, {}),
    user: types.optional(UserTreeModel, {}),
  })
  .named("RootTreeModel")
  .actions((self) => ({
    afterCreate(): void {
      this.handleAppLaunch();
    },

    applyPatch: (data: any): void => {
      applySnapshot(self, {
        ...data,
        hydrated: true,
      } as any);
    },

    forceClean: (): void => {
      applySnapshot(self, {
        hydrated: true,
        user: self.user,
      });
    },

    async handleAppLaunch(): Promise<void> {
      try {
        if (process.env.NODE_ENV === "test") {
          return;
        }

        const json = localStorage.getItem("RootTreeModel") as string;
        this.applyPatch({
          ...JSON.parse(json),
          hydrated: true,
        });
      } catch (error) {
        this.applyPatch({
          ...getSnapshot(self),
          hydrated: true,
        });
        console.warn("Hydration error", error);
      } finally {
        /** Subscribe on changing snapshot */
        onSnapshot(self, this.onSnapshotHandler);
      }
    },

    onSnapshotHandler: throttle((snapshot) => {
      localStorage.setItem(
        "RootTreeModel",
        JSON.stringify({
          /** store root entities */
          ...snapshot,
        })
      );
    }, 1000),
  }));

export interface IRootTreeModel extends Instance<typeof RootTreeModel> {}
export interface IRootTreeModelIn extends SnapshotIn<IRootTreeModel> {}
export interface IRootTreeModelOut extends SnapshotOut<IRootTreeModel> {}
