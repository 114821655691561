import { hash } from "./hash";
import { compare } from "./compare";
import { IUtils } from "./types";
import { validate } from "./validate";
import { delay } from "./delay";
import { getRandomInt } from "./getRandomInt";
import { findSubstring } from "./findSubstring";
import { memoize } from "./memoize";
import { debounce } from "./debounce";
import { throttle } from "./throttle";
import { calculateDistance } from "./calculateDistance";
import { joinBySymbol } from "./joinBySymbol";
import { isString } from "./isString";
import { dto } from "./dto";
import { getMessageFromError } from "./getMessageFromError";
import { objectToFormData } from "./objectToFormData";
import { downloadFile } from "./downloadFile";

export const Utils: IUtils = {
  calculateDistance,
  compare,
  debounce,
  delay,
  downloadFile,
  dto,
  findSubstring,
  getMessageFromError,
  getRandomInt,
  hash,
  isString,
  joinBySymbol,
  memoize,
  objectToFormData,
  throttle,
  validate,
};
