import FuelingRequestCommentForm from "./components/FuelingRequestCommentForm";
import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

const FuelingRequestCommentScene = observer(() => (
  // const UISceneController =
  //   useScene<
  //     IUISceneController<TFuelingRequestSceneResult, TFuelingRequestSceneParams>
  //   >();

  <div className="fueling-create-scene">
    <FuelingRequestCommentForm />
  </div>
));

export default FuelingRequestCommentScene;
