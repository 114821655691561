/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

export const ContractorListItemEntity = types
  .model({
    id: types.number,
    name: types.optional(types.string, ""),
    region: types.optional(types.array(types.number), []),
  })
  .named("ContractorListItemEntity");

export interface IContractorListItemEntity
  extends Instance<typeof ContractorListItemEntity> {}
export interface IContractorListItemEntityIn
  extends SnapshotIn<IContractorListItemEntity> {}
export interface IContractorListItemEntityOut
  extends SnapshotOut<IContractorListItemEntity> {}
