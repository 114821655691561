import {
  IRecalculationsCreateState,
  IRecalculationsCreateViewModel,
} from "./__types__/IRecalculationsListViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
import { RecalculationsRepository } from "@repositories/recalculations";
import { IRecalculationsRepository } from "@repositories/recalculations/__types__/repository";

import moment, { Moment } from "moment";
import { TranslationService } from "@services/translate";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import { AxiosRequestClient } from "@modules/index";

// Defining initialDateTime using moment library
const initialDateTime: Moment = moment();

/**
 * RecalculationsCreateViewModel class
 *
 * @class
 * @implements {IRecalculationsCreateViewModel}
 */
export class RecalculationsCreateViewModel
  implements IRecalculationsCreateViewModel
{
  /**
   * Statuses instance for managing status
   *
   * @type {Statuses}
   */
  public statuses: Statuses = new Statuses(["createRecalculations"]);

  /**
   * State of the RecalculationsCreateViewModel
   *
   * @type {IRecalculationsCreateState}
   */
  public state: IRecalculationsCreateState = observable(this.initialState);

  /**
   * Repository for managing Recalculations
   *
   * @type {IRecalculationsRepository}
   * @private
   */
  private repository: IRecalculationsRepository = new RecalculationsRepository(
    new AxiosRequestClient()
  );

  /**
   * Constructor for RecalculationsCreateViewModel
   *
   * @param {IRootTreeModel} model - RootTreeModel instance
   */
  public constructor(private model: IRootTreeModel) {}

  /**
   * Get the initial state of RecalculationsCreateViewModel
   *
   * @returns {IRecalculationsCreateState} - The initial state object
   * @private
   */
  private get initialState(): IRecalculationsCreateState {
    return {
      contract: null,
      new_price_per_ton: 0,
      number: "",
      recalculation_date: initialDateTime,
      region: null,
      regions: null,
    };
  }

  /**
   * Set the contract in the state
   *
   * @param {object} contract - The contract object
   * @param {number} contract.id - The contract ID
   * @param {string} contract.name - The contract name
   */
  public setContract = (
    contract: { id: number; number: string } | null
  ): void => {
    this.state.contract = contract;
  };

  /**
   * Set the Recalculations number in the state
   *
   * @param {string} numberRecalculations - The Recalculations number
   */
  public setRecalculationsNumber = (numberRecalculations: string): void => {
    this.state.number = numberRecalculations;
  };

  /**
   * Set the delivery date in the state
   *
   * @param {Moment} date - The delivery date
   */
  public setDeliveryDate = (date: Moment): void => {
    this.state.recalculation_date = date;
  };

  /**
   * Set the regions in the state
   *
   * @param {Array} data - The regions data
   */
  public setRegions = (data: { id: number; name: string }[] | null): void => {
    this.state.regions = data ? data : null;
  };

  /**
   * Set the region for the recalculation
   *
   * @param {Array} data - The region's data
   */
  public setRegion = (data: { id: number; name: string } | null): void => {
    this.state.region = data ? data : null;
  };

  /**
   * Set the price in the state
   *
   * @param {number} price - The price
   */
  public setPrice = (price: number): void => {
    this.state.new_price_per_ton = price;
  };

  /**
   * Create a new Recalculations
   *
   * @param {IRecalculationsCreateState} data - The Recalculations data to be created
   * @returns {Promise<{ id: number }>} - The created Recalculations ID
   */
  public createRecalculations = async (
    data: IRecalculationsCreateState
  ): Promise<{ id: number }> => {
    try {
      // Validation checks
      if (!data.contract || data.contract === null) {
        throw new Error(
          TranslationService.t("txt_wrong_contract_number_format")
        );
      }

      if (!data.contract.number || !data.contract.id) {
        throw new Error(TranslationService.t("txt_wrong_score_format"));
      }

      if (data.new_price_per_ton <= 0) {
        throw new Error(TranslationService.t("txt_wrong_price_format"));
      }

      if (!data.new_price_per_ton) {
        throw new Error(TranslationService.t("txt_error_missing_price"));
      }

      if (!data.region) {
        throw new Error(TranslationService.t("txt_error_missing_region"));
      }

      if (!data.recalculation_date)
        throw new Error(TranslationService.t("txt_wrong_delivery_date"));

      if (!data.recalculation_date.isValid())
        throw new Error(TranslationService.t("txt_wrong_delivery_date"));

      this.statuses.setStatus("createRecalculations", RequestStatus.Pending);

      // Create the Recalculations using the repository
      const response = await this.repository.createRecalculations({
        contract_id: data.contract?.id,
        new_price_per_ton: data.new_price_per_ton,
        recalculation_date: moment(data.recalculation_date).format(
          "YYYY-MM-DD"
        ),
        region_id: data.region?.id,
      });

      this.statuses.setStatus("createRecalculations", RequestStatus.Success);

      return response;
    } catch (error) {
      this.statuses.setStatus("createRecalculations", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clear the state of RecalculationsCreateViewModel
   */
  public clearState = (): void => {
    this.state = this.initialState;
  };

  /**
   * Perform cleanup tasks before destroying the RecalculationsCreateViewModel
   */
  public beforeDestroy = (): void => {
    // Additional cleanup tasks can be performed here
    this.repository.beforeDestroy();
  };
}
