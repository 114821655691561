import "./styles.scss";
import { IconArrowDown } from "../icons";
import Select, {
  type DropdownIndicatorProps,
  components,
  GroupBase,
  OptionsOrGroups,
} from "react-select";

type Props = {
  placeholder?: string;
  options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
  isMulti?: boolean;
  isClearable?: boolean;
  value?: unknown;
  onChange?: any;
  isLoading?: boolean;
  isDisabled?: boolean;
  onInputChange?: (value: any) => void;
  onFocus?: () => void;
  onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => (
  <components.DropdownIndicator {...props}>
    <IconArrowDown />
  </components.DropdownIndicator>
);

const SelectComponent = ({
  options,
  placeholder,
  isMulti,
  isClearable,
  value,
  onChange,
  isLoading,
  isDisabled,
  onInputChange,
  onFocus,
  onMenuScrollToBottom,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
Props) => (
  <Select
    className={"custom-select"}
    placeholder={placeholder || "Выберите из списка"}
    options={options}
    components={{ DropdownIndicator }}
    isMulti={isMulti}
    value={value}
    classNamePrefix="react-select"
    isClearable={isClearable}
    hideSelectedOptions={false}
    onChange={onChange}
    isLoading={isLoading}
    isDisabled={isDisabled}
    onInputChange={onInputChange}
    onFocus={onFocus}
    onMenuScrollToBottom={onMenuScrollToBottom}
    menuPosition={"fixed"}
    styles={{
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: "#DBDCE1",
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: "#303B57",
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        ":hover": {
          backgroundColor: "transparent",
          color: "#303B57",
        },
        color: "#303B57",
      }),
    }}
  />
);

export default SelectComponent;
