import {
  Row,
  Arrow,
  DropCol,
  Value,
  SubTitle,
  ArrowRow,
  Option,
} from "../../TableOutput";
import ContractTableOutput from "../ContractTableOutput/ContractTableOutput";
import { IconArrowDown, IconCross, IconOption } from "@shared/controls/icons";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import moment from "moment";
import styled from "styled-components";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "@shared/controls/Button/Button";

type Props = {
  region: any;
  headerObj: any;
  isOpenContractorId: any;
  listWidth: any;
  handleOpenContractor: any;
  handleFuelTransferRegion: any;
  docListFromContractorId: any;
  rowId: number;
};

const RegionTableOutput = ({
  region,
  headerObj,
  isOpenContractorId,
  listWidth,
  handleOpenContractor,
  handleFuelTransferRegion,
  docListFromContractorId,
  rowId,
}: Props): JSX.Element => {
  const windowWidth = useWindowWidth();
  const [isOpenOption, setIsOpenOption] = useState<number | null>(null);

  return (
    <div className="col">
      {windowWidth > 1024 ? (
        <Row
          className={
            isOpenContractorId &&
            isOpenContractorId.find(
              (el: any) => el.id === region.id && el.regionId === rowId
            )
              ? "isOpen"
              : ""
          }
        >
          {Object.keys(headerObj)?.map((elem, ind) => {
            if (elem === "region_contractor")
              return (
                <Arrow
                  className="arrow-region"
                  key={elem + ind + "child"}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                >
                  {region.remains || region.expense || region.income ? (
                    <IconArrowDown
                      onClick={(): void =>
                        handleOpenContractor(region.id, rowId)
                      }
                    />
                  ) : (
                    <Opacity />
                  )}
                  {region[elem]}
                </Arrow>
              );
            else if (
              region[elem] &&
              (elem === "contract" || elem === "document_number")
            )
              return (
                <p
                  key={elem + ind}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  title={region[elem][0] + " от " + region[elem][1]}
                >
                  {region[elem][0]} от {region[elem][1]}
                </p>
              );
            else if (elem === "income" && region[elem] > 0)
              return (
                <p
                  key={elem + ind}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  className="status2"
                  title={"+" + region[elem]}
                >
                  +{region[elem]}
                </p>
              );
            else if (elem === "option")
              return (
                <DropCol
                  key={elem + ind}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  className={
                    isOpenContractorId?.find(
                      (el: any) => el.id === region.id && el.regionId === rowId
                    )
                      ? "isOpen"
                      : ""
                  }
                >
                  <DropdownButton
                    id="dropdown-basic"
                    align={{ lg: "end" }}
                    title={<IconOption />}
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => handleFuelTransferRegion(region[elem])}
                    >
                      Перенести
                    </div>
                    <Link to="/reportregion" className="dropdown-item">
                      Сформировать отчёт
                    </Link>
                  </DropdownButton>
                </DropCol>
              );
            else
              return (
                <p
                  key={elem + ind + "region"}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  className={
                    elem === "expense"
                      ? "status3"
                      : elem === "income"
                      ? "status2"
                      : ""
                  }
                  title={region[elem]}
                >
                  {region[elem]}
                </p>
              );
          })}
        </Row>
      ) : (
        <Row
          className={
            isOpenContractorId &&
            isOpenContractorId.find(
              (el: any) => el.id === region.id && el.regionId === rowId
            )
              ? "isOpen"
              : ""
          }
        >
          <div className="col gap6 justify-between">
            <Value>{region?.region_contractor}</Value>
            {region?.contract ? (
              <>
                <SubTitle>№ договора</SubTitle>
                <Value>
                  {region?.contract[0]} от {region?.contract[1]}
                </Value>
                <SubTitle>Расход</SubTitle>
                {region?.expense ? (
                  <Value className="important status3">
                    {region?.expense} кг
                  </Value>
                ) : null}
                <SubTitle>Приход</SubTitle>
                {region?.income ? (
                  <Value className="important status2">
                    +{region.income} кг
                  </Value>
                ) : null}
              </>
            ) : null}
          </div>
          <div className="col gap6 justify-between align-end">
            <Option
              className={`row align-center justify-center ${
                isOpenContractorId?.find(
                  (el: any) => el.id === region.id && el.regionId === rowId
                )
                  ? "isOpen"
                  : ""
              }`}
              onClick={() => setIsOpenOption(region?.id + region?.remains)}
            >
              <IconOption />
            </Option>
            {isOpenOption === region?.id + region?.remains ? (
              <Offcanvas
                show={isOpenOption}
                onHide={() => setIsOpenOption(null)}
                placement={"bottom"}
                name={"bottom"}
              >
                <Offcanvas.Header>
                  <Offcanvas.Title>Дополнительно</Offcanvas.Title>
                  <IconCross onClick={() => setIsOpenOption(null)} />
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="col gap10">
                    <Button
                      text={"Перенести"}
                      type={"button"}
                      size="small"
                      className="secondary"
                      onClick={() => handleFuelTransferRegion(region.option)}
                    />
                    <Link
                      to="/reportregion"
                      className="dropdown-item secondary"
                    >
                      Сформировать отчёт
                    </Link>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            ) : null}
            <div className="col gap6 align-end">
              <SubTitle>Остаток</SubTitle>
              {region?.remains ? <Value>{region?.remains} кг</Value> : null}
            </div>
          </div>
          {region.remains || region.expense || region.income ? (
            <ArrowRow
              className={`row align-center justify-center arrow-region`}
              onClick={(): void => handleOpenContractor(region.id, rowId)}
            >
              <IconArrowDown />
            </ArrowRow>
          ) : null}
        </Row>
      )}

      {isOpenContractorId &&
      isOpenContractorId.filter(
        (el: any) => el.id === region.id && el.regionId === rowId
      ) &&
      docListFromContractorId?.find(
        (el: any) => el.id === region.id && el.regionId === rowId
      ) ? (
        <>
          {docListFromContractorId
            ?.find((el: any) => el.id === region.id && el.regionId === rowId)
            ?.list?.map((doc: any, docId: number) => (
              <ContractTableOutput
                key={doc + docId}
                headerObj={headerObj}
                listWidth={listWidth}
                doc={{
                  document_number: [
                    doc.number,
                    moment(doc.dt).format("DD.MM.YY"),
                  ],
                  expense:
                    doc.type === "request" || doc.type === "transfer_from"
                      ? Math.round(+doc.weight * 1000) / 1000
                      : null,
                  income:
                    doc.type === "order" || doc.type === "transfer_to"
                      ? Math.round(+doc.weight * 1000) / 1000
                      : null,
                  region_contractor:
                    doc.type === "order"
                      ? "Счет"
                      : doc.type === "request"
                      ? "Требование"
                      : "Перенос топлива",
                  remains:
                    doc.type === "order" || doc.type === "request"
                      ? Math.round(+doc.remains * 1000) / 1000
                      : null,
                }}
              />
            ))}
        </>
      ) : null}
    </div>
  );
};

export default RegionTableOutput;

const Opacity = styled.div`
  width: 24px !important;
  height: 24px !important;
  content: "";
`;
