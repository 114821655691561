import { IScreenControllerProfile } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { Utils } from "@modules/utils";
import {
  IErrors,
  IProfileEditState,
  IProfileState,
  IProfileViewModel,
} from "@viewModels/ProfileViewModel/__types__/IProfileViewModel.types";
import { ProfileViewModel } from "@viewModels/ProfileViewModel/ProfileViewModel";
import { TranslationService } from "@services/translate";
import { RequestStatus } from "@constants/repositories";

/**
 * ScreenControllerProfile implements IScreenControllerProfile.
 * It is responsible for handling the logic and interactions of the profile screen.
 */
class ScreenControllerProfile
  extends UILogicController
  implements IScreenControllerProfile
{
  /** Object to hold disposers for mobx reactions. */
  public disposers: { [key: string]: IReactionDisposer } = {};

  /** Instance of the ProfileViewModel. */
  private ProfileViewModel: IProfileViewModel;

  /**
   * Constructs a new instance of ScreenControllerProfile.
   *
   * @param model - The IRootTreeModel object.
   */
  public constructor(model: IRootTreeModel) {
    super(model);

    this.ProfileViewModel = new ProfileViewModel(model);
  }

  /** Indicator for otp request confirmation or resending otp request. */
  public get isRequestInProgress(): boolean {
    return this.ProfileViewModel.statuses.isInProgress;
  }

  /** Indicator for fetching profile data. */
  public get isFetchingInProgress(): boolean {
    return (
      this.ProfileViewModel.statuses.getStatus("fetchProfile") ===
      RequestStatus.Pending
    );
  }

  /** Indicator for saving main edit info. */
  public get isMainInProgress(): boolean {
    return (
      this.ProfileViewModel.statuses.getStatus("saveMainEditInfo") ===
      RequestStatus.Pending
    );
  }

  /** Indicator for saving secure edit info. */
  public get isSecureInProgress(): boolean {
    return (
      this.ProfileViewModel.statuses.getStatus("saveSecureEditInfo") ===
      RequestStatus.Pending
    );
  }

  /** Indicator for saving image edit info. */
  public get isImageInProgress(): boolean {
    return (
      this.ProfileViewModel.statuses.getStatus("saveImageEditInfo") ===
      RequestStatus.Pending
    );
  }

  /** The profile state. */
  public get profileState(): IProfileState {
    return this.ProfileViewModel.profileState;
  }

  /** The profile edit state. */
  public get profileEditState(): IProfileEditState {
    return this.ProfileViewModel.profileEditState;
  }

  public get profileErrors(): IErrors {
    return this.ProfileViewModel.profileErrors;
  }

  /**
   * Sets the first name.
   *
   * @param data - The first name data.
   */
  public setFirstName = (data: string): void => {
    this.ProfileViewModel.setFirstName(data);
  };

  /**
   * Sets the last name.
   *
   * @param data - The last name data.
   */
  public setLastName = (data: string): void => {
    this.ProfileViewModel.setLastName(data);
  };

  /**
   * Sets the middle name.
   *
   * @param data - The middle name data.
   */
  public setMiddleName = (data: string): void => {
    this.ProfileViewModel.setMiddleName(data);
  };

  /**
   * Sets the phone number.
   *
   * @param data - The phone number data.
   */
  public setPhone = (data: string): void => {
    this.ProfileViewModel.setPhone(data);
  };

  /**
   * Sets the profile image.
   *
   * @param image - The image file.
   */
  public setImage = (image: File): void => {
    this.ProfileViewModel.setImage(image);
  };

  /**
   * Sets the old password.
   *
   * @param data - The old password data.
   */
  public setOldPassword = (data: string): void => {
    this.ProfileViewModel.setOldPassword(data);
    // this.removeErrors();
  };

  /**
   * Sets the password.
   *
   * @param data - The password data.
   */
  public setPassword = (data: string): void => {
    this.ProfileViewModel.setPassword(data);
    // this.removeErrors();
  };

  /**
   * Sets the repeat password.
   *
   * @param data - The repeat password data.
   */
  public setRepeatPassword = (data: string): void => {
    this.ProfileViewModel.setRepeatPassword(data);
    // this.removeErrors();
  };

  public setErrorPhone = (error: boolean): void => {
    this.ProfileViewModel.setErrorPhone(error);
  };

  public setErrorOldPassword = (error: boolean): void => {
    this.ProfileViewModel.setErrorOldPassword(error);
  };

  public setErrorPasswordNotEqual = (error: boolean): void => {
    this.ProfileViewModel.setErrorPasswordNotEqual(error);
  };

  public setErrorPasswordValidationSymbols = (error: boolean): void => {
    this.ProfileViewModel.setErrorPasswordValidationSymbols(error);
  };

  public setErrorPasswordValidationNumber = (error: boolean): void => {
    this.ProfileViewModel.setErrorPasswordValidationNumber(error);
  };

  public setErrorPasswordValidationBigLetter = (error: boolean): void => {
    this.ProfileViewModel.setErrorPasswordValidationBigLetter(error);
  };

  /**
   * Fetches the profile data.
   *
   * @returns A promise that resolves when the profile data is fetched.
   */
  public fetchProfileData = async (): Promise<void> => {
    try {
      await this.ProfileViewModel.fetchProfileData();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  /**
   * Saves the profile main info.
   *
   * @returns A promise that resolves when the profile main info is saved.
   */
  public saveProfileMainInfo = async (): Promise<void> => {
    try {
      await this.ProfileViewModel.saveMainEditInfo();

      this.model.user.syncProfile();

      toast.success(TranslationService.t("txt_profile_info_saved"));
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public removeErrors = (): void => {
    try {
      this.ProfileViewModel.profileErrors =
        this.ProfileViewModel.profileInitialErrors;
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  /**
   * Saves the profile secure info.
   *
   * @returns A promise that resolves when the profile secure info is saved.
   */
  public saveProfileSecureInfo = async (): Promise<void> => {
    try {
      await this.ProfileViewModel.saveSecureEditInfo();

      this.model.user.syncProfile();

      toast.success(TranslationService.t("txt_profile_info_saved"));

      this.removeErrors();

      this.model.user.logout();
    } catch (error) {
      // toast.error(Utils.getMessageFromError(error));
    }
  };

  /**
   * Saves the profile image info.
   *
   * @returns A promise that resolves when the profile image info is saved.
   */
  public saveProfileImageInfo = async (): Promise<void> => {
    try {
      await this.ProfileViewModel.saveImageEditInfo();

      this.model.user.syncProfile();

      toast.success(TranslationService.t("txt_profile_info_saved"));
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  /**
   * Performs necessary cleanup before destroying the instance.
   */
  public beforeDestroy = (): void => {
    this.ProfileViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerProfile };
