const IconArrowLeft = (): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.88326 8.75L6.875 7.99157L4.67459 5L6.875 2.00843L5.88326 1.25L3.125 5L5.88326 8.75Z"
      fill="#303B57"
    />
  </svg>
);

export default IconArrowLeft;
