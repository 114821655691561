/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

// const ContractListContractsItemEntity = types.string;

const ContractListContractorItemEntity = types.model({
  id: types.number,
  name: types.optional(types.string, ""),
  region: types.optional(types.array(types.number), []),
});

export const ContractListItemEntity = types
  .model({
    conclusionDate: types.optional(types.string, ""),
    contractor: types.optional(ContractListContractorItemEntity, {
      id: 0,
      name: "",
      region: [],
    }),
    id: types.identifierNumber,
    number: types.optional(types.string, ""),
    orders: types.optional(types.array(types.number), []),
    regions: types.optional(
      types.array(
        types.model({
          id: types.number,
          name: types.string,
        })
      ),
      []
    ),
    termDate: types.optional(types.string, ""),
  })
  .named("ContractListItemEntity");

export interface IContractListItemEntity
  extends Instance<typeof ContractListItemEntity> {}
export interface IContractListItemEntityIn
  extends SnapshotIn<IContractListItemEntity> {}
export interface IContractListItemEntityOut
  extends SnapshotOut<IContractListItemEntity> {}
