import Joi from "joi";

export const DtoRecalculationsListItemScheme = Joi.object({
  contract: Joi.object({
    contractor: Joi.number().optional(),
    id: Joi.number().required(),
    number: Joi.number().required(),
  }).required(),
  id: Joi.number().required(),
  newPricePerTon: Joi.number().required(),
  oldPricePerTon: Joi.number().required(),
  recalculationDate: Joi.string().required(),
  region: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required().allow(""),
  }).required(),
});
