import { TDtoContractorListItemEntry } from "./__types__/TDtoContractorListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoContractorListItemParser: TParseDto<
  TDtoContractorListItemEntry
> = (data: any): TDtoContractorListItemEntry => ({
  id: Number(data.id),
  name: Utils.isString(data.name) ? data.name : "",
  // regions: Array.isArray(data.regions)
  //   ? data.regions.map((region: any) => ({
  //       contractors: Array.isArray(region.contractors)
  //         ? region.map((element: any) =>
  //             Utils.isString(element) ? element : ""
  //           )
  //         : [],
  //       id: Utils.isString(region.id) ? region.id : "",
  //       name: Utils.isString(region.name) ? region.name : "",
  //     }))
  //   : [],
  regions: Array.isArray(data.regions)
    ? data.regions.filter(Boolean).map((region: any) => Number(region))
    : [],
});
