const IconEyeClose = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
  >
    <path
      d="M6.99961 0.389957C5.84809 0.381092 4.71503 0.680346 3.71799 1.25677L2.80118 0.260738C2.60613 0.0485429 2.31269 -0.0434589 2.03144 0.0194729C1.75021 0.0822596 1.5239 0.290384 1.43773 0.565373C1.3514 0.840355 1.41855 1.14033 1.61345 1.35252L2.4157 2.22339C1.78668 2.81695 1.24703 3.49847 0.813349 4.24666C0.498835 4.78386 0.333008 5.39513 0.333008 6.01764C0.333008 6.64026 0.498839 7.25157 0.813349 7.78876C1.65311 9.22647 3.57827 11.6412 6.99974 11.6412C8.15126 11.65 9.28432 11.3508 10.2814 10.7744L11.1982 11.7704C11.3932 11.9826 11.6867 12.0746 11.9679 12.0117C12.2491 11.9489 12.4754 11.7407 12.5616 11.4657C12.648 11.1908 12.5808 10.8908 12.3859 10.6786L11.5836 9.80774C12.2127 9.21417 12.7523 8.53265 13.186 7.78446C13.5005 7.24727 13.6663 6.63599 13.6663 6.01348C13.6663 5.39086 13.5005 4.77955 13.186 4.24236C12.3462 2.80465 10.4211 0.389957 6.99961 0.389957ZM6.99961 9.97446C4.40967 9.97446 2.91168 8.07631 2.24903 6.94284C2.08523 6.66117 1.9989 6.34128 1.9989 6.01557C1.9989 5.68986 2.08523 5.36995 2.24903 5.0883C2.60075 4.48413 3.03792 3.93403 3.54706 3.45484L9.11024 9.49724C8.45172 9.81336 7.73009 9.97665 6.99961 9.97446ZM11.7502 6.94284C11.3985 7.54701 10.9613 8.09711 10.4521 8.5763L4.88897 2.53389C5.54749 2.21778 6.26912 2.05456 6.99961 2.05675C9.58954 2.05675 11.0875 3.9549 11.7502 5.08837C11.914 5.37004 12.0003 5.68979 12.0003 6.0155C12.0003 6.34121 11.914 6.66118 11.7502 6.94284Z"
      fill="#303B57"
    />
  </svg>
);

export default IconEyeClose;
