const IconChat = (): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1735_13259)">
      <path
        d="M4.9998 0.833994C2.70297 0.833994 0.833251 2.58658 0.833251 4.74026C0.834886 5.47169 1.05232 6.18649 1.45821 6.79493C1.37919 6.96532 1.28737 7.12936 1.18348 7.28584L1.00901 7.54624C0.868411 7.75632 0.808921 8.01046 0.841891 8.26113C0.87477 8.51181 0.997653 8.74197 1.18775 8.90874C1.37784 9.0755 1.62207 9.16741 1.87493 9.16732C2.61505 9.16813 3.33754 8.94079 3.94388 8.51627C4.28927 8.60238 4.64384 8.64606 4.99979 8.64652C7.29663 8.64652 9.16634 6.89393 9.16634 4.74025C9.16634 2.58657 7.29666 0.833994 4.9998 0.833994ZM4.9998 7.60486C4.73132 7.60513 4.46385 7.57325 4.20292 7.50976C3.90066 7.43937 3.58269 7.50041 3.32802 7.67779C2.89988 7.97025 2.39336 8.12638 1.87493 8.12566L2.04941 7.86527C2.20926 7.62431 2.34695 7.36936 2.46083 7.10359C2.57382 6.83067 2.5373 6.51877 2.36447 6.27935C2.04649 5.82903 1.87548 5.29153 1.87492 4.7403C1.87492 3.15829 3.2746 1.87571 4.99983 1.87571C6.72507 1.87571 8.12475 3.15826 8.12475 4.7403C8.12475 6.32232 6.72507 7.6049 4.99983 7.6049L4.9998 7.60486Z"
        fill="#303B57"
      />
    </g>
    <defs>
      <clipPath id="clip0_1735_13259">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconChat;
