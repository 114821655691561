import { IFuelingsRepositoryResponseDto } from "./__types__/dtoResponse";
import { Utils } from "@modules/utils";
import { TDtoFuelingListEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingListEntry.types";
import {
  IDtoFuelingCommentEntry,
  TDtoFuelingCommentListItemEntry,
} from "@modules/utils/dto/parsers/__types__/TDtoFuelingCommentListItemEntry.types";
import { TDtoFuelingTotalsEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTotalsEntry.types";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class FuelingsRepositoryResponseDto
  implements IFuelingsRepositoryResponseDto
{
  public static getListOfFuelings = (
    data: unknown
  ): TDtoPaginationEntry<TDtoFuelingListEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.FuelingListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(data, Utils.dto.Parsers.FuelingListItem)
    ) as TDtoPaginationEntry<TDtoFuelingListEntry>;

  public static getFuelingTotals = (data: unknown): TDtoFuelingTotalsEntry =>
    Utils.dto.transform(
      Utils.dto.Schemes.FuelingTotals,
      data,
      Utils.dto.Parsers.FuelingTotals
    );

  public static getFuelingCommentsList = (
    data: unknown
  ): TDtoPaginationEntry<TDtoFuelingCommentListItemEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.FuelingCommentListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(
          data,
          Utils.dto.Parsers.FuelingCommentListItem
        )
    ) as TDtoPaginationEntry<TDtoFuelingCommentListItemEntry>;

  public static getFuelingComment = (data: unknown): IDtoFuelingCommentEntry =>
    Utils.dto.transform(
      Utils.dto.Schemes.FuelingCommentListItem,
      data,
      Utils.dto.Parsers.FuelingCommentListItem
    );
}
