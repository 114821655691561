import {
  IRegionsListFilters,
  IRegionsListLocalFilters,
  IRegionsListViewModel,
} from "./__types__/IRegionsListViewModel";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
import {
  DateFilerType,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "../DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { DatePeriodFilterViewModel } from "../DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { ISearchFilterViewModel } from "../SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { SearchFilterViewModel } from "../SearchFilterViewModel/SearchFilterViewModel";
import { RegionsRepository } from "../../repositories/regions/repository";
import { IRegionsRepository } from "../../repositories/regions/__types__/repository";
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";
import {
  IRegionListItemEntity,
  RegionListItemEntity,
} from "@entities/RegionListItemEntity";
import _ from "lodash";
import { observable } from "mobx";
import { AxiosRequestClient, Utils } from "@modules/index";

/**
 * The `RegionsListViewModel` class is responsible for managing a list of regions.
 * It provides functionality to fetch and filter regions based on various criteria.
 */
export class RegionsListViewModel implements IRegionsListViewModel {
  public statuses: Statuses = new Statuses(["fetchItems"]);

  // Observables
  public _localFilter: IRegionsListLocalFilters = observable({
    contract: undefined,
    contractor: undefined,
    per_page: 12,
  });

  // Debounced function for fetching items
  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing),
    400
  );

  // View models
  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  private SearchFilterViewModel: ISearchFilterViewModel;

  // Repository
  private repository: IRegionsRepository = new RegionsRepository(
    new AxiosRequestClient()
  );

  // Pagination
  private _pagination: IPagination<IRegionListItemEntity> = new Pagination(
    undefined
  );

  /**
   * Constructor for the `RegionsListViewModel` class.
   *
   * @param model - The root tree model.
   */
  public constructor(
    private model: IRootTreeModel,
    params?: {
      per_page?: number;
      dateType?:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  ) {
    // Initialize date period filter view model
    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params?.dateType,
    });

    // Initialize search filter view model
    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: "",
    });

    if (params?.per_page) this._localFilter.per_page = params?.per_page;
  }

  /**
   * Get the list of filters applied to the request.
   *
   * @returns The filters object.
   */
  public get filters(): IRegionsListFilters {
    return {
      contract: this._localFilter.contract,
      contractor: this._localFilter.contractor,
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,
      per_page: this._localFilter.per_page,
      search: this.SearchFilterViewModel.state.search,
    };
  }

  /**
   * Get the meta data of the pagination.
   *
   * @returns The pagination meta data.
   */
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  /**
   * Get the metadata of the pagination.
   *
   * @returns The pagination metadata.
   */
  public get metadata(): IPagination<IRegionListItemEntity> {
    return this._pagination;
  }

  /**
   * Get the list of regions.
   *
   * @returns The list of regions.
   */
  public get list(): IRegionListItemEntity[] {
    return this._pagination.data;
  }

  /**
   * Set the search filter.
   *
   * @param search - The search keyword.
   */
  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  /**
   * Fetch a batch of items.
   *
   * @param page - The page number.
   * @param refreshing - True if the page is being refreshed, false otherwise.
   */
  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      // Check if previous request is ongoing
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }

      // Check if maximum number of pages already loaded
      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      // Set status to pending
      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      // Make the API request
      const response = await this.repository.getRegionsList({
        contract: this.filters.contract?.id,
        contractor: this.filters.contractor?.id,
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        page,
        per_page: this.filters.per_page,
        search: this.filters.search,
      });

      // Update pagination data
      if (page === 1) {
        this._pagination.metadata = {
          data: response.data.map(
            (item): IRegionListItemEntity => RegionListItemEntity.create(item)
          ),
          meta: response.meta,
        };
      } else {
        this._pagination.metadata = {
          data: _.unionBy(
            this._pagination.data,
            response.data.map(
              (item): IRegionListItemEntity => RegionListItemEntity.create(item)
            ),
            (item) => item.id
          ),
          meta: response.meta,
        };
      }

      // Set status to success
      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      // Check if request was cancelled
      if (isCancel(error)) {
        return;
      }

      // Set status to error
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Set the contractor filter.
   *
   * @param contractor - The contractor object with id and name properties.
   */
  public setContractorFilter = (contractor: {
    id: number;
    name: string;
  }): void => {
    this._localFilter.contractor = contractor;
  };

  /**
   * Set the contract filter.
   *
   * @param contract - The contract object with id and name properties.
   */
  public setContractFilter = (contract: {
    id: number;
    number: string;
  }): void => {
    this._localFilter.contract = contract;
  };

  /**
   * Clear the list of regions.
   */
  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  /**
   * Clean up resources before destroying the view model.
   */
  public beforeDestroy(): void {
    this.repository.beforeDestroy();
  }
}
