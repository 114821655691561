import { FuelingType } from "@constants/fueling";
import { IScreenControllerFuelingCommentEditScreen } from "@screens/FuelingCommentEditScreen/connector/__types__/UIViewController";
import Input from "@shared/controls/Input/Input";
import SelectComponent from "@shared/controls/Select/Select";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import styled from "styled-components";

const FuelingTypeMap = {
  [FuelingType.Unknown]: "Неизвеcтно",
  [FuelingType.General]: "Основная",
  [FuelingType.Transfer]: "Перегон",
};

export const FuelingCommentEditFieldsForm = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCommentEditScreen;
  }>();

  return (
    <Col16 className="col">
      <Title>Требование</Title>
      <Table>
        <FlexWrapper>
          <div className="custom-field">
            <label>№ борта</label>
            <SelectComponent
              isClearable
              options={UIViewController.listOfVehiclesMetadata.data.map(
                (vehicle, index) => ({
                  id: vehicle.id,
                  index,
                  label: vehicle.number,
                  number: vehicle.number,
                  value: vehicle.id,
                })
              )}
              isMulti={false}
              onMenuScrollToBottom={() =>
                UIViewController.fetchListOfVehicles()
              }
              onInputChange={UIViewController.setVehicleSearch}
              isLoading={UIViewController.isVehiclesLoading}
              value={
                UIViewController.editState.fuelingRequest.vehicle
                  ? {
                      label:
                        UIViewController.editState.fuelingRequest.vehicle
                          .number,
                      value:
                        UIViewController.editState.fuelingRequest.vehicle.id,
                    }
                  : undefined
              }
              onChange={(vehicle: any) => UIViewController.setVehicle(vehicle)}
            />
          </div>
          <div className="custom-field">
            <label>№ топливного требования</label>
            <Input
              placeholder="Введите номер топливного требования"
              value={UIViewController.editState.fuelingRequest.number}
              type="string"
              onChange={(value) => UIViewController.setFuelingNumber(value)}
            />
          </div>

          <div className="custom-field">
            <label>Объем, л</label>
            <Input
              placeholder="Введите объем договора"
              value={UIViewController.editState.fuelingRequest.volume}
              type="number"
              onChange={(volume: string) => UIViewController.setVolume(volume)}
            />
          </div>

          {/* НОВОЕ ПОЛЕ */}
          <div className="custom-field">
            <label>Коэффициент</label>
            <Input
              placeholder="Введите коэффициент"
              value={UIViewController.editState.fuelingRequest.coefficient}
              type="number"
              onChange={(coefficient: number) => {
                UIViewController.setCoefficient(Number(coefficient))
                if (coefficient && Number(UIViewController.editState.fuelingRequest.volume)) {
                  const newWeight = coefficient * Number(UIViewController.editState.fuelingRequest.volume)
                  UIViewController.setWeight(`${newWeight}`)
                }
              }}
            />
          </div>
          <div className="custom-field">
            <label>Вес, кг</label>
            <Input
              disabled={true}
              placeholder="Введите вес топлива"
              value={`${Number(UIViewController.editState.fuelingRequest.volume) * Number(UIViewController.editState.fuelingRequest.coefficient)}`}
              newValue={`${Number(UIViewController.editState.fuelingRequest.volume) * Number(UIViewController.editState.fuelingRequest.coefficient)}`}
              type="number"
              onChange={(weight: string) => UIViewController.setWeight(weight)}
            />
          </div>

          <div className="custom-field">
            <label>Тип заправки</label>
            <ButtonGroup>
              {[
                {
                  text: FuelingTypeMap[FuelingType.General],
                  value: FuelingType.General,
                },
                {
                  text: FuelingTypeMap[FuelingType.Transfer],
                  value: FuelingType.Transfer,
                },
              ].map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`application-fuel-${idx}`}
                  type="radio"
                  variant="secondary"
                  name="radio"
                  value={radio.value}
                  checked={
                    UIViewController.editState.fuelingRequest.fuelingType ===
                    radio.value
                  }
                  onChange={(event: any) =>
                    UIViewController.setFuelingType(
                      Number(event.currentTarget.value)
                    )
                  }
                >
                  {radio.text}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          <div className="custom-field">
            <label>Регион</label>
            <SelectComponent
              options={UIViewController.listOfRegionsMetadata.data.map(
                (region, index) => ({
                  id: region.id,
                  index,
                  label: region.name,
                  name: region.name,
                  value: region.id,
                })
              )}
              onMenuScrollToBottom={() => UIViewController.fetchListOfRegions()}
              isMulti={false}
              onInputChange={UIViewController.setRegionSearch}
              isClearable
              isLoading={UIViewController.isRegionsLoading}
              value={
                UIViewController.editState.fuelingRequest.region
                  ? {
                      label:
                        UIViewController.editState.fuelingRequest.region.name,
                      value:
                        UIViewController.editState.fuelingRequest.region.id,
                    }
                  : undefined
              }
              onChange={(region: any) => UIViewController.setRegion(region)}
            />
          </div>
          <div className="custom-field">
            <label>Поставщик</label>
            <SelectComponent
              onInputChange={UIViewController.setContractorSearch}
              isClearable
              options={UIViewController.listOfContractorsMetadata.data.map(
                (contractor, index) => ({
                  id: contractor.id,
                  index,
                  label: contractor.name,
                  name: contractor.name,
                  value: contractor.id,
                })
              )}
              onMenuScrollToBottom={() =>
                UIViewController.fetchListOfContractors()
              }
              isLoading={UIViewController.isContractorsLoading}
              value={
                UIViewController.editState.fuelingRequest.contractor
                  ? {
                      label:
                        UIViewController.editState.fuelingRequest.contractor
                          .name,
                      value:
                        UIViewController.editState.fuelingRequest.contractor.id,
                    }
                  : undefined
              }
              onChange={(contractor: any) =>
                UIViewController.setContractor(contractor)
              }
            />
          </div>
        </FlexWrapper>
      </Table>
    </Col16>
  );
});

const Col16 = styled.div`
  gap: 16px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FlexWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  .custom-field {
    width: calc(25% - 12px);
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: flex-start;
    align-items: flex-start;

    input {
      max-width: 100%;
    }

    @media (max-width: 1440px) {
      width: calc(33% - 8px);
    }

    @media (max-width: 1280px) {
      width: calc(50% - 8px);
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    .custom-select {
      width: 100%;
    }

    label + input {
      height: 40px;
      width: 100%;
    }

    .btn-group,
    .btn-group-vertical {
      display: inline-flex;
      position: relative;
      vertical-align: middle;
    }

    .btn-group {
      border-radius: 6px;
      border: 1px solid #dbdce1;
      flex-direction: row;
      align-items: stretch;
    }
    .bs-example .btn-group,
    .d-inline-flex {
      display: inline-flex !important;
    }

    .btn-check {
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      position: absolute;
    }

    color: #303b57;

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      background: #dbdce1;
      border-color: #dbdce1;
    }

    .btn {
      flex: 1 1 auto;
    }
  }
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;
