type Props = {
  onClick?: () => void;
};
const IconArrowLeftLong = ({ onClick }: Props): JSX.Element => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
  >
    <path
      d="M8.30424 11.0336L6.61981 12.6667L1.01596 7.23335C0.905277 7.12615 0.817474 6.99886 0.757568 6.85874C0.69766 6.71862 0.666828 6.56843 0.666828 6.41675C0.666828 6.26507 0.69766 6.11487 0.757568 5.97476C0.817474 5.83464 0.905277 5.70734 1.01596 5.60015L6.61981 0.166748L8.30424 1.79994L4.73301 5.26255H17.3335V7.57095H4.73301L8.30424 11.0336Z"
      fill="#303B57"
    />
  </svg>
);

export default IconArrowLeftLong;
