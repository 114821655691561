import { IContractsRepositoryResponseDto } from "./__types__/dtoResponse";
import { Utils } from "@modules/utils";
import { TDtoContractListEntry } from "@modules/utils/dto/parsers/__types__/TDtoContractListEntry.types";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class ContractsRepositoryResponseDto
  implements IContractsRepositoryResponseDto
{
  public static getListOfContract = (
    data: unknown
  ): TDtoPaginationEntry<TDtoContractListEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.ContractListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(data, Utils.dto.Parsers.ContractListItem)
    ) as TDtoPaginationEntry<TDtoContractListEntry>;
}
