import CreateScoreForm from "./components/CreateScoreForm";
import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

const ScoreCreateScene = observer(() => (
  // const UISceneController =
  //   useScene<
  //     IUISceneController<TCreateScoreSceneResult, TCreateScoreSceneParams>
  //   >();

  <div className="contract-create-scene">
    <CreateScoreForm />
  </div>
));

export default ScoreCreateScene;
