import { IScreenControllerFueling } from "@screens/FuelingScreen/connector/__types__/UIViewController";
import Pagination from "@shared/controls/Pagination/Pagination";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const FuelingPagination = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFueling;
  }>();

  return (
    <Pagination
      count={UIViewController.listOfFuelingMetadata.data.length}
      total={UIViewController.listOfFuelingMetadata.meta.total}
      per_page={UIViewController.listOfFuelingMetadata.meta.per_page}
      loading={UIViewController.isRequestInProgress}
      onLoad={UIViewController.fetchListOfFueling}
    />
  );
});
