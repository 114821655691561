import Joi from "joi";

export const DtoProfileScheme = Joi.object({
  email: Joi.string().required().allow(""),
  firstName: Joi.string().required().allow(""),
  image: Joi.string().required().allow(""),
  lastName: Joi.string().required().allow(""),
  middleName: Joi.string().required().allow(""),
  phone: Joi.string().required().allow(""),
});
