import {
  IUISceneController,
  TCreateContractSceneParams,
  TCreateContractSceneResult,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IContractListItemEntity } from "@entities/ContractListItemEntity";
import { IContractorListItemEntity } from "@entities/ContractorListItemEntity";
import { IRegionListItemEntity } from "@entities/RegionListItemEntity";
import { Moment } from "moment";
import { debounce } from "lodash";
import {
  IContractCreateViewModel,
  IContractCreateState,
} from "@viewModels/ContractCreateViewModel/__types__/IContractsListViewModel";
import { ContractCreateViewModel } from "@viewModels/ContractCreateViewModel/ContractCreateViewModel";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { ContractsListViewModel } from "@viewModels/ContractsListViewModel/ContractsListViewModel";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";
import { ContractorsListViewModel } from "@viewModels/ContractorsListViewModel/ContractorsListViewModel";
import { IContractsListViewModel } from "@viewModels/ContractsListViewModel/__types__/IContractsListViewModel";
import { IRegionsListViewModel } from "@viewModels/RegionsListViewModel/__types__/IRegionsListViewModel";
import { IContractorsListViewModel } from "@viewModels/ContractorsListViewModel/__types__/IContractorsListViewModel";
import { IPagination } from "@viewModels/pagination/types";

export class UISceneController<
    T extends TCreateContractSceneResult,
    P extends TCreateContractSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "CreateContractScene";

  public fetchListOfContractorsDebounce = debounce(
    (page?: number, refreshing?: boolean) =>
      this.fetchListOfContractors(page, refreshing),
    400
  );

  private ContractsListViewModel: IContractsListViewModel;

  private RegionsListViewModel: IRegionsListViewModel;

  private ContractorsListViewModel: IContractorsListViewModel;

  private ContractCreateViewModel: IContractCreateViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TCreateContractSceneParams
  ) {
    super(options);

    this.ContractsListViewModel = new ContractsListViewModel(params.model, {
      search: "",
    });

    this.RegionsListViewModel = new RegionsListViewModel(params.model);

    this.ContractorsListViewModel = new ContractorsListViewModel(params.model);

    this.ContractCreateViewModel = new ContractCreateViewModel(params.model);
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get createContractState(): IContractCreateState {
    return this.ContractCreateViewModel.state;
  }

  public get listOfContractsMetadata(): IPagination<IContractListItemEntity> {
    return this.ContractsListViewModel.metadata;
  }

  public get listOfContractorsMetadata(): IPagination<IContractorListItemEntity> {
    return this.ContractorsListViewModel.metadata;
  }

  public get listOfRegionsMetadata(): IPagination<IRegionListItemEntity> {
    return this.RegionsListViewModel.metadata;
  }

  public get isContractorsLoading(): boolean {
    return this.ContractorsListViewModel.statuses.isInProgress;
  }

  public get isContractsLoading(): boolean {
    return this.ContractsListViewModel.statuses.isInProgress;
  }

  public get isRegionsLoading(): boolean {
    return this.RegionsListViewModel.statuses.isInProgress;
  }

  public get isScoreCreationInProgress(): boolean {
    return this.ContractCreateViewModel.statuses.isInProgress;
  }

  public fetchListOfRegions = (): void => {
    try {
      this.RegionsListViewModel.fetchItemsBatch(
        this.RegionsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContractors = (
    page = this.RegionsListViewModel.meta.current_page + 1,
    refreshing: boolean = false
  ): void => {
    try {
      this.ContractorsListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfContracts = (): void => {
    try {
      this.ContractsListViewModel.fetchItemsBatch(
        this.ContractsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractNumber = (contractNumber: string): void => {
    try {
      this.ContractCreateViewModel.setContractNumber(contractNumber);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractor = async (contractor: {
    id: number;
    name: string;
  }): Promise<void> => {
    try {
      // const previousContractorId = this.createContractState.contractor?.id;
      // clear regions if contractor is different

      this.ContractCreateViewModel.setContractor(contractor);

      // if (contractor.id !== previousContractorId) {
      //   this.ContractCreateViewModel.setRegions(null);

      //   this.RegionsListViewModel.setContractorFilter(contractor);

      //   await this.fetchListOfRegions();
      // }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegions = (regions: { id: number; name: string }[]): void => {
    try {
      this.ContractCreateViewModel.setRegions(regions);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setConclusionDate = (date: Moment): void => {
    try {
      this.ContractCreateViewModel.setConclusionDate(date);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setTermDate = (date: Moment): void => {
    try {
      this.ContractCreateViewModel.setTermDate(date);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setContractorSearch = (contractor: string): void => {
    try {
      const previousSearchState = this.ContractorsListViewModel.filters.search;

      this.ContractorsListViewModel.setSearch(contractor);

      if (contractor !== previousSearchState) {
        this.ContractorsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setRegionSearch = (region: string): void => {
    try {
      const previousSearchState = this.RegionsListViewModel.filters.search;

      this.RegionsListViewModel.setSearch(region);

      if (region !== previousSearchState) {
        this.RegionsListViewModel.fetchItemsBatchDebounce(1, true);
      }
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public createContract = async (): Promise<void> => {
    try {
      // Create contract
      await this.ContractCreateViewModel.createContract(
        this.ContractCreateViewModel.state
      );

      // @ts-ignore
      this.displays.handleResolve({});
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public clearContract = (): void => {
    try {
      this.ContractCreateViewModel.clearState();
      this.RegionsListViewModel.clearList();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };
}
