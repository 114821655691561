import Joi from "joi";

export const DtoScoreListScheme = Joi.array().items(
  Joi.object({
    deliveryDate: Joi.string(),
    id: Joi.number().required(),
    number: Joi.string().required(),
    numberScore: Joi.string(),
    price: Joi.string().required(),
    region: {
      contractors: Joi.array().items(Joi.string().allow("")),
      id: Joi.number().required(),
      name: Joi.string().required().allow(""),
    },
    weight: Joi.string().required(),
  })
);
