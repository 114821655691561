import { IScreenControllerFuelingTurnover } from "@screens/HomeScreen/connector/__types__/UIViewController";
import { DateFilters } from "@shared/controls/DateFilters/DateFilters";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const HomeDateFilter = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingTurnover;
  }>();

  return (
    <DateFilters
      type={UIViewController.listOfFuelingTurnoverFilters.date_filter}
      date_to={UIViewController.listOfFuelingTurnoverFilters.date_to}
      date_from={UIViewController.listOfFuelingTurnoverFilters.date_from}
      onChangeDateFilter={UIViewController.setDateFilter}
    />
  );
});
