import { ChangingPassword } from "./components/ChangingPassword/ChangingPassword";
import { NewPassword } from "./components/NewPassword/NewPassword";
import logo from "../AuthorizationScreen/images/logo.svg";
import artu from "../AuthorizationScreen/images/artu.svg";
import logoIcon from "../AuthorizationScreen/images/logoIcon.svg";
import { observer } from "mobx-react";
import { useControllers } from "@view/hooks/useControllers";
import { IScreenControllerAuth } from "@screens/AuthorizationScreen/connector/__types__/UIViewController";
import "../AuthorizationScreen/styles.scss";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { ScreenControllerAuth } from "@screens/AuthorizationScreen/connector/UIViewController";
import withControllers from "@view/providers/__proto__/withController";
import { Helmet } from "react-helmet";

const NewPasswordScreen = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();
  const windowWidth = useWindowWidth();

  return (
    <div className="wrapper-auth">
      <Helmet>
        <title>Новый пароль</title>
      </Helmet>
      <div className="container col">
        {windowWidth > 768 || UIViewController.page === "main" ? (
          <div className="row between">
            <h1>
              Система управления <br /> запасами и расходами ГСМ
            </h1>
            <div className="colLogo col">
              <div className="row">
                <img src={logoIcon} />
                <img src={logo} />
              </div>
            </div>
          </div>
        ) : (
          <div className="row w-100 align-end justify-end">
            <div className="colLogo col">
              <div className="row">
                <img src={logoIcon} />
                <img src={logo} />
              </div>
            </div>
          </div>
        )}
        <NewPassword />
        {UIViewController.page === "changing" ? <ChangingPassword /> : null}

        <div className="row align-end">
          <div className="row justify-center align-center gap8">
            <p>Разработано</p>
            <img src={artu} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default withControllers(ScreenControllerAuth)(NewPasswordScreen);
