import { IScreenControllerReportsByContract } from "@screens/ReportByContractsScreen/connector/__types__/UIViewController";
import SelectComponent from "@shared/controls/Select/Select";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";

export const ReportsByContractsAdditionalFilters = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportsByContract;
  }>();

  return (
    <ColGap16>
      <label>Поставщик</label>
      <SelectComponent
        isClearable
        onFocus={UIViewController.fetchListOfContractors}
        options={UIViewController.listOfContractorsMetadata.data.map(
          (contractor, index) => ({
            id: contractor.id,
            index,
            label: contractor.name,
            name: contractor.name,
            value: contractor.id,
          })
        )}
        onMenuScrollToBottom={() => UIViewController.fetchListOfContractors()}
        onInputChange={UIViewController.setContractorSearch}
        isLoading={UIViewController.isContractorsLoading}
        // value={UIViewController.listOfFuelingFilters.contractor}
        value={
          UIViewController.listOfFuelingFilters.contractor
            ? {
                label: UIViewController.listOfFuelingFilters.contractor.name,
                value: UIViewController.listOfFuelingFilters.contractor.id,
              }
            : null
        }
        onChange={(data: any) => UIViewController.setContractor(data)}
      />

      <label>Номер договора</label>
      <SelectComponent
        isClearable
        onFocus={UIViewController.fetchListOfContracts}
        options={UIViewController.listOfContractsMetadata.data.map(
          (contract, index) => ({
            id: contract.id,
            index,
            label: contract.number,
            number: contract.number,
            value: contract.id,
          })
        )}
        placeholder="Все договоры"
        onMenuScrollToBottom={() => UIViewController.fetchListOfContracts()}
        onInputChange={UIViewController.setContractSearch}
        isLoading={UIViewController.isContractsLoading}
        // value={UIViewController.listOfFuelingFilters.contract}
        value={
          UIViewController.listOfFuelingFilters.contract
            ? {
                label: UIViewController.listOfFuelingFilters.contract.number,
                value: UIViewController.listOfFuelingFilters.contract.id,
              }
            : null
        }
        onChange={(data: any) => UIViewController.setContract(data)}
      />
    </ColGap16>
  );
});

const ColGap16 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
