import {
  IconMain,
  IconReports,
  IconDocs,
  IconApplication,
} from "@shared/controls/icons";
import { TranslationService } from "@services/translate";

export const navList = {
  ROLE_EMPLOYEE_GSM: [
    {
      icon: <IconMain />,
      link: "/",
      name: TranslationService.t("txt_sidebar_main"),
    },
    {
      icon: <IconReports />,
      link: "/report",
      list: [
        {
          link: "/reportregion",
          name: TranslationService.t("txt_title_report_region"),
        },
        {
          link: "/reportvs",
          name: TranslationService.t("txt_title_report_vs"),
        },
        {
          link: "/reportkvs",
          name: TranslationService.t("txt_title_report_kvs"),
        },
        {
          link: "/reportcontract",
          name: TranslationService.t("txt_title_report_contract"),
        },
      ],
      name: TranslationService.t("txt_sidebar_report"),
    },
    {
      icon: <IconDocs />,
      link: "/doc",
      list: [
        {
          link: "/doccontracts",
          name: TranslationService.t("txt_title_doc_contract"),
        },
        {
          link: "/docscores",
          name: TranslationService.t("txt_title_doc_score"),
        },
        {
          link: "/recalculations",
          name: TranslationService.t("txt_title_recalculations"),
        },
      ],
      name: TranslationService.t("txt_sidebar_documents"),
    },
    {
      icon: <IconApplication />,
      link: "/application",
      name: TranslationService.t("txt_sidebar_application"),
    },
  ],
  ROLE_KVS: [
    {
      icon: <IconMain />,
      link: "/",
      name: TranslationService.t("txt_sidebar_main"),
    },
    {
      icon: <IconApplication />,
      link: "/application",
      name: TranslationService.t("txt_sidebar_application"),
    },
  ],
};
