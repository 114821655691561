import { IObservableValue, observable } from "mobx";
import { Utils } from "@modules/utils";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";

export class SceneDisplay<T> {
  public isVisible: IObservableValue<boolean> = observable.box(true);

  private animationDelay = 0;

  public constructor(private options: TSceneDisplayOptions) {}

  public handleCallback = async (
    callback?: CallableFunction
  ): Promise<void> => {
    this.isVisible.set(false);
    await Utils.delay(this.animationDelay);
    if (callback) {
      callback();
    }
    this.options.reject(
      new ErrorScene("handleCallback", {
        cause: ScenesErrors.SceneClosedByCallback,
      })
    );
    return;
  };

  public handleRejects = async (): Promise<void> => {
    this.isVisible.set(false);
    await Utils.delay(this.animationDelay);
    this.options.reject(
      new ErrorScene("handleRejects", { cause: ScenesErrors.UserClosedScene })
    );
    return;
  };

  public handleResolve = async (data: T): Promise<void> => {
    this.isVisible.set(false);
    await Utils.delay(this.animationDelay);
    return this.options.resolve(data);
  };

  public handleDismiss = (): void =>
    this.options.reject(
      new ErrorScene("handleDismiss", { cause: ScenesErrors.UserClosedScene })
    );
}
