import { INotificationsRepositoryResponseDto } from "./__types__/dtoResponse";
import { TDtoNotificationListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoNotificationListItemEntry.types";
import { Utils } from "@modules/index";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class NotificationsRepositoryResponseDto
  implements INotificationsRepositoryResponseDto
{
  public static getListOfNotification = (
    data: unknown
  ): TDtoPaginationEntry<TDtoNotificationListItemEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.NotificationListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(
          data,
          Utils.dto.Parsers.NotificationListItem
        )
    ) as TDtoPaginationEntry<TDtoNotificationListItemEntry>;
}
