import { FuelingCommentStatus } from "@constants/fueling";
import { Screens } from "@constants/screens";
import { IScreenControllerFuelingCommentScreen } from "@screens/FuelingCommentScreen/connector/__types__/UIViewController";
import Button from "@shared/controls/Button/Button";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const FuelingCommentActionForm = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCommentScreen;
  }>();

  return (
    <Col16 className="row">
      {UIViewController.state.status !== FuelingCommentStatus.Declined &&
      UIViewController.state.status !== FuelingCommentStatus.Fixed ? (
        <Button
          text={"Отклонить"}
          type={"button"}
          size="small"
          className={"secondary"}
          disabled={UIViewController.isRequestInProgress}
          loading={UIViewController.isDecliningInProgress}
          onClick={() => UIViewController.declineFuelingComment()}
        />
      ) : null}

      {UIViewController.state.status === FuelingCommentStatus.New ? (
        <Link to={Screens.FuelingCommentEdit.path(UIViewController.state.id)}>
          <Button
            text={"Внести изменения"}
            type={"button"}
            size="small"
            className={"primary"}
            disabled={UIViewController.isRequestInProgress}
          />
        </Link>
      ) : null}
    </Col16>
  );
});

const Col16 = styled.div`
  gap: 16px;
`;
