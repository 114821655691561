import { IAxiosRequestClient, TGlobalInterceptorListeners } from "./types";
import { TRequestClientBody, TRequestClientConfig } from "../../types/instance";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  isCancel,
} from "axios";
import { isObject } from "lodash";
import QueryString from "qs";
import { Env } from "@constants/env";
import { observable } from "mobx";
import { IRootTreeModel } from "@models/index";

export class AxiosRequestClient implements IAxiosRequestClient {
  private static globalInterceptorsListeners: TGlobalInterceptorListeners =
    observable({});

  private client: AxiosInstance;

  public constructor(
    private params?: {
      config?: CreateAxiosDefaults<any>;
      model: IRootTreeModel;
    }
  ) {
    this.client = axios.create({
      baseURL: Env.CLIENT_SERVER_PATH ? `${Env.CLIENT_SERVER_PATH}/` : `/api/`,
      ...params?.config,
      headers: {
        ...axios.defaults.headers.common,
        Accept: "application/json",
        "Accept-Language": "en,ar",
        "Content-Type": "application/json",
        ...params?.config?.headers,
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
      paramsSerializer: {
        serialize: this.paramsSerializer,
      },
      timeout: params?.config?.timeout || 30000,
    });

    this.setInterceptors();
  }

  public static setGlobalInterceptorsListeners = (
    listeners: TGlobalInterceptorListeners
  ): void => {
    this.globalInterceptorsListeners = {
      ...this.globalInterceptorsListeners,
      ...listeners,
    };
  };

  public async get<R = any>(
    url: string,
    config: TRequestClientConfig
  ): Promise<R> {
    return (
      await this.client.get(url, {
        ...config,
        headers: {
          ...this.client.defaults.headers.common,
          ...axios.defaults.headers.common,
          ...config.headers,
        },
      })
    ).data as R;
  }

  public async post<D = TRequestClientBody, R = any>(
    url: string,
    body: D,
    config: TRequestClientConfig
  ): Promise<R> {
    return (
      await this.client.post(url, body, {
        ...config,
        headers: {
          ...this.client.defaults.headers.common,
          ...axios.defaults.headers.common,
          ...config.headers,
        },
      })
    ).data as R;
  }

  public put = async <D = TRequestClientBody, R = any>(
    url: string,
    body: D,
    config: TRequestClientConfig
  ): Promise<R> => (await this.client.put(url, body, config)).data as R;

  public patch = async <D = TRequestClientBody, R = any>(
    url: string,
    body: D,
    config: TRequestClientConfig
  ): Promise<R> =>
    (
      await this.client.patch(url, body, {
        ...config,
        headers: {
          ...this.client.defaults.headers.common,
          ...axios.defaults.headers.common,
          ...config.headers,
        },
      })
    ).data as R;

  public delete = async <R = any>(
    url: string,
    config: AxiosRequestConfig<any>
  ): Promise<R> =>
    (
      await this.client.delete(url, {
        ...config,
        headers: {
          ...this.client.defaults.headers.common,
          ...axios.defaults.headers.common,
          ...config.headers,
        },
      })
    ).data as R;

  public authorize = (token: string, type: "bearer"): void => {
    if (type === "bearer") {
      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: `Bearer ${token}`,
      };
    }
  };

  public unauthorize = (): void => {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      Authorization: "",
    };
  };

  /**
   * Method serialize params before sending in request.
   * - reduce all parameters that have value undefined
   */
  private paramsSerializer = (data = {}): string => {
    if (!isObject(data)) return `${data}`;
    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (value === undefined || value === "") return acc;
      return { ...acc, [key]: value };
    }, {});
    return QueryString.stringify(data, { arrayFormat: "brackets" });
  };

  private setInterceptors = (): void => {
    this.client.interceptors.request.use(this.onRequest);
    this.client.interceptors.response.use(
      this.onResponse,
      this.onResponseError
    );
  };

  private onRequest = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig<any>> => {
    try {
      const bytesSent = new TextEncoder().encode(
        JSON.stringify(config.data)
      ).length;

      // @ts-ignore
      config.metadata = {
        bytesSent,
        startTime: new Date(),
      };

      await AxiosRequestClient.globalInterceptorsListeners.handleRequest?.(
        config
      );
      return config;
    } catch (error) {
      return config;
    }
  };

  private onResponse = async (response: any): Promise<AxiosResponse> => {
    try {
      response.config.metadata.bytesReceived = new TextEncoder().encode(
        response.request._response || ""
      ).length;

      response.config.metadata.endTime = new Date();
      response.duration =
        response.config.metadata.endTime - response.config.metadata.startTime;

      await AxiosRequestClient.globalInterceptorsListeners.handleResponse?.(
        response
      );

      return response;
    } catch (error) {
      return response;
    }
  };

  private onResponseError = async (error: any): Promise<AxiosError> => {
    try {
      error.config.metadata.endTime = new Date();

      error.duration =
        error.config.metadata.endTime - error.config.metadata.startTime;

      if (!isCancel(error))
        await AxiosRequestClient.globalInterceptorsListeners.handleError?.(
          error
        );

      return Promise.reject(error);
    } catch (err) {
      error.config.metadata.endTime = new Date();
      error.duration =
        error.config.metadata.endTime - error.config.metadata.startTime;

      return Promise.reject(err);
    }
  };
}
