import {
  IFuelingRequestState,
  IFuelingRequestViewModel,
} from "./__types__/IFuelingRequestViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
import { FuelingsRepository } from "@repositories/fuelings";
import { IFuelingsRepository } from "@repositories/fuelings/__types__/repository";

import { TranslationService } from "@services/translate";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import { AxiosRequestClient } from "@modules/request/libs/axios";
import { FuelingType } from "@constants/fueling";

export class FuelingRequestViewModel implements IFuelingRequestViewModel {
  /**
   * The statuses object represents the different statuses for fueling requests.
   */
  public statuses: Statuses = new Statuses(["createFueling"]);

  /**
   * The state object represents the current state of the fueling request.
   */
  public state: IFuelingRequestState = observable(this.initialState);

  private repository: IFuelingsRepository = new FuelingsRepository(
    new AxiosRequestClient()
  );

  /**
   * The constructor of the FuelingRequestViewModel class.
   *
   * @param model - The model object.
   */
  public constructor(private model: IRootTreeModel) {}

  /**
   * The initial state of the fueling request.
   *
   * @returns The initial state object.
   */
  private get initialState(): IFuelingRequestState {
    return {
      coefficient: 0,
      contractor: null,
      fuelingType: 1,
      number: "",
      region: null,
      vehicle: null,
      volume: 0,
      weight: 0,
    };
  }

  /**
   * Sets the fueling number in the state.
   *
   * @param number - The fueling number.
   */
  public setFuelingNumber = (number: string): void => {
    this.state.number = number;
  };

  /**
   * Sets the selected vehicle in the state.
   *
   * @param vehicle - The selected vehicle object.
   */
  public setVehicle = (
    vehicle: { id: number; number: string } | null
  ): void => {
    this.state.vehicle = vehicle;
  };

  /**
   * Sets the weight in the state.
   *
   * @param weight - The weight value.
   */
  public setWeight = (weight: number): void => {
    this.state.weight = weight;
  };

  /**
   * Sets the volume in the state.
   *
   * @param volume - The volume value.
   */
  public setVolume = (volume: number): void => {
    this.state.volume = volume;
  };

  /**
   * Sets the coefficient in the state.
   *
   * @param coefficient - The coefficient value.
   */
  public setCoefficient = (coefficient: number): void => {
    this.state.coefficient = coefficient;
  };

  /**
   * Sets the fueling type in the state.
   *
   * @param type - The fueling type value.
   */
  public setFuelingType = (type: number): void => {
    this.state.fuelingType = type;
  };

  /**
   * Sets the contractor in the state.
   *
   * @param data - The contractor data object.
   */
  public setContractor = (data: { id: number; name: string } | null): void => {
    this.state.contractor = data;
  };

  /**
   * Sets the region in the state.
   *
   * @param data - The region data object.
   */
  public setRegion = (data: { id: number; name: string } | null): void => {
    this.state.region = data ? data : null;
  };

  /**
   * Creates a new fueling request.
   *
   * @param data - The fueling request data.
   * @returns A promise that resolves with the created fueling request ID.
   * @throws An error if any of the validation checks fail.
   */
  public createFueling = async (
    data: IFuelingRequestState
  ): Promise<{ id: number }> => {
    try {
      if (!data.vehicle) {
        throw new Error(TranslationService.t("txt_wrong_vehicle_format"));
      }

      if (!data.number || data.number === null) {
        throw new Error(
          TranslationService.t("txt_wrong_fueling_number_format")
        );
      }

      if (data.weight <= 0) {
        throw new Error(TranslationService.t("txt_wrong_weight_format"));
      }

      if (data.volume <= 0) {
        throw new Error(TranslationService.t("txt_wrong_volume_format"));
      }

      if (
        ![FuelingType.General, FuelingType.Transfer].includes(data.fuelingType)
      ) {
        throw new Error(TranslationService.t("txt_wrong_fueling_type_format"));
      }

      if (!data.contractor)
        throw new Error(TranslationService.t("txt_error_missing_contractor"));

      if (!data.region)
        throw new Error(TranslationService.t("txt_error_missing_region"));

      this.statuses.setStatus("createFueling", RequestStatus.Pending);

      const response = await this.repository.createFueling({
        coefficient: data.coefficient,
        contractor: data.contractor.id,
        fuelingType: data.fuelingType,
        number: data.number,
        region: data.region.id,
        vehicle: data.vehicle.id,
        volume: data.volume,
        weight: data.weight,
      });

      this.statuses.setStatus("createFueling", RequestStatus.Success);

      return response;
    } catch (error) {
      this.statuses.setStatus("createFueling", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * Clears the state of the fueling request.
   */
  public clearState(): void {
    this.state = this.initialState;
  }

  /**
   * Performs necessary cleanup actions before destroying the view model.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
    this.repository.beforeDestroy();
  }
}
