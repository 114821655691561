// import { TranslationService } from "@services/translate";
import {
  IUISceneController,
  TCreateRecalculationsSceneParams,
  TCreateRecalculationsSceneResult,
} from "../connector/UISceneController/types";
import Button from "@shared/controls/Button/Button";
import SelectComponent from "@shared/controls/Select/Select";
import { useEffect } from "react";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import moment from "moment";
import "moment/locale/ru";
import { TranslationService } from "@services/translate";
import FilterComponent from "@shared/helpers/SideForm/SideForm";

const CreateRecalculationsForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<
        TCreateRecalculationsSceneResult,
        TCreateRecalculationsSceneParams
      >
    >();
  moment.locale("ru");

  // Fetch initial data to the form fields
  useEffect(() => {
    UISceneController.fetchListOfContracts();
    UISceneController.fetchListOfRegions();
  }, []);

  return (
    <FilterComponent
      title="Новый перерасчет"
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form">
        <div className="custom-field">
          <label>№ договора</label>
          <SelectComponent
            isClearable
            onMenuScrollToBottom={() =>
              UISceneController.fetchListOfContracts()
            }
            onFocus={() => UISceneController.fetchListOfContracts()}
            options={UISceneController.listOfContractsMetadata.data.map(
              (contract, index) => ({
                id: contract.id,
                index,
                label: contract.number + " (" + contract.contractor.name + ")",
                number: contract.number,
                value: contract.id,
              })
            )}
            onInputChange={UISceneController.setContractSearch}
            isLoading={UISceneController.isContractsLoading}
            // value={UISceneController.createRecalculationsState.contract}
            value={
              UISceneController.createRecalculationsState.contract
                ? {
                    label:
                      UISceneController.createRecalculationsState.contract
                        .number,
                    value:
                      UISceneController.createRecalculationsState.contract.id,
                  }
                : null
            }
            onChange={(contract: any) =>
              UISceneController.setContract(contract)
            }
          />
        </div>
        <div className="custom-field">
          <label>Регион</label>
          <SelectComponent
            isDisabled={!UISceneController.createRecalculationsState.contract}
            onMenuScrollToBottom={() => UISceneController.fetchListOfRegions()}
            onFocus={() => UISceneController.fetchListOfRegions()}
            options={UISceneController.listOfRegionsMetadata.data.map(
              (region, index) => ({
                id: region.id,
                index,
                label: region.name,
                name: region.name,
                value: region.id,
              })
            )}
            onInputChange={UISceneController.setRegionSearch}
            isClearable
            isLoading={UISceneController.isRegionsLoading}
            value={
              UISceneController.createRecalculationsState.region
                ? {
                    label:
                      UISceneController.createRecalculationsState.region.name,
                    value:
                      UISceneController.createRecalculationsState.region.id,
                  }
                : null
            }
            onChange={(region: any) => UISceneController.setRegion(region)}
          />
        </div>

        <div className="custom-field">
          <label>Новая цена за тонну, ₽</label>
          <Input
            placeholder="Введите номер договора"
            value={
              UISceneController.createRecalculationsState.new_price_per_ton
            }
            type="number"
            onChange={(price: number) => UISceneController.setPrice(price)}
          />
        </div>
        <div className="custom-field">
          <label>Дата перерасчета</label>
          <Input
            placeholder="дд.мм.гг"
            value={UISceneController.createRecalculationsState.recalculation_date.format(
              "YYYY-MM-DD"
            )}
            type="date"
            onChange={(value: string) =>
              UISceneController.setDeliveryDate(moment(value, "YYYY-MM-DD"))
            }
          />
        </div>
      </div>

      <div className="row">
        <Button
          text={TranslationService.t("txt_add")}
          type={"button"}
          className={"primary"}
          size="small"
          loading={UISceneController.isRecalculationsCreationInProgress}
          onClick={UISceneController.createRecalculations}
        />
      </div>
    </FilterComponent>
  );
});

export default CreateRecalculationsForm;
