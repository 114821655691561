import { IFieldOrderFilterViewModel } from "./__types__/IFieldOrderFilterViewModel";
import { observable, runInAction } from "mobx";

/**
 * Represents the parameters required for creating a FieldOrderFilterViewModel instance.
 */
export type IFieldOrderFilterViewModelParams = {
  keys: string[];
};

/**
 * Represents a view model for field order filtering.
 *
 * @template T - The type of the field.
 */
export class FieldOrderFilterViewModel<T>
  implements IFieldOrderFilterViewModel<T>
{
  /**
   * The state of the view model.
   */
  public _state: {
    /**
     * The current field.
     */
    field: T;
    /**
     * The sort direction.
     */
    direction: "asc" | "desc";
  };

  /**
   * Initializes a new instance of the FieldOrderFilterViewModel class.
   *
   * @param {string} direction - The initial sort direction.
   * @param {T} field - The initial field.
   */
  public constructor(direction: "asc", field: T) {
    this._state = observable({
      direction,
      field,
    });
  }

  public get state(): { field: T; direction: "asc" | "desc" } {
    return {
      direction: this._state.direction,
      field: this._state.field,
    };
  }

  /**
   * Sets the sort direction filter.
   *
   * @param {string} direction - The sort direction ("asc" or "desc").
   */
  public setDirectionFilter = (direction: "asc" | "desc"): void => {
    runInAction(() => {
      this._state.direction = direction;
    });
  };

  /**
   * Sets the field filter.
   *
   * @param {T} field - The field to set.
   */
  public setFieldFilter = (field: T): void => {
    runInAction(() => {
      this._state.field = field;
    });
  };

  /**
   * Performs necessary clean up before destroying the view model.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
  }
}
