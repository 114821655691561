const IconDownload = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.3992 2.92633C17.204 3.67626 17.5817 4.68139 18.2667 6.50676C18.3638 6.76574 18.3539 7.05265 18.2394 7.30439C18.1248 7.55613 17.915 7.75206 17.6559 7.84909C17.3969 7.94612 17.1099 7.9363 16.8581 7.82179C16.6063 7.70727 16.4103 7.49745 16.3132 7.23847C15.722 5.66047 15.4251 4.86627 14.9771 4.44964C14.7932 4.27803 14.5852 4.13417 14.3598 4.02259C13.8128 3.74917 12.9637 3.74917 11.2759 3.74917H8.71815C7.03035 3.74917 6.18124 3.74917 5.63427 4.02259C5.40883 4.13417 5.20087 4.27803 5.01698 4.44964C4.56898 4.86627 4.27205 5.66047 3.6808 7.23847C3.58225 7.49505 3.38625 7.7023 3.13551 7.81503C2.88478 7.92777 2.59964 7.93685 2.34223 7.84031C2.08482 7.74376 1.87602 7.54941 1.76133 7.29962C1.64664 7.04982 1.63535 6.76483 1.72994 6.50676C2.41235 4.68399 2.79002 3.67626 3.59485 2.92633C3.92616 2.61393 4.30073 2.35084 4.70703 2.14514C5.69157 1.66602 6.76728 1.66602 8.71815 1.66602L11.2759 1.66602C13.2268 1.66602 14.3025 1.66602 15.287 2.15556C15.6927 2.35805 16.0672 2.61761 16.3992 2.92633Z"
      fill="#303B57"
    />
    <path
      d="M8.95516 7.6551C8.95516 7.37885 9.06493 7.11392 9.26032 6.91859C9.4557 6.72326 9.7207 6.61352 9.99701 6.61352C10.2733 6.61352 10.5383 6.72326 10.7337 6.91859C10.9291 7.11392 11.0389 7.37885 11.0389 7.6551L11.0389 15.5555L13.383 13.2119C13.5572 13.0294 13.7897 12.9135 14.0403 12.884C14.2908 12.8545 14.5439 12.9134 14.7557 13.0505C14.8812 13.1373 14.9862 13.2507 15.063 13.3826C15.1399 13.5145 15.1868 13.6617 15.2005 13.8138C15.2142 13.9658 15.1942 14.119 15.1421 14.2625C15.09 14.406 15.007 14.5362 14.8989 14.6441L12.1094 17.4329C11.807 17.7419 11.442 17.9825 11.0389 18.1386C10.5093 18.3412 9.9325 18.3864 9.3779 18.2685C8.82331 18.1507 8.31466 17.875 7.91332 17.4746L5.09251 14.6415C4.98243 14.5318 4.89831 14.3988 4.84631 14.2524C4.79431 14.1059 4.77574 13.9497 4.79198 13.7951C4.80821 13.6406 4.85883 13.4916 4.94013 13.3592C5.02142 13.2267 5.13133 13.1141 5.26181 13.0296C5.46726 12.9008 5.71068 12.8463 5.95147 12.8752C6.19226 12.9041 6.41585 13.0147 6.58496 13.1885L8.95516 15.5555L8.95516 7.6551Z"
      fill="#303B57"
    />
  </svg>
);

export default IconDownload;
