import { ScreenControllerFuelingCommentScreen } from "./connector/UIViewController";
import { IScreenControllerFuelingCommentScreen } from "./connector/__types__/UIViewController";
import { FuelingCommentActionForm } from "./components/ActionsForm/ActionForm";
import { FuelingCommentPreview } from "./components/Preview/Preview";
import styled from "styled-components";
import { observer } from "mobx-react";
import { IconArrowLeft } from "@shared/controls/icons";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import withControllers from "@view/providers/__proto__/withController";
import { useControllers } from "@view/hooks/useControllers";
import { FuelingCommentStatus } from "@constants/fueling";
import { Screens } from "@constants/screens";
import { Helmet } from "react-helmet";

const TranslateStatusMap = {
  [FuelingCommentStatus.Pending]: "Загрузка",
  [FuelingCommentStatus.New]: "Новая",
  [FuelingCommentStatus.Fixed]: "Исправлена",
  [FuelingCommentStatus.Declined]: "Отклонена",
};

const FuelingCommentScreen = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCommentScreen;
  }>();

  const navigation = useParams();

  useEffect(() => {
    UIViewController.fetchCommentFuelingData(Number(navigation.id));
  }, []);

  return (
    <Col32 className="col">
      <Helmet>
        <title>{`Заявка №${UIViewController.state.id}`}</title>
      </Helmet>
      <Title>
        <Link to={Screens.Application.path()}>
          <IconArrowLeft />
        </Link>
        Заявка №{UIViewController.state.id}
        <span className={`status status${UIViewController.state.status}`}>
          {
            TranslateStatusMap[
              UIViewController.state.status as FuelingCommentStatus
            ]
          }
        </span>
      </Title>
      <Col48 className="col">
        <Col32 className="col">
          <Col16 className="col">
            <Comment>{UIViewController.state.comment}</Comment>
            {UIViewController.state.file?.url ? (
              <img src={UIViewController.state.file.url} />
            ) : null}
          </Col16>
          <FuelingCommentPreview />
        </Col32>

        <FuelingCommentActionForm />
      </Col48>
    </Col32>
  );
});

export default withControllers(ScreenControllerFuelingCommentScreen)(
  FuelingCommentScreen
);

const Col48 = styled.div`
  gap: 48px;
`;

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const Col16 = styled.div`
  gap: 16px;
`;

const Title = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  .status {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 12px;
    margin-left: 4px;
    border-radius: 4px;
  }

  .status1 {
    background: #ff9900;
  }

  .status2 {
    background: #417d24;
  }

  .status3 {
    background: #ef5d58;
  }

  + div {
    padding-bottom: 40px;
  }
`;

const Comment = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #303b57;

  + img {
    height: fit-content;
    width: fit-content;
    max-height: 500px;
    max-width: 540px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
`;
