import {
  IContractsListFilters,
  IContractsListViewModel,
  TContractsFieldsOrder,
} from "./__types__/IContractsListViewModel";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
import {
  DateFilerType,
  IDatePeriodFilterViewModelParams,
} from "../DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { ISearchFilterViewModel } from "../SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { SearchFilterViewModel } from "../SearchFilterViewModel/SearchFilterViewModel";
import { IFieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/__types__/IFieldOrderFilterViewModel";
import { FieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/FieldOrderFilterViewModel";
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";
import { ContractsRepository } from "@repositories/contracts";
import { IContractsRepository } from "@repositories/contracts/__types__/repository";
import {
  ContractListItemEntity,
  IContractListItemEntity,
} from "@entities/ContractListItemEntity";
import _ from "lodash";
import { AxiosRequestClient, Utils } from "@modules/index";

/**
 * Represents the view model for the contracts list
 */
export class ContractsListViewModel implements IContractsListViewModel {
  /**
   * Manages the status of fetching items
   */
  public statuses: Statuses = new Statuses(["fetchItems"]);

  /**
   * Debounced function to fetch items in batches
   */
  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing),
    400
  );

  /**
   * Private filters applied to the request
   */
  public privateFilters: {
    contract: null | { id: number; number: string };
    contractor: null | { id: number; name: string };
    region: null | { id: number; name: string };
    per_page: number;

    conclusionDate: { date_from: number; date_to: number } | null;
    termDate: { date_from: number; date_to: number } | null;
  } = {
    conclusionDate: null,
    contract: null,
    contractor: null,
    per_page: 12,
    region: null,
    termDate: null,
  };

  /**
   * The field order filter view model
   */
  public FieldOrderFilterViewModel: IFieldOrderFilterViewModel<TContractsFieldsOrder>;

  // /**
  //  * The date period filter view model
  //  */
  // private ConclusionDatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  // /**
  //  * The date period filter view model
  //  */
  // private TermDatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  /**
   * The search filter view model
   */
  private SearchFilterViewModel: ISearchFilterViewModel;

  /**
   * The contracts repository to fetch data from
   */
  private repository: IContractsRepository = new ContractsRepository(
    new AxiosRequestClient()
  );

  /** Pagination list of items */
  private _pagination: IPagination<IContractListItemEntity> = new Pagination(
    undefined
  );

  /**
   * Constructs a new instance of ContractsListViewModel
   *
   * @param model - The root tree model
   * @param params - The parameters for the contracts list view model
   */
  public constructor(
    private model: IRootTreeModel,
    params: {
      search: string;
      per_page?: number;
      conclusionDate?: IDatePeriodFilterViewModelParams;
      termDate?: IDatePeriodFilterViewModelParams;
      // dateType?:
      //   | DateFilerType.All
      //   | DateFilerType.Month
      //   | DateFilerType.Week
      //   | DateFilerType.Year;
    }
  ) {
    // this.ConclusionDatePeriodFilterViewModel = new DatePeriodFilterViewModel(params.conclusionDate);
    // this.TermDatePeriodFilterViewModel = new DatePeriodFilterViewModel(params.termDate);

    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: params.search,
    });

    this.FieldOrderFilterViewModel =
      new FieldOrderFilterViewModel<TContractsFieldsOrder>(
        "asc",
        "conclusionDate"
      );

    if (params.per_page) this.privateFilters.per_page = params.per_page;

    if (
      params.conclusionDate &&
      params.conclusionDate.type === DateFilerType.Custom
    ) {
      this.privateFilters.conclusionDate = {
        date_from: params.conclusionDate.start,
        date_to: params.conclusionDate.end,
      };
    }

    if (params.termDate && params.termDate.type === DateFilerType.Custom) {
      this.privateFilters.termDate = {
        date_from: params.termDate.start,
        date_to: params.termDate.end,
      };
    }
  }

  /**
   * Gets the list of filters applied to the request
   */
  public get filters(): IContractsListFilters {
    return {
      conclusionDate: this.privateFilters.conclusionDate,
      contract: this.privateFilters.contract,
      contractor: this.privateFilters.contractor,
      direction: this.FieldOrderFilterViewModel.state.direction,
      order: this.FieldOrderFilterViewModel.state.field,
      per_page: this.privateFilters.per_page,
      region: this.privateFilters.region,
      search: this.SearchFilterViewModel.state.search,
      termDate: this.privateFilters.termDate,
    };
  }

  /**
   * Gets the meta information about the pagination
   */
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  /**
   * Gets the metadata about the pagination
   */
  public get metadata(): IPagination<IContractListItemEntity> {
    return this._pagination;
  }

  /**
   * Gets the list of contract items
   */
  public get list(): IContractListItemEntity[] {
    return this._pagination.data;
  }

  /**
   * Sets the contract
   *
   * @param contract - The contract object
   */
  public setContract = async (
    contract: {
      id: number;
      number: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contract = contract;
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setConclusionDateFilter = async (
    params: IDatePeriodFilterViewModelParams | null
  ): Promise<void> => {
    if (params?.type === DateFilerType.Custom) {
      // Set the date filter using the provided parameters
      this.privateFilters.conclusionDate = {
        date_from: params.start,
        date_to: params.end,
      };
    } else {
      this.privateFilters.conclusionDate = null;
    }
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setTermDateFilter = async (
    params: IDatePeriodFilterViewModelParams | null
  ): Promise<void> => {
    if (params?.type === DateFilerType.Custom) {
      // Set the date filter using the provided parameters
      this.privateFilters.termDate = {
        date_from: params.start,
        date_to: params.end,
      };
    } else {
      this.privateFilters.termDate = null;
    }
  };

  /**
   * Sets the contractor
   *
   * @param contractor - The contractor object
   */
  public setContractor = async (
    contractor: {
      id: number;
      name: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contractor = contractor;
  };

  /**
   * Sets the region
   *
   * @param region - The region object
   */
  public setRegion = (region: { id: number; name: string } | null): void => {
    this.privateFilters.region = region;
  };

  /**
   * Sets the field order
   *
   * @param field - The field to be sorted by
   * @param direction - The sorting direction (asc or desc)
   */
  public setFieldOrder = (
    field: TContractsFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    this.FieldOrderFilterViewModel.setFieldFilter(field);
    this.FieldOrderFilterViewModel.setDirectionFilter(direction);
  };

  /**
   * Sets the search filter
   *
   * @param search - The search query
   */
  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  /**
   * Fetches items in batches
   *
   * @param page - The page number
   * @param refreshing - Indicates if the fetch is refreshing the list
   */
  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }

      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      if (refreshing || page === 1) {
        this._pagination.metadata = {};
      }

      const response = await this.repository.getContractsList({
        conclusionDate: this.filters.conclusionDate,
        contract: this.filters.contract?.number,
        contractor: this.filters.contractor?.id,
        // date_to: this.filters.date_to,
        direction: this.filters.direction,
        order: this.filters.order,
        page,
        per_page: this.filters.per_page,
        region: this.filters.region?.id || undefined,
        search: this.filters.search,
        termDate: this.filters.termDate,
      });

      if (response.meta.current_page === 1) {
        this._pagination.metadata = {
          data: response.data.map(
            (item: any): IContractListItemEntity =>
              ContractListItemEntity.create(item)
          ),
          meta: response.meta,
        };
      } else {
        this._pagination.metadata = {
          data: _.unionBy(
            this._pagination.data,
            response.data.map(
              (item: any): IContractListItemEntity =>
                ContractListItemEntity.create(item)
            )
          ),
          meta: response.meta,
        };
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clears the list
   */
  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  /**
   * Clean up resources before destroying the view model
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
    this.repository.beforeDestroy();
  }
}
