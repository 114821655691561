export const RTLLanguages = {
  ar: "Arabic",
  arc: "Aramaic",
  dv: "Divehi",
  fa: "Persian",
  ha: "Hausa",
  he: "Hebrew",
  khw: "Khowar",
  ks: "Kashmiri",
  ku: "Kurdish",
  ps: "Pashto",
  ur: "Urdu",
  yi: "Yiddish",
};

export enum LANGUAGE_CODES {
  EN = "en",
  AR = "ar",
  RU = "ru",
}

export const SystemLanguage = LANGUAGE_CODES.RU;

export const RTLIsoCodes = Object.keys(RTLLanguages);

export enum SupportedLanguages {
  En,
  Ar,
}

export enum LANGUAGES {
  EN = 1,
  AR = 2,
  RU = 3,
}

export const LanguageMap: { [key in LANGUAGES]: string } = {
  [LANGUAGES.EN]: LANGUAGE_CODES.EN,
  [LANGUAGES.AR]: LANGUAGE_CODES.AR,
  [LANGUAGES.RU]: LANGUAGE_CODES.RU,
};
