export enum FuelingCommentStatus {
  Pending = 0,
  New = 1,
  Fixed = 2,
  Declined = 3,
}

export enum FuelingType {
  Unknown,
  General,
  Transfer,
}
