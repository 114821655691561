import { DtoFuelingListItemScheme } from "./DtoFuelingListItemScheme";
import Joi from "joi";

export const DtoFuelingTurnoverListSubItemScheme = Joi.object({
  conclusionDate: Joi.string().required(),
  contractor: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
  }).required(),
  id: Joi.number().required(),
  number: Joi.string().required(),
  regions: Joi.array().items(
    Joi.object({
      criticalWeight: Joi.number().required(),
      id: Joi.number().required(),
      minimalWeight: Joi.number().required(),
      name: Joi.string().required(),
    })
  ),
  requests: Joi.array().items(DtoFuelingListItemScheme),
  termDate: Joi.string().required(),
  totals: Joi.object({
    total: Joi.number().required(),
    totalIn: Joi.number().required(),
    totalOut: Joi.number().required(),
    transferIn: Joi.number().required(),
    transferOut: Joi.number().required(),
  }),
});

export const DtoFuelingTurnoverListItemScheme = Joi.object({
  id: Joi.number().required(),
  items: Joi.array().items(DtoFuelingTurnoverListSubItemScheme),
  name: Joi.string().required(),
  regions: Joi.array().items(
    Joi.object({
      criticalWeight: Joi.number().required(),
      id: Joi.number().required(),
      minimalWeight: Joi.number().required(),
      name: Joi.string().required(),
    })
  ),
});
