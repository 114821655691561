const IconReports = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0968 2H10.7097C9.93969 2 9.20125 2.30587 8.65679 2.85034C8.11233 3.3948 7.80645 4.13324 7.80645 4.90323V7.80645H4.90323C4.13324 7.80645 3.3948 8.11233 2.85034 8.65679C2.30587 9.20125 2 9.93969 2 10.7097V19.0968C2 19.8668 2.30587 20.6052 2.85034 21.1497C3.3948 21.6941 4.13324 22 4.90323 22H13.2903C14.0603 22 14.7988 21.6941 15.3432 21.1497C15.8877 20.6052 16.1935 19.8668 16.1935 19.0968V16.1935H19.0968C19.8668 16.1935 20.6052 15.8877 21.1497 15.3432C21.6941 14.7988 22 14.0603 22 13.2903V4.90323C22 4.13324 21.6941 3.3948 21.1497 2.85034C20.6052 2.30587 19.8668 2 19.0968 2ZM14.2581 19.0968C14.2581 19.3534 14.1561 19.5996 13.9746 19.7811C13.7931 19.9626 13.547 20.0645 13.2903 20.0645H4.90323C4.64656 20.0645 4.40042 19.9626 4.21893 19.7811C4.03744 19.5996 3.93548 19.3534 3.93548 19.0968V10.7097C3.93548 10.453 4.03744 10.2069 4.21893 10.0254C4.40042 9.84389 4.64656 9.74194 4.90323 9.74194H7.80645V13.2903C7.80645 14.0603 8.11233 14.7988 8.65679 15.3432C9.20125 15.8877 9.93969 16.1935 10.7097 16.1935H14.2581V19.0968ZM14.2581 14.2581H10.7097C10.453 14.2581 10.2069 14.1561 10.0254 13.9746C9.84389 13.7931 9.74194 13.547 9.74194 13.2903V9.74194H13.2903C13.547 9.74194 13.7931 9.84389 13.9746 10.0254C14.1561 10.2069 14.2581 10.453 14.2581 10.7097V14.2581ZM20.0645 13.2903C20.0645 13.547 19.9626 13.7931 19.7811 13.9746C19.5996 14.1561 19.3534 14.2581 19.0968 14.2581H16.1935V10.7097C16.1935 9.93969 15.8877 9.20125 15.3432 8.65679C14.7988 8.11233 14.0603 7.80645 13.2903 7.80645H9.74194V4.90323C9.74194 4.64656 9.84389 4.40042 10.0254 4.21893C10.2069 4.03744 10.453 3.93548 10.7097 3.93548H19.0968C19.3534 3.93548 19.5996 4.03744 19.7811 4.21893C19.9626 4.40042 20.0645 4.64656 20.0645 4.90323V13.2903Z"
      fill="#303B57"
    />
  </svg>
);

export default IconReports;
