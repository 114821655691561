import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

export const PilotListItemEntity = types
  .model({
    id: types.identifierNumber,
    name: types.optional(types.string, ""),
  })
  .named("PilotListItemEntity");

export type IPilotListItemEntity = Instance<typeof PilotListItemEntity>;
export type IPilotListItemEntityIn = SnapshotIn<IPilotListItemEntity>;
export type IPilotListItemEntityOut = SnapshotOut<IPilotListItemEntity>;
