import { IScreenControllerDocContract } from "@screens/DocContractsScreen/connector/__types__/UIViewController";
import { Sorting } from "@shared/controls/Sorting/Sorting";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const SortingMobile = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerDocContract;
  }>();

  return (
    <Sorting
      list={[
        [
          "По дате заключения",
          "conclusionDate",
          "Сначала ранние",
          "Сначала поздние",
        ],
        [
          "По дате срока договора",
          "termDate",
          "Сначала ранние",
          "Сначала поздние",
        ],
      ]}
      sortBy={UIViewController.listOfContractsFilters.order}
      sortDir={UIViewController.listOfContractsFilters.direction}
      onChangeOrder={UIViewController.setFieldOrderBy}
    />
  );
});
