import { IScreenControllerReportsByRegion } from "@screens/ReportByRegionsScreen/connector/__types__/UIViewController";
import SelectComponent from "@shared/controls/Select/Select";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";

export const ReportByRegionsAdditionalFilters = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportsByRegion;
  }>();

  return (
    <ColGap16>
      <label>Регион</label>
      <SelectComponent
        isClearable
        onFocus={UIViewController.fetchListOfRegions}
        options={UIViewController.listOfRegionsMetadata.data.map(
          (region, index) => ({
            id: region.id,
            index,
            label: region.name,
            name: region.name,
            value: region.id,
          })
        )}
        onMenuScrollToBottom={() => UIViewController.fetchListOfRegions()}
        onInputChange={UIViewController.setRegionSearch}
        isLoading={UIViewController.isRegionsLoading}
        // value={UIViewController.listOfFuelingFilters.region}
        value={
          UIViewController.listOfFuelingFilters.region
            ? {
                label: UIViewController.listOfFuelingFilters.region.name,
                value: UIViewController.listOfFuelingFilters.region.id,
              }
            : null
        }
        onChange={(region: any) => UIViewController.setRegion(region)}
      />
    </ColGap16>
  );
});

const ColGap16 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
