import {
  IconArrowDown,
  IconArrowLeft,
  IconArrowLeftLong,
  IconArrowRight,
  IconCross,
  IconDate,
} from "../icons";
import checkImg from "../Select/images/check.svg";
import Button from "@shared/controls/Button/Button";

import { TranslationService } from "@services/translate";
import {
  DateFilerType,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { observer } from "mobx-react";
import moment from "moment";
import "moment/locale/ru";
import React, { ChangeEvent, useCallback, useState } from "react";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import "./styles.scss";
import Calendar from "react-calendar";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import DropdownButton from "react-bootstrap/DropdownButton";
import Offcanvas from "react-bootstrap/Offcanvas";

type Props = {
  type: DateFilerType;
  date_from?: number;
  date_to?: number;

  onChangeDateFilter?: (params: IDatePeriodFilterViewModelParams) => void;
};

const list = [
  { name: TranslationService.t("txt_filter_all"), value: DateFilerType.All },
  { name: TranslationService.t("txt_filter_year"), value: DateFilerType.Year },
  {
    name: TranslationService.t("txt_filter_month"),
    value: DateFilerType.Month,
  },
  { name: TranslationService.t("txt_filter_week"), value: DateFilerType.Week },
  // { name: "Период", value: DateFilerType.Custom },
];

export const DateFilters = observer((props: Props): JSX.Element => {
  const [isCalendarShown, setCalendarShown] = useState(false);
  const [activeDate, setActiveDate] = useState<Date | undefined>(new Date());
  const [openDateMobile, setOpenDateMobile] = useState<boolean>(false);
  const [rangeValue, setRangeValue] = useState([
    moment(props.date_from).toDate(),
    moment(props.date_to).toDate(),
  ]);
  moment.locale("ru");

  const onChangeType = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (props.onChangeDateFilter)
      props.onChangeDateFilter({
        type: event.target.value as
          | DateFilerType.All
          | DateFilerType.Month
          | DateFilerType.Week
          | DateFilerType.Year,
      });
  }, []);

  const onChangeDate = useCallback((date: any) => {
    if (!Array.isArray(date)) return;

    setRangeValue(date);
  }, []);

  const handleSaveDateRange = useCallback(() => {
    if (props.onChangeDateFilter)
      props.onChangeDateFilter({
        end: moment(rangeValue[1]).unix() * 1000,
        start: moment(rangeValue[0]).unix() * 1000,
        type: DateFilerType.Custom,
      });
    setCalendarShown(false);
  }, [rangeValue]);

  const onClearDate = useCallback(() => {
    if (props.onChangeDateFilter)
      props.onChangeDateFilter({
        type: DateFilerType.All,
      });
  }, []);

  const handlePrevMonth = useCallback(() => {
    if (activeDate) {
      setActiveDate(new Date(activeDate.setMonth(activeDate.getMonth() - 1)));
    }
  }, [activeDate, setActiveDate]);

  const handleNextMonth = useCallback(() => {
    if (activeDate) {
      setActiveDate(new Date(activeDate.setMonth(activeDate.getMonth() + 1)));
    }
  }, [activeDate, setActiveDate]);

  const handleChangeMonth = useCallback(
    (ind: number) => {
      if (activeDate) {
        setActiveDate(
          new Date(
            `${activeDate?.getFullYear()}-${ind < 9 ? "0" : ""}${
              ind + 1
            }-01T12:00:00`
          )
        );
      }
    },
    [activeDate]
  );

  const handleChangeYear = useCallback(
    (el: number) => {
      if (activeDate) {
        setActiveDate(
          new Date(
            `${el}-${+activeDate?.getMonth() < 9 ? "0" : ""}${
              +activeDate?.getMonth() + 1
            }-01T12:00:00`
          )
        );
      }
    },
    [activeDate]
  );

  const handleCloseMobileModal = useCallback(() => {
    setCalendarShown(false);
    setOpenDateMobile(false);
    setOpenDateTimeMobile(false);
  }, []);

  const handleRemoveActiveDate = useCallback(() => {
    setOpenDateTimeMobile(false);
    setActiveDate(new Date());
  }, []);

  const handleSaveDateTime = useCallback(() => {
    setOpenDateTimeMobile(false);
    if (activeDate === undefined || null || !new Date(activeDate))
      setActiveDate(new Date());
  }, [activeDate]);

  const windowWidth = useWindowWidth();
  const [openDateTimeMobile, setOpenDateTimeMobile] = useState<boolean>(false);

  return (
    <>
      {windowWidth > 768 ? (
        <>
          <ButtonGroup>
            {list.map((item, idx) => (
              <ToggleButton
                key={idx}
                id={`datefilter-${idx}`}
                type="radio"
                variant={item.value === props.type ? "active" : ""}
                name="radio"
                value={item.value}
                checked={item.value === props.type}
                onChange={onChangeType}
              >
                {item.name}
              </ToggleButton>
            ))}
            <ToggleButton
              key={DateFilerType.Custom}
              id={`datefilter-${DateFilerType.Custom}`}
              type="radio"
              className="date-option"
              variant={DateFilerType.Custom === props.type ? "active" : ""}
              name="radio"
              value={DateFilerType.Custom}
              checked={DateFilerType.Custom === props.type}
            >
              {props.type === DateFilerType.Custom ? (
                <>
                  <IconDate />
                  {moment(props.date_from).format("DD MMM YYYY")} &#8212;&nbsp;
                  {moment(props.date_to).format("DD MMM YYYY")}
                  <IconCross onClick={onClearDate} />
                </>
              ) : (
                <IconDate onClick={() => setCalendarShown(true)} />
              )}
            </ToggleButton>
          </ButtonGroup>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isCalendarShown}
          >
            <Modal.Header>
              <IconCross onClick={() => setCalendarShown(false)} />
            </Modal.Header>
            <Modal.Body>
              <div className="col gap8" style={{ marginTop: "20px" }}>
                <Row>
                  <Arrow onClick={handlePrevMonth}>
                    <IconArrowLeft />
                  </Arrow>
                  <DropdownButton
                    autoClose="outside"
                    id="dropdown-date"
                    title={
                      <TitleDate>
                        <RowGap8>
                          <span>
                            {activeDate &&
                              activeDate.toLocaleString("default", {
                                month: "long",
                              })}
                          </span>
                          <span>
                            {activeDate && activeDate.getUTCFullYear()}
                          </span>
                        </RowGap8>
                        <IconArrowDown />
                      </TitleDate>
                    }
                  >
                    <Col>
                      {[
                        "январь",
                        "февраль",
                        "март",
                        "апрель",
                        "май",
                        "июнь",
                        "июль",
                        "август",
                        "сентябрь",
                        "октябрь",
                        "ноябрь",
                        "декабрь",
                      ].map((el, ind) => (
                        <p
                          key={el + ind}
                          className={
                            activeDate &&
                            activeDate.toLocaleString("default", {
                              month: "long",
                            }) === el
                              ? "active"
                              : ""
                          }
                          onClick={() => handleChangeMonth(ind)}
                        >
                          {el}
                        </p>
                      ))}
                    </Col>
                    <Line />
                    <Col>
                      {Array.from(
                        { length: 100 },
                        (_, index) => 2023 - index
                      ).map((el, ind) => (
                        <p
                          key={el + ind + "year" + el}
                          className={
                            activeDate && activeDate.getUTCFullYear() === el
                              ? "active"
                              : ""
                          }
                          onClick={() => handleChangeYear(el)}
                        >
                          {el}
                        </p>
                      ))}
                    </Col>
                  </DropdownButton>

                  <Arrow onClick={handleNextMonth}>
                    <IconArrowRight />
                  </Arrow>
                </Row>
                <Calendar
                  value={[
                    moment(rangeValue[0]).toDate(),
                    moment(rangeValue[1]).toDate(),
                  ]}
                  selectRange={true}
                  locale={"ru-RU"}
                  onChange={onChangeDate}
                  next2Label={null}
                  prev2Label={null}
                  activeStartDate={activeDate}
                  view={"month"}
                  showNavigation={false}
                  maxDate={new Date()}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="w-100">
              <Button
                text={"Выбрать"}
                type={"button"}
                className={"primary w-100"}
                size="small"
                onClick={handleSaveDateRange}
              />
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
      {windowWidth <= 768 ? (
        <>
          <Button
            className="primary d-flex gap0"
            size="small"
            type={"button"}
            iconLeft={<IconDate />}
            onClick={() => setOpenDateMobile(true)}
          />
          <Offcanvas
            show={openDateMobile}
            onHide={handleCloseMobileModal}
            placement={"bottom"}
          >
            {openDateMobile && !isCalendarShown && !openDateTimeMobile && (
              <Offcanvas.Header>
                <Offcanvas.Title>Фильтр по дате</Offcanvas.Title>
                <IconCross onClick={handleCloseMobileModal} />
              </Offcanvas.Header>
            )}
            {openDateMobile && isCalendarShown && !openDateTimeMobile && (
              <Offcanvas.Header>
                <IconArrowLeftLong
                  onClick={() => {
                    setCalendarShown(false);
                    setRangeValue([new Date(), new Date()]);
                    setActiveDate(new Date());
                  }}
                />
                <Offcanvas.Title>Выбор периода</Offcanvas.Title>
                <IconCross onClick={handleCloseMobileModal} />
              </Offcanvas.Header>
            )}
            {openDateMobile && isCalendarShown && openDateTimeMobile && (
              <Offcanvas.Header>
                <IconArrowLeftLong onClick={handleRemoveActiveDate} />
                <Offcanvas.Title>Выбор месяца и года</Offcanvas.Title>
                <IconCross onClick={handleCloseMobileModal} />
              </Offcanvas.Header>
            )}
            <Offcanvas.Body>
              {openDateMobile && !isCalendarShown && !openDateTimeMobile && (
                <ButtonGroup>
                  {list.map((item, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`datefilter-${idx}`}
                      type="radio"
                      variant={item.value === props.type ? "active" : ""}
                      name="radio"
                      value={item.value}
                      checked={item.value === props.type}
                      onChange={onChangeType}
                    >
                      {item.name}
                    </ToggleButton>
                  ))}
                  <ToggleButton
                    key={DateFilerType.Custom}
                    id={`datefilter-${DateFilerType.Custom}`}
                    type="radio"
                    className="date-option"
                    variant={
                      DateFilerType.Custom === props.type ? "active" : ""
                    }
                    name="radio"
                    value={DateFilerType.Custom}
                    checked={DateFilerType.Custom === props.type}
                  >
                    {props.type === DateFilerType.Custom ? (
                      <>
                        {moment(props.date_from).format("DD MMM YYYY")}-
                        {moment(props.date_to).format("DD MMM YYYY")}
                        <IconCross onClick={onClearDate} />
                      </>
                    ) : (
                      <span
                        className="w-100"
                        onClick={() => setCalendarShown(true)}
                      >
                        <IconDate />
                      </span>
                    )}
                  </ToggleButton>
                </ButtonGroup>
              )}
              {openDateMobile && isCalendarShown && !openDateTimeMobile && (
                <div className="col gap8" style={{ marginTop: "20px" }}>
                  <Row>
                    <Arrow onClick={handlePrevMonth}>
                      <IconArrowLeft />
                    </Arrow>
                    <TitleDate onClick={() => setOpenDateTimeMobile(true)}>
                      <RowGap8>
                        <span>
                          {activeDate &&
                            activeDate.toLocaleString("default", {
                              month: "long",
                            })}
                        </span>
                        <span>{activeDate && activeDate.getUTCFullYear()}</span>
                      </RowGap8>
                      <IconArrowDown />
                    </TitleDate>

                    <Arrow onClick={handleNextMonth}>
                      <IconArrowRight />
                    </Arrow>
                  </Row>
                  <Calendar
                    value={[
                      moment(rangeValue[0]).toDate(),
                      moment(rangeValue[1]).toDate(),
                    ]}
                    selectRange={true}
                    locale={"ru-RU"}
                    onChange={onChangeDate}
                    next2Label={null}
                    prev2Label={null}
                    activeStartDate={activeDate}
                    view={"month"}
                    showNavigation={false}
                    maxDate={new Date()}
                  />
                  <Button
                    text={"Выбрать"}
                    type={"button"}
                    className={"primary w-100"}
                    size="small"
                    onClick={handleSaveDateRange}
                  />
                </div>
              )}
              {openDateMobile && isCalendarShown && openDateTimeMobile && (
                <>
                  <RowDate>
                    <Col>
                      {[
                        "январь",
                        "февраль",
                        "март",
                        "апрель",
                        "май",
                        "июнь",
                        "июль",
                        "август",
                        "сентябрь",
                        "октябрь",
                        "ноябрь",
                        "декабрь",
                      ].map((el, ind) => (
                        <p
                          key={el + ind}
                          className={
                            activeDate &&
                            activeDate.toLocaleString("default", {
                              month: "long",
                            }) === el
                              ? "active"
                              : ""
                          }
                          onClick={() => handleChangeMonth(ind)}
                        >
                          {el}
                        </p>
                      ))}
                    </Col>
                    <Line />
                    <Col>
                      {Array.from(
                        { length: 100 },
                        (_, index) => 2023 - index
                      ).map((el, ind) => (
                        <p
                          key={el + ind + "year" + el}
                          className={
                            activeDate && activeDate.getUTCFullYear() === el
                              ? "active"
                              : ""
                          }
                          onClick={() => handleChangeYear(el)}
                        >
                          {el}
                        </p>
                      ))}
                    </Col>
                  </RowDate>
                  <Button
                    text={"Выбрать"}
                    type={"button"}
                    className={"primary"}
                    onClick={handleSaveDateTime}
                    disabled={!activeDate?.getDate}
                  />
                </>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : null}
    </>
  );
});

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Arrow = styled.div`
  background: #f0f2f8;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const TitleDate = styled.div`
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  display: flex;
  align-items: center;
  gap: 14px;

  @media (max-width: 768px) {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const RowGap8 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .btn-check {
    display: none;
  }

  p,
  .btn-check + label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 0;

    &.active {
      color: #ef5d58;

      @media (max-width: 768px) {
        &::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          width: 16px;
          height: 100%;
          background-image: url(${checkImg});
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  .btn-check: checked + label {
    color: #ef5d58;
    &::after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 16px;
      height: 100%;
      background-image: url(${checkImg});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

const Month = styled.div`
  display: flex;

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    width: 100%;
  }

  input::checked + label {
    color: #ef5d58;

    &::after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 16px;
      height: 100%;
      background-image: url(${checkImg});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

const Line = styled.div`
  width: 1px;
  height: auto;
  content: "";
  background: #dbdce1;
`;

const RowDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  max-height: 328px;
  margin-bottom: 24px;
  align-items: stretch;

  @media (max-width: 768px) {
    gap: 16px;
  }

  + button {
    width: 100%;
  }
`;
