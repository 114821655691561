import { Screens } from "@constants/screens";
import { IScreenControllerFuelingCommentEditScreen } from "@screens/FuelingCommentEditScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import Button from "@shared/controls/Button/Button";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export const FuelingCommentEditActionForm = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCommentEditScreen;
  }>();

  const params = useParams();
  const navigate = useNavigate();

  const declineEditing = useCallback(() => {
    navigate(Screens.FuelingComment.path(params.id));
  }, []);

  const saveFuelingComment = useCallback(async () => {
    try {
      await UIViewController.saveEditState();
      navigate(Screens.FuelingComment.path(params.id));
    } catch (error) {
      return;
    }
  }, []);

  return (
    <Col16 className="row">
      <Button
        text={TranslationService.t("txt_cancel")}
        type={"button"}
        size="small"
        className={"secondary"}
        disabled={UIViewController.isRequestInProgress}
        onClick={declineEditing}
      />

      <Button
        text={"Сохранить изменения"}
        type={"button"}
        size="small"
        className={"primary"}
        loading={UIViewController.isSavingInProgress}
        disabled={UIViewController.isRequestInProgress}
        onClick={saveFuelingComment}
      />
    </Col16>
  );
});

const Col16 = styled.div`
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
