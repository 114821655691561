import { IProfileRepositoryResponseDto } from "./__types__/dtoResponse";
import { TDtoProfileEntry } from "../../modules/utils/dto/parsers/__types__/TDtoProfileEntry.type";
import { Utils } from "@modules/index";

export class ProfileRepositoryResponseDto
  implements IProfileRepositoryResponseDto
{
  public static getProfile = (data: unknown): TDtoProfileEntry =>
    Utils.dto.transform(
      Utils.dto.Schemes.Profile,
      data,
      Utils.dto.Parsers.Profile
    );
}
