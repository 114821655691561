import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";
import { TDtoRecalculationsListEntry } from "@modules/utils/dto/parsers/__types__/TDtoRecalculationsListEntry.types";

export const DtoRecalculationsListParser: TParseDto<
  TDtoRecalculationsListEntry
> = (data: any): TDtoRecalculationsListEntry =>
  Array.isArray(data.list)
    ? data.list.map((listElement: any) => ({
        contract: {
          id: Number(data.contract.id),
          number: Number(data.contract.number) || 0,
        },
        id: Number(listElement.id),

        newPricePerTon: Number(listElement.newPricePerTon) || 0,
        oldPricePerTon: Number(listElement.oldPricePerTon) || 0,

        recalculationDate: Utils.isString(listElement.recalculationDate)
          ? listElement.recalculationDate
          : "",

        region: {
          id: Number(listElement.region.id),
          name: Utils.isString(listElement.region.name)
            ? listElement.region.name
            : "",
        },
      }))
    : [];
