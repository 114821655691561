const IconAddFile = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.97882 1.53502C10.3024 1.40101 10.6491 1.33203 10.9993 1.33203C11.3495 1.33203 11.6963 1.40101 12.0198 1.53502C12.3433 1.66903 12.6373 1.86546 12.8849 2.11308C13.1325 2.3607 13.329 2.65467 13.463 2.97821C13.597 3.30174 13.666 3.64851 13.666 3.9987C13.666 4.34889 13.597 4.69565 13.463 5.01919C13.3293 5.34195 13.1335 5.63529 12.8867 5.88254L6.6782 12.1775C6.36564 12.4901 5.9417 12.6657 5.49967 12.6657C5.05765 12.6657 4.63372 12.4901 4.32116 12.1775C4.0086 11.865 3.83301 11.441 3.83301 10.999C3.83301 10.558 4.00778 10.135 4.31898 9.82268L9.52404 4.53119C9.78224 4.26871 10.2043 4.26523 10.4668 4.52343C10.7293 4.78163 10.7328 5.20372 10.4746 5.46621L5.26399 10.7633C5.20148 10.8258 5.16634 10.9106 5.16634 10.999C5.16634 11.0874 5.20146 11.1722 5.26397 11.2347C5.32648 11.2972 5.41127 11.3323 5.49967 11.3323C5.58752 11.3323 5.67179 11.2977 5.73418 11.2359L11.9421 4.9415C12.0659 4.81769 12.1641 4.67071 12.2311 4.50894C12.2982 4.34718 12.3326 4.17379 12.3326 3.9987C12.3326 3.8236 12.2982 3.65022 12.2311 3.48845C12.1641 3.32669 12.0659 3.1797 11.9421 3.05589C11.8183 2.93208 11.6713 2.83386 11.5096 2.76686C11.3478 2.69985 11.1744 2.66536 10.9993 2.66536C10.8242 2.66536 10.6508 2.69985 10.4891 2.76686C10.3279 2.8336 10.1815 2.93131 10.0579 3.05444L3.84977 9.3491C3.41218 9.78668 3.16634 10.3802 3.16634 10.999C3.16634 11.6178 3.41217 12.2113 3.84976 12.6489C4.28734 13.0865 4.88084 13.3323 5.49967 13.3323C6.11851 13.3323 6.71201 13.0865 7.14959 12.6489L12.2782 7.52699C12.5387 7.26681 12.9608 7.26708 13.221 7.5276C13.4812 7.78812 13.4809 8.21023 13.2204 8.47041L8.0924 13.5917C8.0923 13.5918 8.0925 13.5916 8.0924 13.5917C7.4048 14.2792 6.47199 14.6657 5.49967 14.6657C4.52721 14.6657 3.59458 14.2794 2.90695 13.5917C2.21932 12.9041 1.83301 11.9715 1.83301 10.999C1.83301 10.0273 2.21867 9.09546 2.90524 8.40799L9.11368 2.11307C9.3613 1.86545 9.65528 1.66903 9.97882 1.53502Z"
      fill="#303B57"
    />
  </svg>
);

export default IconAddFile;
