const IconPlus = (): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1735_3122)">
      <path
        d="M4.47949 8.64551C4.47949 8.78364 4.53436 8.91612 4.63204 9.01379C4.72972 9.11147 4.86219 9.16634 5.00032 9.16634C5.13846 9.16634 5.27093 9.11147 5.36861 9.01379C5.46628 8.91612 5.52116 8.78364 5.52116 8.64551L5.52116 5.52051L8.64616 5.52051C8.78429 5.52051 8.91677 5.46563 9.01444 5.36796C9.11212 5.27028 9.16699 5.13781 9.16699 4.99967C9.16699 4.86154 9.11212 4.72907 9.01444 4.63139C8.91677 4.53371 8.78429 4.47884 8.64616 4.47884L5.52116 4.47884L5.52116 1.35384C5.52116 1.21571 5.46629 1.08323 5.36861 0.985556C5.27093 0.887881 5.13846 0.833008 5.00033 0.833008C4.86219 0.833008 4.72972 0.887881 4.63204 0.985556C4.53437 1.08323 4.47949 1.21571 4.47949 1.35384L4.47949 4.47884L1.35449 4.47884C1.21636 4.47884 1.08388 4.53371 0.986206 4.63139C0.888531 4.72906 0.833658 4.86154 0.833658 4.99967C0.833658 5.13781 0.888531 5.27028 0.986206 5.36796C1.08388 5.46563 1.21636 5.52051 1.35449 5.52051L4.47949 5.52051L4.47949 8.64551Z"
        fill="#FCFCFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1735_3122">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(10) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconPlus;
