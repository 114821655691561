import { ISidebarController } from "../../connector/__types__/UIViewController";
import { IconArrowDown } from "@shared/controls/icons";
import { useCallback, useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useControllers } from "@view/hooks/useControllers";

interface NavLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  to: string;
  className?: any;
}

interface IStyledProps {
  hideMenuOpen?: boolean;
  open?: boolean;
  className?: any;
  onClick?: void;
}

type TChildProps = {
  name: string;
  link: string;
};

type TElementProps = {
  icon: JSX.Element;
  name: string;
  link: string;
  list?: TChildProps[];
};

type TProps = {
  element: TElementProps;
  hideMenuOpen: boolean;
};

const LinkComponent = ({ element, hideMenuOpen }: TProps): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: ISidebarController;
  }>();

  const [open, setOpen] = useState(false);
  const [activeParent, setActiveParent] = useState(false);

  const location = useLocation();

  const handleOpenList = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (location.pathname.includes(element.link)) setActiveParent(true);
    else setActiveParent(false);
  }, [location, element.link]);

  return (
    <Wrapper>
      {element?.list ? (
        <RowBetween
          className={activeParent ? "active" : ""}
          onClick={handleOpenList}
          style={{
            justifyContent: UIViewController.isOpenedSideMenu
              ? "space-between"
              : "center",
          }}
        >
          <Row>
            {element.icon}
            {UIViewController.isOpenedSideMenu && <p>{element.name}</p>}
          </Row>
          {UIViewController.isOpenedSideMenu && (
            <Icon open={open}>
              <IconArrowDown />
            </Icon>
          )}
        </RowBetween>
      ) : (
        <LinkRowBetween
          style={{
            justifyContent: UIViewController.isOpenedSideMenu
              ? "space-between"
              : "center",
          }}
          to={element.link}
          className={({ isActive }:any):any => (isActive ? "active" : "")}
        >
          <Row>
            {element.icon}
            {UIViewController.isOpenedSideMenu && <p>{element.name}</p>}
          </Row>
        </LinkRowBetween>
      )}

      {element?.list && open && hideMenuOpen && (
        <NavList>
          {element.list?.map((child, indChild) => (
            <Child
              key={`${element}${child}${indChild}`}
              to={child.link}
              className={({ isActive }:any):any => (isActive ? "active" : "")}
            >
              {child.name}
            </Child>
          ))}
        </NavList>
      )}
    </Wrapper>
  );
};

export default LinkComponent;

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const Icon = styled.div<Pick<IStyledProps, "open">>`
  transform: ${({ open }) => open && "rotate(180deg)"};
  transition: transform 0.2s ease-in-out;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const RowBetween = styled(Row)<Pick<IStyledProps, "className">>`
  justify-content: space-between;
  padding: 16px 20px;
  color: #303b57;

  &.active {
    background: #f0f2f8;
    color: #ef5d58;

    p {
      color: #ef5d58;
    }
    svg path {
      fill: #ef5d58;
    }
  }
`;

const LinkRowBetween = styled(NavLink)<Pick<NavLinkProps, "className">>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 20px;
  color: #303b57;
  &.active {
    background: #f0f2f8;
    color: #ef5d58;

    p {
      color: #ef5d58;
    }

    svg path {
      fill: #ef5d58;
    }
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const Child = styled(NavLink)<Pick<NavLinkProps, "className">>`
  padding: 12px 10px 12px 44px;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #303b57;

  &.active {
    font-size: 12px;
    background: #f0f2f8;
    color: #ef5d58;
  }
`;
