import { IContractorsRepository } from "./__types__/repository";
import { ContractorsRepositoryResponseDto } from "./dtoResponse";
import { TDtoContractorListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoContractorListItemEntry.types";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";

export class ContractorsRepository implements IContractorsRepository {
  public readonly abortControllers = {
    getContractorsList: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getContractorsList = async (params: {
    page: number;
    per_page: number;
    search?: string;
    region?: number;
    contract?: number;
    contractor?: number;
    date_from?: number;
    date_to?: number;
  }): Promise<TDtoPaginationEntry<TDtoContractorListItemEntry>> => {
    if (this.abortControllers.getContractorsList) {
      this.abortControllers.getContractorsList.abort();
    }
    this.abortControllers.getContractorsList = new AbortController();

    const response: any = await this.requestClient.get(`/contractor`, {
      params: {
        contract: params.contract,
        contractor: params.contractor,
        date_from: params.date_from,
        date_to: params.date_to,
        limit: params.per_page,
        name: params.search,
        page: params.page,
        regions: [params.region],
      },
      signal: this.abortControllers.getContractorsList.signal,
    });

    return ContractorsRepositoryResponseDto.getListOfContractors(response);
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
