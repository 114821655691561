import { hash } from "../hash";
import { IUtils } from "../types";

export const compare: IUtils["compare"] = (
  first: any,
  second: any,
  onFailCallback?: () => void
): boolean => {
  if (hash(first) === hash(second)) return true;
  onFailCallback?.call(this);
  return false;
};
