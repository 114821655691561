import Joi from "joi";

export const DtoNewScoreScheme = Joi.object({
  contract: Joi.number().required(),
  deliveryDate: Joi.string().required(),
  number: Joi.string().required(),
  price: Joi.number().required(),
  region: Joi.number().required(),
  weight: Joi.number().required(),
});
