import { useScenes } from "../../hook";
import React from "react";
import { observer } from "mobx-react";
import { SceneContext } from "@view/hooks/useScene/useScene";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";

const SceneRender = observer(() => {
  const scenes = useScenes();
  return (
    <>
      {scenes.map(([key, SceneInstance]) => {
        const [, Component] = ScenesViewModel.registers[SceneInstance._name];
        return (
          <SceneContext.Provider key={key} value={SceneInstance}>
            <Component key={key} />
          </SceneContext.Provider>
        );
      })}
    </>
  );
});

export default SceneRender;
