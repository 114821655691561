const IconArrowRight = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76343 1.75L4.375 2.8118L7.45558 7L4.375 11.1882L5.76343 12.25L9.625 7L5.76343 1.75Z"
      fill="#303B57"
    />
  </svg>
);

export default IconArrowRight;
