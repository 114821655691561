import {
  INotificationsListFilters,
  INotificationsListViewModel,
  TNotificationsFieldsOrder,
} from "./__types__/INotificationsListViewModel";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
import {
  DateFilerType,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "../DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { DatePeriodFilterViewModel } from "../DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { ISearchFilterViewModel } from "../SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { SearchFilterViewModel } from "../SearchFilterViewModel/SearchFilterViewModel";
import { IFieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/__types__/IFieldOrderFilterViewModel";
import { FieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/FieldOrderFilterViewModel";
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";
import {
  NotificationListItemEntity,
  INotificationListItemEntity,
} from "@entities/NotificationListItemEntity/NotificationListItemEntity";
import _ from "lodash";
import { AxiosRequestClient, Utils } from "@modules/index";
import { NotificationsRepository } from "@repositories/notifications/repository";
import { INotificationsRepository } from "@repositories/notifications/__types__/repository";
import { NotificationsBlackListViewModel } from "@viewModels/NotificationsBlackListViewModel/NotificationsBlackListViewModel";
import {
  IBlacklist,
  INotificationsBlackListViewModel,
} from "@viewModels/NotificationsBlackListViewModel/__types__/INotificationsBlackListViewModel";
import { runInAction } from "mobx";
import { IFuelingsRepository } from "@repositories/fuelings/__types__/repository";
import { FuelingsRepository } from "@repositories/fuelings";
import { UserRoles } from "@constants/roles";
import {
  NotificationType,
  NotificationsTitles,
} from "@constants/notifications";

/**
 * View model for the list of notifications.
 */
export class NotificationsListViewModel implements INotificationsListViewModel {
  /**
   * Statuses instance for managing request status.
   */
  public statuses: Statuses = new Statuses(["fetchItems"]);

  /**
   * Debounced function for fetching items in batches.
   */
  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing),
    400
  );

  /**
   * Private filters for contract, notification, and region.
   */
  public privateFilters: {
    per_page: number;
  } = {
    per_page: 12,
  };

  /**
   * FieldOrderFilterViewModel instance for managing field order filters.
   */
  public FieldOrderFilterViewModel: IFieldOrderFilterViewModel<TNotificationsFieldsOrder>;

  /**
   * DatePeriodFilterViewModel instance for managing date period filters.
   */
  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  /**
   * SearchFilterViewModel instance for managing search filters.
   */
  private SearchFilterViewModel: ISearchFilterViewModel;

  /**
   * Notifications repository for fetching notifications data.
   */
  private repository: INotificationsRepository = new NotificationsRepository(
    new AxiosRequestClient()
  );

  /**
   * Fueling repository for fetching notifications data.
   */
  private fuelingRepository: IFuelingsRepository = new FuelingsRepository(
    new AxiosRequestClient()
  );

  /**
   * Pagination list of items.
   */
  private _pagination: IPagination<INotificationListItemEntity> =
    new Pagination(undefined);

  private _blacklist: INotificationsBlackListViewModel<string> =
    new NotificationsBlackListViewModel({
      storageKey: "notificationsBlacklist",
    });

  /**
   * Constructor for NotificationsListViewModel.
   *
   * @param model - RootTreeModel instance.
   * @param params - Parameters for initializing the view model.
   */
  public constructor(
    private model: IRootTreeModel,
    params: {
      search?: string;
      per_page?: number;
      dateType?:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  ) {
    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params.dateType,
    });

    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: "",
    });

    this.FieldOrderFilterViewModel =
      new FieldOrderFilterViewModel<TNotificationsFieldsOrder>("asc", "dt");

    if (params.per_page) this.privateFilters.per_page = params.per_page;
  }

  /**
   * Getter for the list of filters applied to request.
   *
   * @returns Filters object.
   */
  public get filters(): INotificationsListFilters {
    return {
      date_filter: this.DatePeriodFilterViewModel.state.type,
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,
      direction: this.FieldOrderFilterViewModel.state.direction,

      order: this.FieldOrderFilterViewModel.state.field,
      per_page: this.privateFilters.per_page,
      search: this.SearchFilterViewModel.state.search,
    };
  }

  /**
   * Getter for the pagination meta data.
   *
   * @returns Pagination meta object.
   */
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  /**
   * Getter for the complete metadata.
   *
   * @returns Pagination metadata object.
   */
  public get metadata(): IPagination<INotificationListItemEntity> {
    return this._pagination;
  }

  public get blacklist(): IBlacklist {
    return this._blacklist.list;
  }

  /**
   * Setter for the field order filter.
   *
   * @param field - Field order.
   * @param direction - Sort direction.
   */
  public setFieldOrder = (
    field: TNotificationsFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    this.FieldOrderFilterViewModel.setFieldFilter(field);
    this.FieldOrderFilterViewModel.setDirectionFilter(direction);
  };

  /**
   * Setter for the search filter.
   *
   * @param search - Search string.
   */
  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  /**
   * Fetches items in batches based on the given page number.
   *
   * @param page - Page number.
   * @param refreshing - Flag indicating if the request is refreshing.
   * @returns Promise.
   */
  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }

      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      if (refreshing || page === 1) {
        this._pagination.metadata = {};
      }

      if (this.model.user.user?.roles.includes(UserRoles.EmployeeGSM)) {
        const response = await this.repository.getNotificationsList({
          date_from: this.filters.date_from,
          date_to: this.filters.date_to,
          direction: this.filters.direction,
          limit: this.filters.per_page,
          order: this.filters.order,
          page,
        });

        if (response.meta.current_page === 1) {
          this._pagination.metadata = {
            data: response.data.map(
              (item): INotificationListItemEntity =>
                NotificationListItemEntity.create({
                  ...item,
                  title: NotificationsTitles[item.type](),
                })
            ),
            meta: response.meta,
          };
        } else {
          this._pagination.metadata = {
            data: _.unionBy(
              this._pagination.data,
              response.data.map(
                (item): INotificationListItemEntity =>
                  NotificationListItemEntity.create({
                    ...item,
                    title: NotificationsTitles[item.type](),
                  })
              )
            ),
            meta: response.meta,
          };
        }
      } else if (this.model.user.user?.roles.includes(UserRoles.Kvs)) {
        const response = await this.fuelingRepository.getFuelingCommentsList({
          date_from: this.filters.date_from,
          date_to: this.filters.date_to,
          isNew: true,
          // direction: this.filters.direction,
          // order: this.filters.order,
          page,
          per_page: this.filters.per_page,
        });

        if (response.meta.current_page === 1) {
          this._pagination.metadata = {
            data: response.data.map((item): INotificationListItemEntity => {
              const type =
                item.status === 1
                  ? NotificationType.CommentNew
                  : item.status === 2
                  ? NotificationType.CommentApproved
                  : NotificationType.CommentRejected;

              return NotificationListItemEntity.create({
                dt: item.fuelingRequest?.createdAt || Date.toString(),
                id: item.id,
                message: item.comment,
                // status: item.status,
                title: NotificationsTitles[type](),
                type,
              });
            }),
            meta: response.meta,
          };
        } else {
          this._pagination.metadata = {
            data: _.unionBy(
              this._pagination.data,
              response.data.map((item: any): INotificationListItemEntity => {
                const type =
                  item.status === 1
                    ? NotificationType.CommentNew
                    : item.status === 2
                    ? NotificationType.CommentApproved
                    : NotificationType.CommentRejected;

                return NotificationListItemEntity.create({
                  dt: item.fuelingRequest?.createdAt || Date.toString(),
                  id: item.id,
                  message: item.comment,
                  // status: item.status,
                  title: NotificationsTitles[type](),
                  type,
                });
              })
            ),
            meta: response.meta,
          };
        }
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  public markNotificationAsRead = async (hash: string): Promise<void> => {
    runInAction(() => {
      this._blacklist.add(hash);
    });
    // this.repository.updateNotificationPartly({
    //   // TODO: read notification
    // });
  };

  /**
   * Clears the list of items.
   */
  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  /**
   * Clean up method to be called before destroying the view model.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
    this.repository.beforeDestroy();
  }
}
