import { TDtoPilotListItemEntry } from "./__types__/TDtoPilotListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoPilotListItemParser: TParseDto<TDtoPilotListItemEntry> = (
  data: any
): TDtoPilotListItemEntry => ({
  id:
    typeof data.id === "number"
      ? Number(data.id)
      : Utils.getRandomInt(0, 1000000),
  name: [data.firstName, data.middleName, data.lastName].some(Boolean)
    ? [data.firstName, data.middleName, data.lastName].join(" ")
    : " - ",
});
