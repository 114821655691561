import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";
import { TDtoRecalculationsListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoRecalculationsListItemEntry.types";

export const DtoRecalculationsListItemParser: TParseDto<
  TDtoRecalculationsListItemEntry
> = (data: any): TDtoRecalculationsListItemEntry => ({
  contract: {
    id: Number(data.contract.id),
    number: Number(data.contract.number) || 0,
  },
  id: Number(data.id),
  newPricePerTon: Number(data.newPricePerTon) || 0,
  oldPricePerTon: Number(data.oldPricePerTon) || 0,
  recalculationDate: Utils.isString(data.recalculationDate)
    ? data.recalculationDate
    : "",
  region: {
    id: Number(data.region.id),
    name: Utils.isString(data.region.name) ? data.region.name : "",
  },
});
