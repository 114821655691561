import { IconUser } from "../icons";
import { observer } from "mobx-react";
import "./styles.scss";
import { useNavigate } from "react-router";
import styled from "styled-components";

type Props = {
  username?: string;
  image?: string;
};

const Profile = (props: Props): JSX.Element => {
  const history = useNavigate();
  return (
    <div className="header-user-info" onClick={() => history("/profile")}>
      {props.image ? (
        <img src={props.image} />
      ) : (
        <ProfileBlock>
          <IconUser />
        </ProfileBlock>
      )}
      <p>{props.username}</p>
    </div>
  );
};

export default observer(Profile);

const ProfileBlock = styled.div`
  background: #f0f2f8;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
