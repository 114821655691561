import { IDtoFuelsByRegionDto } from "./__types__/dtoResponse";
import { TDtoFuelsByRegionEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelsByRegionEntry.types";
import { Utils } from "@modules/index";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import { TDtoFuelingTurnoverListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTurnoverListItemEntry.types";
import { TDtoFuelingTurnoverContractListItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTurnoverContractListItemEntry.types";

export class ReportsResponseDto implements IDtoFuelsByRegionDto {
  public static getFuelsByRegionReport = (
    data: unknown
  ): TDtoPaginationEntry<TDtoFuelsByRegionEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.FuelsByRegionReport),
      data,
      () =>
        Utils.dto.Parsers.Pagination(
          data,
          Utils.dto.Parsers.FuelsByRegionReport
        )
    ) as TDtoPaginationEntry<TDtoFuelsByRegionEntry>;

  public static getFuelingTurnover = (
    data: unknown
  ): TDtoPaginationEntry<TDtoFuelingTurnoverListItemEntry> =>
    Utils.dto.transform(
      Utils.dto.Schemes.Pagination(Utils.dto.Schemes.FuelingTurnoverListItem),
      data,
      () =>
        Utils.dto.Parsers.Pagination(
          data,
          Utils.dto.Parsers.FuelingTurnoverListItem
        )
    ) as TDtoPaginationEntry<TDtoFuelingTurnoverListItemEntry>;

  public static getFuelingTurnoverContract = (
    data: unknown
  ): TDtoFuelingTurnoverContractListItemEntry =>
    Utils.dto.transform(
      Utils.dto.Schemes.FuelingTurnoverContractListItem,
      data,
      Utils.dto.Parsers.FuelingTurnoverContractListItem
    );
}
