import { IScreenControllerRecalculations } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { IRecalculationsListItemEntity } from "@entities/RecalculationsListItemEntity";
import { RequestStatus } from "@constants/repositories";
import { TranslationService } from "@services/translate";
import { SceneControllerRecalculationsCreate } from "@scenes/RecalculationsCreateScene";
import { SceneControllerContractsFilters } from "@scenes/FiltersScene";
import {
  IRecalculationsListFilters,
  IRecalculationsListViewModel,
  TRecalculationsFieldsOrder,
} from "@viewModels/RecalculationsListViewModel/__types__/IRecalculationsListViewModel";
import { RecalculationsListViewModel } from "@viewModels/RecalculationsListViewModel/RecalculationsListViewModel";
import { IPagination } from "@viewModels/pagination/types";
import { RecalculationsCreateViewModel } from "@viewModels/RecalculationsCreateViewModel/RecalculationsCreateViewModel";
import {
  IRecalculationsCreateState,
  IRecalculationsCreateViewModel,
} from "@viewModels/RecalculationsCreateViewModel/__types__/IRecalculationsListViewModel";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import { IFiltersViewModel } from "@viewModels/FiltersViewModel/__types__/IFiltersViewModel";
import { FiltersViewModel } from "@viewModels/FiltersViewModel/FiltersViewModel";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";
import {
  DateFilerType,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";

class ScreenControllerRecalculations
  extends UILogicController
  implements IScreenControllerRecalculations
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private RecalculationsListViewModel: IRecalculationsListViewModel;

  private RecalculationsCreateViewModel: IRecalculationsCreateViewModel;

  private FiltersViewModel: IFiltersViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.RecalculationsListViewModel = new RecalculationsListViewModel(model, {
      dateType: DateFilerType.Month,
      search: "",
    });

    this.RecalculationsCreateViewModel = new RecalculationsCreateViewModel(model);

    this.FiltersViewModel = new FiltersViewModel(model, {});
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return (
      this.RecalculationsListViewModel.statuses.getStatus("fetchItems") ===
      RequestStatus.Pending
    );
  }

  public get totalNumberOfRecalculations(): number {
    return this.RecalculationsListViewModel.meta.total;
  }

  public get listOfRecalculationsMetadata(): IPagination<IRecalculationsListItemEntity> {
    return this.RecalculationsListViewModel.metadata;
  }

  public get listOfRecalculationsFilters(): IRecalculationsListFilters {
    return this.RecalculationsListViewModel.filters;
  }

  public get createRecalculationstate(): IRecalculationsCreateState {
    return this.RecalculationsCreateViewModel.state;
  }

  public get filterCount(): number {
    return this.FiltersViewModel.state.filterCount;
  }

  public setFieldOrderBy = (
    field: TRecalculationsFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    try {
      this.RecalculationsListViewModel.setFieldOrder(field, direction);

      this.RecalculationsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setDateFilter = (params: IDatePeriodFilterViewModelParams): void => {
    try {
      this.RecalculationsListViewModel.setDateFilter(params);

      this.RecalculationsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFilterCount(count: number): void {
    try {
      this.FiltersViewModel.setFilterCount(count);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  }

  public fetchListOfRecalculations = (): void => {
    try {
      this.RecalculationsListViewModel.fetchItemsBatch(
        this.RecalculationsListViewModel.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openCreateRecalculationscene = async (): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(SceneControllerRecalculationsCreate, {
        model: this.model,
      });

      // Notify user about success action
      toast.success(TranslationService.t("txt_success_contract_created"));

      await this.RecalculationsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public resetFilters = async (): Promise<void> => {
    try {
      this.RecalculationsListViewModel.setRegion(null);
      this.RecalculationsListViewModel.setRecalculations(null);
      this.RecalculationsListViewModel.setContract(null);

      this.setFilterCount(0);

      await this.RecalculationsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openRecalculationsFiltersScene = async (): Promise<void> => {
    try {
      const result = await ScenesViewModel.openRegisteredScene(
        SceneControllerContractsFilters,
        {
          contract: this.RecalculationsListViewModel.filters.contract,
          fields: {
            contract: true,
            recalculations: true,
            region: true,
          },
          model: this.model,
          recalculations: this.RecalculationsListViewModel.filters.recalculations,
          region: this.RecalculationsListViewModel.filters.region || undefined,
        }
      );

      this.RecalculationsListViewModel.setRegion(result.region);
      this.RecalculationsListViewModel.setRecalculations(result.recalculations);
      this.RecalculationsListViewModel.setContract(result.contract);

      this.setFilterCount(result.filterCount);

      await this.RecalculationsListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.RecalculationsListViewModel.beforeDestroy();
    this.RecalculationsCreateViewModel.beforeDestroy();
    this.FiltersViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerRecalculations };
