import Joi from "joi";

export const DtoFuelingRequestScheme = Joi.object({
  contractor: Joi.string().required(),
  fuelingType: Joi.number().required(),
  number: Joi.string().required(),
  region: Joi.string().required(),
  vehicle: Joi.string().required(),
  volume: Joi.number().required(),
  weight: Joi.number().required(),
});
