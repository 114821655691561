/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

export const ProfileEntity = types
  .model({
    email: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    image: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    middleName: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
  })
  .named("ProfileEntity");

export interface IProfileEntity extends Instance<typeof ProfileEntity> {}
export interface IProfileEntityIn extends SnapshotIn<IProfileEntity> {}
export interface IProfileEntityOut extends SnapshotOut<IProfileEntity> {}
