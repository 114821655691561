import styled from "styled-components";

const IconLoading = (): JSX.Element => (
  <Animate
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_2039_2803)">
      <path
        d="M12 2V4.5C13.9891 4.5 15.8968 5.29018 17.3033 6.6967C18.7098 8.10322 19.5 10.0109 19.5 12H22C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2Z"
        fill="#303B57"
      />
      <path
        d="M3.25 13.25C3.94036 13.25 4.5 12.6904 4.5 12C4.5 11.3096 3.94036 10.75 3.25 10.75C2.55964 10.75 2 11.3096 2 12C2 12.6904 2.55964 13.25 3.25 13.25Z"
        fill="#303B57"
      />
      <path
        d="M5.58169 8.25099C5.92687 7.65313 5.72202 6.88864 5.12416 6.54346C4.52629 6.19829 3.7618 6.40313 3.41663 7.00099C3.07145 7.59886 3.27629 8.36335 3.87416 8.70853C4.47202 9.0537 5.23651 8.84886 5.58169 8.25099Z"
        fill="#303B57"
      />
      <path
        d="M8.24916 5.58459C8.84702 5.23941 9.05187 4.47492 8.70669 3.87706C8.36151 3.27919 7.59702 3.07435 6.99916 3.41953C6.40129 3.7647 6.19645 4.52919 6.54163 5.12706C6.8868 5.72492 7.65129 5.92977 8.24916 5.58459Z"
        fill="#303B57"
      />
      <path
        d="M20.5811 16.9981C20.9263 16.4002 20.7214 15.6357 20.1235 15.2906C19.5257 14.9454 18.7612 15.1502 18.416 15.7481C18.0708 16.346 18.2757 17.1104 18.8735 17.4556C19.4714 17.8008 20.2359 17.596 20.5811 16.9981Z"
        fill="#303B57"
      />
      <path
        d="M16.9985 20.5844C17.5964 20.2393 17.8013 19.4748 17.4561 18.8769C17.1109 18.279 16.3464 18.0742 15.7485 18.4194C15.1507 18.7645 14.9458 19.529 15.291 20.1269C15.6362 20.7248 16.4007 20.9296 16.9985 20.5844Z"
        fill="#303B57"
      />
      <path
        d="M12 22C12.6904 22 13.25 21.4404 13.25 20.75C13.25 20.0596 12.6904 19.5 12 19.5C11.3096 19.5 10.75 20.0596 10.75 20.75C10.75 21.4404 11.3096 22 12 22Z"
        fill="#303B57"
      />
      <path
        d="M8.70638 20.1228C9.05156 19.5249 8.84672 18.7604 8.24885 18.4153C7.65099 18.0701 6.8865 18.2749 6.54132 18.8728C6.19614 19.4707 6.40099 20.2351 6.99885 20.5803C7.59672 20.9255 8.36121 20.7207 8.70638 20.1228Z"
        fill="#303B57"
      />
      <path
        d="M5.12721 17.4562C5.72508 17.1111 5.92992 16.3466 5.58474 15.7487C5.23956 15.1508 4.47508 14.946 3.87721 15.2912C3.27934 15.6363 3.0745 16.4008 3.41968 16.9987C3.76486 17.5966 4.52934 17.8014 5.12721 17.4562Z"
        fill="#303B57"
      />
    </g>
    <defs>
      <clipPath id="clip0_2039_2803">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Animate>
);

export default IconLoading;

const Animate = styled.svg`
  animation: rotate 1s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
