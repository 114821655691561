import {
  IScenesInstance,
  TSceneConstructorParams,
} from "./__types__/ScenesInstance";
import { IScenesViewModel } from "./__types__/ScenesViewModel";
import { IObservableArray, observable } from "mobx";

type SceneComponent = () => JSX.Element;

export class ScenesViewModel implements IScenesViewModel {
  public static registers: {
    [key: string]: [typeof IScenesInstance<any, any>, SceneComponent];
  } = {};

  public static openedScenes: IObservableArray<
    [string, IScenesInstance<any, any>]
  > = observable.array<[string, IScenesInstance<any, any>]>([]);

  public static registerView = (
    UISceneController: typeof IScenesInstance<any, any>,
    Component: SceneComponent
  ): void => {
    ScenesViewModel.registers[UISceneController._name] = [
      UISceneController,
      Component,
    ];
  };

  public static openRegisteredScene = <
    T extends any,
    P extends TSceneConstructorParams
  >(
    UISceneController: typeof IScenesInstance<T, P>,
    params: P
  ): Promise<T> => {
    const _sceneId = params.sceneId ?? this.generateSceneId();
    return new Promise((resolve, reject) => {
      if (!ScenesViewModel.registers[UISceneController._name]) {
        return reject(`${UISceneController._name} is not registered`);
      }
      ScenesViewModel.openedScenes.push([
        _sceneId,
        new UISceneController({ reject, resolve }, params),
      ]);
    })
      .then((result) => result as T)
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.closeRegisteredScene(_sceneId);
      });
  };

  public static closeRegisteredScene = (sceneId: string): void => {
    const index = ScenesViewModel.openedScenes.findIndex(
      ([_sceneId]) => _sceneId === sceneId
    );
    if (~index) {
      ScenesViewModel.openedScenes.splice(index, 1);
    }
  };

  public static generateSceneId = (): string => Date.now().toString();

  public static closeAllRegisteredScene = (): void => {
    ScenesViewModel.openedScenes.length = 0;
  };
}
