import { IScreenControllerReportByVS } from "@screens/ReportByVSScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import SelectComponent from "@shared/controls/Select/Select";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";

export const ReportByVSsAdditionalFilters = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportByVS;
  }>();

  return (
    <ColGap16>
      <label>{TranslationService.t("txt_vehicle")}</label>
      <SelectComponent
        isClearable
        onFocus={UIViewController.fetchListOfVehicles}
        options={UIViewController.listOfVehiclesMetadata.data.map(
          (vehicle, index) => ({
            id: vehicle.id,
            index,
            label: vehicle.number,
            number: vehicle.number,
            value: vehicle.id,
          })
        )}
        onMenuScrollToBottom={() => UIViewController.fetchListOfVehicles()}
        onInputChange={UIViewController.setVehicleSearch}
        isLoading={UIViewController.isVehiclesLoading}
        // value={UIViewController.listOfFuelingFilters.vehicle}
        value={
          UIViewController.listOfFuelingFilters.vehicle
            ? {
                label: UIViewController.listOfFuelingFilters.vehicle.number,
                value: UIViewController.listOfFuelingFilters.vehicle.id,
              }
            : null
        }
        onChange={(vehicle: any) => UIViewController.setVehicle(vehicle)}
      />
    </ColGap16>
  );
});

const ColGap16 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
