type Props = {
  onClick?: () => void;
};

const IconArrowDown = ({ onClick }: Props): JSX.Element => (
  <svg
    onClick={onClick}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.61798L3.21348 5L8 8.58989L12.7865 5L14 6.61798L8 11.118L2 6.61798Z"
      fill="#303B57"
    />
  </svg>
);

export default IconArrowDown;
