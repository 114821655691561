import { UIViewController } from "./connector/UIViewController";
import SceneRender from "./components/SceneRender/SceneRender";
import { ScenesContext } from "./hook";
import React, { useEffect, useRef } from "react";
import { SceneControllerContractCreate } from "@scenes/ContractCreateScene";
import ContractCreateScene from "@scenes/ContractCreateScene/ContractCreateScene";
import { SceneControllerContractsFilters } from "@scenes/FiltersScene";
import ContractsFiltersScene from "@scenes/FiltersScene/FiltersScene";
import { SceneControllerScoreCreate } from "@scenes/ScoreCreateScene";
import ScoreCreateScene from "@scenes/ScoreCreateScene/ScoreCreateScene";
import FuelingRequestScene from "@scenes/FuelingRequestScene/FuelingRequestScene";
import { SceneControllerFuelingRequest } from "@scenes/FuelingRequestScene";
import { SceneControllerFuelingCommentRequest } from "@scenes/FuelingRequestCommentScene";
import FuelingRequestCommentScene from "@scenes/FuelingRequestCommentScene/FuelingRequestCommentScene";
import NotificationsScene from "@scenes/NotificationsScene/NotificationsScene";
import { SceneControllerNotificationsList } from "@scenes/NotificationsScene";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import FuelTransferScene from "@scenes/FuelTransferScene/FuelTransferScene";
import { SceneControllerFuelTransfer } from "@scenes/FuelTransferScene";
import { SceneControllerNotificationsPilotList } from "@scenes/NotificationsPilotScene";
import NotificationsPilotScene from "@scenes/NotificationsPilotScene/NotificationsPilotScene";
import RecalculationsCreateScene from "@scenes/RecalculationsCreateScene/RecalculationsCreateScene";
import { SceneControllerRecalculationsCreate } from "@scenes/RecalculationsCreateScene";

const RootScene = React.memo(
  () => {
    const controller = useRef(new UIViewController()).current;

    useEffect(() => {
      /** TODO: move registration to another file when entities will be too much */
      ScenesViewModel.registerView(
        SceneControllerContractCreate,
        ContractCreateScene
      );

      ScenesViewModel.registerView(
        SceneControllerContractsFilters,
        ContractsFiltersScene
      );

      ScenesViewModel.registerView(
        SceneControllerScoreCreate,
        ScoreCreateScene
      );

      ScenesViewModel.registerView(
        SceneControllerRecalculationsCreate,
        RecalculationsCreateScene
      );

      ScenesViewModel.registerView(
        SceneControllerFuelingRequest,
        FuelingRequestScene
      );

      ScenesViewModel.registerView(
        SceneControllerFuelingCommentRequest,
        FuelingRequestCommentScene
      );

      ScenesViewModel.registerView(
        SceneControllerNotificationsList,
        NotificationsScene
      );

      ScenesViewModel.registerView(
        SceneControllerNotificationsPilotList,
        NotificationsPilotScene
      );

      ScenesViewModel.registerView(
        SceneControllerFuelTransfer,
        FuelTransferScene
      );
    }, []);

    return (
      <ScenesContext.Provider value={controller.scenes}>
        <SceneRender />
      </ScenesContext.Provider>
    );
  },
  () => true
);

export default RootScene;
