/* eslint-disable @typescript-eslint/no-empty-interface */
import { FuelingListItemEntity } from "@entities/FuelingListItemEntity";
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const ContractorModel = types.model("Contractor", {
  id: types.number,
  name: types.string,
});

const RegionModel = types.model("Region", {
  criticalWeight: types.number,
  id: types.number,
  minimalWeight: types.number,
  name: types.string,
});

const TotalsModel = types.model("Totals", {
  total: types.number,
  totalIn: types.number,
  totalOut: types.number,
  transferIn: types.number,
  transferOut: types.number,
});

const DataModel = types.model("Data", {
  conclusionDate: types.string,
  contractor: ContractorModel,
  id: types.number,
  number: types.string,
  regions: types.array(RegionModel),
  requests: types.optional(types.array(FuelingListItemEntity), []),
  termDate: types.string,
  totals: TotalsModel,
});

export const FuelingTurnoverListItemEntity = types
  .model({
    id: types.number,
    items: types.optional(types.array(DataModel), []),
    name: types.string,
    regions: types.array(RegionModel),
  })
  .named("FuelingTurnoverListItemEntity");

export interface IFuelingTurnoverListItemEntity
  extends Instance<typeof FuelingTurnoverListItemEntity> {}
export interface IFuelingTurnoverListItemEntityIn
  extends SnapshotIn<IFuelingTurnoverListItemEntity> {}
export interface IFuelingTurnoverListItemEntityOut
  extends SnapshotOut<IFuelingTurnoverListItemEntity> {}
