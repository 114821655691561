import {
  ProfileEntity,
  IProfileEntity,
} from "@entities/ProfileEntity/ProfileEntity";
import { IUserEntity, UserEntity } from "@entities/UserEntity";
import { AxiosRequestClient } from "@modules/request";
import { Utils } from "@modules/utils";
import { ProfileRepository } from "@repositories/profile/repository";
import axios from "axios";
import { IReactionDisposer, reaction } from "mobx";
import {
  Instance,
  applySnapshot,
  types,
  SnapshotIn,
  SnapshotOut,
} from "mobx-state-tree";

export const UserTreeModel = types
  .model({
    isLogin: types.optional(types.boolean, false),
    profile: types.maybeNull(ProfileEntity),
    user: types.maybeNull(UserEntity),
  })
  .actions((self) => {
    const repository = new ProfileRepository(new AxiosRequestClient());
    let disposer: IReactionDisposer;

    return {
      afterCreate(): void {
        disposer = reaction(
          () => self.isLogin,
          (current, prev) =>
            Utils.compare(current, prev, (): void => {
              if (current) {
                this.syncProfile();
              }
            }),
          { fireImmediately: true }
        );
      },

      /**
       * Method should be called when we need to authorize user from system
       */
      auth: ({ user }: { user: IUserEntity }): void => {
        if (UserEntity.is(user)) {
          applySnapshot(self, {
            ...self,
            isLogin: true,
            user,
          });
        }
      },

      beforeDestroy(): void {
        if (disposer) disposer();
      },

      logout: (): void => {
        localStorage.clear();
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          Authorization: ``,
        };
        applySnapshot(self, {
          ...self,
          isLogin: false,
          profile: null,
          user: null,
        });
      },

      setProfile(profile: IProfileEntity): void {
        applySnapshot(self, {
          ...self,
          profile,
        });
      },

      async syncProfile(): Promise<void> {
        try {
          if (process.env.NODE_ENV === "test") return;
          if (!self.isLogin) return;

          const response = await repository.getProfile();
          this.setProfile(ProfileEntity.create(response));
        } catch (error) {
          console.error(error);
        }
      },
    };
  });

export type IUserTreeModel = Instance<typeof UserTreeModel>;
export type IUserTreeModelIn = SnapshotIn<IUserTreeModel>;
export type IUserTreeModelOut = SnapshotOut<IUserTreeModel>;
