import { IScreenControllerFuelingTurnover } from "./__types__/UIViewController";
import { IReactionDisposer } from "mobx";
import { UILogicController } from "@view/providers/__proto__/withController";
import { IRootTreeModel } from "@models/RootTreeModel";
import { toast } from "react-toastify";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { RequestStatus } from "@constants/repositories";
import { IPagination } from "@viewModels/pagination/types";
import { IFiltersViewModel } from "@viewModels/FiltersViewModel/__types__/IFiltersViewModel";
import { FiltersViewModel } from "@viewModels/FiltersViewModel/FiltersViewModel";
import {
  IFuelingTurnoverListFilters,
  IFuelingTurnoverListViewModel,
  TFuelingTurnoverFieldsOrder,
} from "@viewModels/FuelingTurnoverListViewModel/__types__/IFuelingTurnoverListViewModel";
import { FuelingTurnoverListViewModel } from "@viewModels/FuelingTurnoverListViewModel/FuelingTurnoverListViewModel";
import { IFuelingTurnoverListItemEntity } from "@entities/FuelingTurnoverListItemEntity/FuelingTurnoverListItemEntity";
import { ErrorScene, ScenesErrors } from "@viewModels/scenes/ErrorScene";
import { ScenesViewModel } from "@viewModels/scenes/ScenesViewModel";
import { SceneControllerContractsFilters } from "@scenes/FiltersScene";
import { IFuelingTurnoverContractListViewModel } from "@viewModels/FuelingTurnoverContractListViewModel/__types__/IFuelingTurnoverContractListViewModel";
import { FuelingTurnoverContractListViewModel } from "@viewModels/FuelingTurnoverContractListViewModel/FuelingTurnoverContractListViewModel";
import { SceneControllerFuelTransfer } from "@scenes/FuelTransferScene";
import { TranslationService } from "@services/translate";
import { TDtoFuelingTurnoverContractListSubItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTurnoverContractListItemEntry.types";
import { ContractsListViewModel } from "@viewModels/ContractsListViewModel/ContractsListViewModel";
import {
  IContractsListFilters,
  IContractsListViewModel,
} from "@viewModels/ContractsListViewModel/__types__/IContractsListViewModel";
import {
  DateFilerType,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import moment from "moment";

const roundMath = (number: number): number => Math.round(number * 1000) / 1000;

class ScreenControllerFuelingTurnover
  extends UILogicController
  implements IScreenControllerFuelingTurnover
{
  public disposers: { [key: string]: IReactionDisposer } = {};

  private FuelingTurnoverListViewModel: IFuelingTurnoverListViewModel;

  private FiltersViewModel: IFiltersViewModel;

  private ContractsListViewModel: IContractsListViewModel;

  private FuelingTurnoverContractListViewModel: IFuelingTurnoverContractListViewModel;

  public constructor(model: IRootTreeModel) {
    super(model);

    this.FuelingTurnoverListViewModel = new FuelingTurnoverListViewModel(
      model,
      { dateType: DateFilerType.Month }
    );

    this.ContractsListViewModel = new ContractsListViewModel(model, {
      search: "",
    });

    this.FuelingTurnoverContractListViewModel =
      new FuelingTurnoverContractListViewModel(model, {
        dateType: DateFilerType.Month,
      });

    this.FiltersViewModel = new FiltersViewModel(model, {});
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestInProgress(): boolean {
    return (
      this.FuelingTurnoverListViewModel.statuses.getStatus("fetchItems") ===
      RequestStatus.Pending
    );
  }

  /** Indicator for otp request confirmation or resending otp request */
  public get isRequestContractInProgress(): boolean {
    return (
      this.FuelingTurnoverContractListViewModel.statuses.getStatus(
        "fetchItemsContract"
      ) === RequestStatus.Pending
    );
  }

  public get listOfFuelingTurnoverMetadata(): IPagination<IFuelingTurnoverListItemEntity> {
    return this.FuelingTurnoverListViewModel.metadata;
  }

  public get listOfOpenContractList():
    | {
        id: number;
        regionId: number;
        list: TDtoFuelingTurnoverContractListSubItemEntry[] | [];
      }[]
    | [] {
    return this.FuelingTurnoverContractListViewModel.state.openList;
  }

  public get listOfContractsFilters(): IContractsListFilters {
    return this.ContractsListViewModel.filters;
  }

  public get listOfFuelingTurnoverFilters(): IFuelingTurnoverListFilters {
    return this.FuelingTurnoverListViewModel.filters;
  }

  public get listOfItemsForTable(): any[] {
    let list = this.listOfFuelingTurnoverMetadata.data.map((region) => {
      const regionMinWidthFromId =
        region.regions.find((el: any) => el?.id === region.id)?.minimalWeight ||
        0;
      const regionCriticalWeightFromId =
        region.regions.find((el: any) => el?.id === region.id)
          ?.criticalWeight || 0;
      return {
        expense: -roundMath(
          region.items.reduce(
            (sum, val) => +sum + +val.totals.totalOut + +val.totals.transferOut,
            0
          )
        ),
        id: region.id,
        income: roundMath(
          region.items.reduce(
            (sum, val) => +sum + +val.totals.totalIn + +val.totals.transferIn,
            0
          )
        ),
        option: () => this.openFuelTransferScene(region, undefined),
        region_contractor: region.name,
        regionsList: region.items.map((item) => ({
          contract: [
            item.number,
            moment(item.conclusionDate).format("DD.MM.YY"),
          ],
          expense: -roundMath(+item.totals.totalOut + +item.totals.transferOut),
          id: item.id,
          income: +roundMath(item.totals.totalIn + +item.totals.transferIn),
          option: () => this.openFuelTransferScene(region, item.contractor),
          region_contractor: item.contractor.name,
          remains: roundMath(item.totals.total),
          requests: item.requests.length,
        })),
        remains: roundMath(
          region.items.reduce((sum, val) => +sum + +val.totals.total, 0)
        ),
        status_value:
          region.items.reduce((sum, val) => +sum + +val.totals.total, 0) >=
          regionMinWidthFromId
            ? ["", 0]
            : region.items.reduce((sum, val) => +sum + +val.totals.total, 0) <
              regionCriticalWeightFromId
            ? [regionCriticalWeightFromId, 1]
            : [regionMinWidthFromId, 2],
      };
    });

    list = list.sort((first, second) => {
      const result = // @ts-ignore
        first[this.listOfFuelingTurnoverFilters.order] >
        // @ts-ignore

        second[this.listOfFuelingTurnoverFilters.order]
          ? -1
          : 1;

      if (this.listOfFuelingTurnoverFilters.direction === "asc") return result;

      return result * -1;
    });

    return list;
  }

  public get filterCount(): number {
    return this.FiltersViewModel.state.filterCount;
  }

  public setFilterCount(count: number): void {
    try {
      this.FiltersViewModel.setFilterCount(count);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  }

  // public get fieldsOrder(): {
  //   field: TFuelingTurnoverFieldsOrder;
  //   direction: "asc" | "desc";
  // } {
  //   // @ts-ignore
  //   return {};
  //   // return this.FuelingTurnoverListViewModel.fieldsOrder;
  // }

  // public get listOfOpenContractList(): TOpenContractListItem[] | [] {
  //   return this.FuelingTurnoverContractListViewModel.listOfOpenContractList;
  // }

  public setOpenContractList = (
    data:
      | {
          id: number;
          regionId: number;
          list: TDtoFuelingTurnoverContractListSubItemEntry[] | [];
        }[]
      | []
  ): void => {
    try {
      this.setOpenContractList(data);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFieldOrderBy = (
    field: TFuelingTurnoverFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    try {
      this.FuelingTurnoverListViewModel.setFieldOrder(field, direction);
      this.FuelingTurnoverContractListViewModel.setFieldOrder(field, direction);

      // this.FuelingTurnoverContractListViewModel.clearList();
      // this.FuelingTurnoverListViewModel.fetchItemsBatch(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setDateFilter = (params: IDatePeriodFilterViewModelParams): void => {
    try {
      this.FuelingTurnoverListViewModel.setDateFilter(params);
      this.FuelingTurnoverContractListViewModel.setDateFilter(params);

      this.fetchListOfFuelingTurnover(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public fetchListOfFuelingTurnover = async (
    page: number = 1,
    refreshing: boolean = true
  ): Promise<void> => {
    try {
      this.FuelingTurnoverContractListViewModel.clearList();
      await this.FuelingTurnoverListViewModel.fetchItemsBatch(page, refreshing);
    } catch (error) {
      console.error(error);
    }
  };

  public fetchListOfFuelingTurnoverContract = async (
    id: number,
    regionId: number
  ): Promise<void> => {
    try {
      let newArray:
        | {
            id: number;
            regionId: number;
            list: TDtoFuelingTurnoverContractListSubItemEntry[] | [];
          }[]
        | [] = [...this.FuelingTurnoverContractListViewModel.state.openList];
      if (
        this.FuelingTurnoverContractListViewModel.state.openList.find(
          (el) => el.id === id && el.regionId === regionId
        )
      ) {
        newArray = newArray.filter(
          (el) => el.id !== id && el.regionId !== regionId
        );
      } else {
        await this.FuelingTurnoverContractListViewModel.fetchItemsBatch(
          id,
          regionId,
          1,
          true
        );
        const list: TDtoFuelingTurnoverContractListSubItemEntry[] | [] =
          this.FuelingTurnoverContractListViewModel.state.fetchContractList
            .list;
        newArray[newArray.length] = {
          id,
          list,
          regionId,
        };
      }
      this.FuelingTurnoverContractListViewModel.setOpenContractList(newArray);
      // const response =
      //   await this.FuelingTurnoverContractListViewModel.fetchItemsBatch(id);
    } catch (error) {
      console.error(error);
    }
  };

  public resetFilters = async (): Promise<void> => {
    try {
      this.FuelingTurnoverListViewModel.setRegion(null);
      this.FuelingTurnoverListViewModel.setFueling(null);
      this.FuelingTurnoverListViewModel.setContract(null);

      this.FuelingTurnoverContractListViewModel.setRegion(null);
      this.FuelingTurnoverContractListViewModel.setFueling(null);
      this.FuelingTurnoverContractListViewModel.setContract(null);

      this.setFilterCount(0);

      this.fetchListOfFuelingTurnover(1, true);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public openFuelingTurnoverFiltersScene = async (): Promise<void> => {
    try {
      const result = await ScenesViewModel.openRegisteredScene(
        SceneControllerContractsFilters,
        {
          contract: this.FuelingTurnoverListViewModel.filters.contract,
          fields: {
            contract: true,
            fueling: true,
            // fuelingNumber: true,
            region: true,
          },
          fueling: this.FuelingTurnoverListViewModel.filters.fueling,
          // f:
          //   this.FuelingTurnoverListViewModel.filters.fueling,
          // fuelingType: this.FuelingListViewModel.filters.fuelingType,
          model: this.model,
          region: this.FuelingTurnoverListViewModel.filters.region || undefined,
          // vehicle: this.FuelingListViewModel.filters.vehicle,
        }
      );

      this.FuelingTurnoverListViewModel.setRegion(result.region);
      // this.FuelingTurnoverListViewModel.setFuelingNumber(result.fuelingNumber);
      this.FuelingTurnoverListViewModel.setFueling(result.fueling);
      this.FuelingTurnoverListViewModel.setContract(result.contract);

      this.FuelingTurnoverContractListViewModel.setRegion(result.region);
      // this.FuelingTurnoverContractListViewModel.setFuelingNumber(
      //   result.fuelingNumber
      // );
      this.FuelingTurnoverContractListViewModel.setFueling(result.fueling);
      this.FuelingTurnoverContractListViewModel.setContract(result.contract);

      this.setFilterCount(result.filterCount);

      this.fetchListOfFuelingTurnover(1, true);
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public openFuelTransferScene = async (
    regionFrom: { id: number; name: string },
    contractor: { id: number; name: string } | undefined
  ): Promise<void> => {
    try {
      await ScenesViewModel.openRegisteredScene(SceneControllerFuelTransfer, {
        fields: {
          contractor,
          regionFrom,
        },
        model: this.model,
      });

      toast.success(TranslationService.t("txt_success_fuel_transfer_create"));
    } catch (error) {
      if (error instanceof ErrorScene) {
        if (error.cause === ScenesErrors.UserClosedScene) {
          return;
        }
      }

      toast.error(getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.FuelingTurnoverListViewModel.beforeDestroy();
    /** Cancel all disposers */
    Object.values(this.disposers).map((func) => func.call(undefined));
  };
}

export { ScreenControllerFuelingTurnover };
