import {
  IFuelingsRepository,
  TFuelingDocumentsRequestParams,
  TFuelingListRequestParams,
  TFuelingTotalsRequestParams,
} from "./__types__/repository";
import { FuelingsRepositoryResponseDto } from "./dtoResponse";
import { TDtoFuelingListEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingListEntry.types";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import { Utils } from "@modules/utils";
import {
  IDtoFuelingCommentEntry,
  TDtoFuelingCommentListItemEntry,
} from "@modules/utils/dto/parsers/__types__/TDtoFuelingCommentListItemEntry.types";
import { FuelingCommentStatus, FuelingType } from "@constants/fueling";
import moment from "moment";
import { TDtoFuelingTotalsEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTotalsEntry.types";

export class FuelingsRepository implements IFuelingsRepository {
  public readonly abortControllers = {
    createFueling: new AbortController(),
    createFuelingComment: new AbortController(),
    editFuelingComment: new AbortController(),
    getFuelingComment: new AbortController(),
    getFuelingCommentsList: new AbortController(),
    getFuelingTotals: new AbortController(),
    getFuelingsList: new AbortController(),
    getFuelingsListDocument: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getFuelingsList = async (
    params: TFuelingListRequestParams
  ): Promise<TDtoPaginationEntry<TDtoFuelingListEntry>> => {
    if (this.abortControllers.getFuelingsList) {
      this.abortControllers.getFuelingsList.abort();
    }
    this.abortControllers.getFuelingsList = new AbortController();

    const response: any = await this.requestClient.get(`/fueling-request`, {
      params: {
        asc: params.direction === "asc",
        contract: params.contract,
        contractor: params.contractor,
        createdAt:
          params.date_from && params.date_to
            ? {
                end: moment(params.date_to).format("YYYY-MM-DD"),
                start: moment(params.date_from).format("YYYY-MM-DD"),
              }
            : undefined,

        fuelingType: params.fuelingType,
        limit: params.per_page,
        number: params.number,
        order: params.order,
        page: params.page,
        pilot: params.pilot,
        region: params.region,
        search: params.search,
        vehicle: params.vehicle,
      },
      signal: this.abortControllers.getFuelingsList.signal,
    });

    return FuelingsRepositoryResponseDto.getListOfFuelings(response);
  };

  public getFuelingTotals = async (
    params: TFuelingTotalsRequestParams
  ): Promise<TDtoFuelingTotalsEntry> => {
    if (this.abortControllers.getFuelingTotals) {
      this.abortControllers.getFuelingTotals.abort();
    }
    this.abortControllers.getFuelingTotals = new AbortController();

    const response: any = await this.requestClient.get(
      `/fueling-request/totals`,
      {
        params: {
          asc: params.direction === "asc",
          contract: params.contract,
          contractor: params.contractor,
          createdAt:
            params.date_from && params.date_to
              ? {
                  end: moment(params.date_to).format("YYYY-MM-DD"),
                  start: moment(params.date_from).format("YYYY-MM-DD"),
                }
              : undefined,

          fuelingType: params.fuelingType,
          // limit: params.per_page,
          number: params.number,
          order: params.order,
          // page: params.page,
          pilot: params.pilot,
          region: params.region,
          search: params.search,
          vehicle: params.vehicle,
        },
        signal: this.abortControllers.getFuelingTotals.signal,
      }
    );

    return FuelingsRepositoryResponseDto.getFuelingTotals(response);
  };

  public getFuelingsListDocument = async (
    params: TFuelingDocumentsRequestParams
  ): Promise<Blob> => {
    if (this.abortControllers.getFuelingsListDocument) {
      this.abortControllers.getFuelingsListDocument.abort();
    }
    this.abortControllers.getFuelingsListDocument = new AbortController();

    const response = await this.requestClient.get(
      `/fueling-request/download/${params.format}`,
      {
        params: {
          asc: params.direction === "asc",
          contract: params.contract,
          contractor: params.contractor,
          createdAt:
            params.date_from && params.date_to
              ? {
                  end: moment(params.date_to).format("YYYY-MM-DD"),
                  start: moment(params.date_from).format("YYYY-MM-DD"),
                }
              : undefined,
          // date_from: params.date_from,
          // date_to: params.date_to,
          fuelingType: params.fuelingType,
          // limit: params.per_page,
          number: params.number,
          order: params.order,
          // page: params.page,
          pilot: params.pilot,
          region: params.region,
          search: params.search,
          type: params.type,
          vehicle: params.vehicle,
        },
        responseType: "blob",
        signal: this.abortControllers.getFuelingsListDocument.signal,
      }
    );

    return response as Blob;
  };

  public getFuelingCommentsList = async (params: {
    page: number;
    per_page: number;
    status?: number;
    date_from?: number;
    date_to?: number;
    order?: string;
    direction?: "asc" | "desc";
    isNew?: boolean;
  }): Promise<TDtoPaginationEntry<TDtoFuelingCommentListItemEntry>> => {
    if (this.abortControllers.getFuelingCommentsList) {
      this.abortControllers.getFuelingCommentsList.abort();
    }
    this.abortControllers.getFuelingCommentsList = new AbortController();

    const response: any = await this.requestClient.get(
      `/fueling-request-comment`,
      {
        params: {
          asc: params.direction === "asc",
          isNew: params.isNew,
          // date_from: params.date_from,
          // date_to: params.date_to,
          limit: params.per_page,
          order: params.order,
          page: params.page,
          status: params.status,
        },
        signal: this.abortControllers.getFuelingCommentsList.signal,
      }
    );

    return FuelingsRepositoryResponseDto.getFuelingCommentsList(response);
  };

  public getFuelingComment = async (
    id: number
  ): Promise<IDtoFuelingCommentEntry> => {
    if (this.abortControllers.getFuelingComment) {
      this.abortControllers.getFuelingComment.abort();
    }
    this.abortControllers.getFuelingComment = new AbortController();

    const response: any = await this.requestClient.get(
      `/fueling-request-comment/get/${id}`,
      {
        signal: this.abortControllers.getFuelingComment.signal,
      }
    );

    return FuelingsRepositoryResponseDto.getFuelingComment(response);
  };

  public createFueling = async (data: {
    number: string;
    vehicle: number;
    weight: number;
    volume: number;
    fuelingType: number;
    region: number;
    contractor: number;
    coefficient: number;
  }): Promise<{ id: number }> => {
    if (this.abortControllers.createFueling) {
      this.abortControllers.createFueling.abort();
    }
    this.abortControllers.createFueling = new AbortController();

    const response: { id: number } = await this.requestClient.post(
      `/fueling-request`,
      {
        ...data,
      },
      {
        signal: this.abortControllers.createFueling.signal,
      }
    );

    return response;
  };

  public createFuelingComment = async (data: {
    comment: string;
    fuelingRequest: number;
    file: File | undefined;
  }): Promise<{ id: number }> => {
    if (this.abortControllers.createFuelingComment) {
      this.abortControllers.createFuelingComment.abort();
    }
    this.abortControllers.createFuelingComment = new AbortController();

    const response: { id: number } = await this.requestClient.post(
      `/fueling-request-comment`,
      Utils.objectToFormData(data),
      {
        signal: this.abortControllers.createFuelingComment.signal,
      }
    );

    return response;
  };

  public editFuelingComment = async (
    id: number,
    data: {
      status?: FuelingCommentStatus;
      fuelingRequest?: {
        number?: string;
        weight?: string;
        volume?: string;
        fuelingType?: FuelingType;
        vehicle?: number;
        region?: number;
        contractor?: number;
        coefficient?: number;
      };
    }
  ): Promise<{ id: number }> => {
    if (this.abortControllers.editFuelingComment) {
      this.abortControllers.editFuelingComment.abort();
    }
    this.abortControllers.editFuelingComment = new AbortController();

    const response: { id: number } = await this.requestClient.put(
      `/fueling-request-comment/${id}`,
      data,
      {
        signal: this.abortControllers.editFuelingComment.signal,
      }
    );

    return response;
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
