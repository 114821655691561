import { TDtoAuthUserEntry } from "./__types__/TDtoAuthUserEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoAuthUserParser: TParseDto<TDtoAuthUserEntry> = (
  data: any
): TDtoAuthUserEntry => ({
  email: Utils.isString(data.email) ? data.email : "",
  password: Utils.isString(data.password) ? data.password : "",
});
