import Button from "../Button/Button";
import { IconLoading } from "../icons";
import { useCallback } from "react";
import styled from "styled-components";

type Props = {
  count: number;
  total?: number;
  per_page?: number;
  loading?: boolean;
  onLoad?: () => void;
};

const Pagination = ({
  count,
  total,
  per_page,
  loading,
  onLoad,
}: Props): JSX.Element => {
  const handleShowMore = useCallback(() => {
    if (loading) return;
    if (onLoad) onLoad();
  }, [loading]);

  return (
    <Relative className="row">
      {(count && count < (total || 0)) || loading ? (
        <Button
          text={
            loading ? (
              <IconLoading />
            ) : (
              `Показать ещё ${
                total && total - count < 12 ? total - count : per_page
              }`
            )
          }
          type="button"
          className="secondary"
          size="small"
          onClick={handleShowMore}
          loading={loading}
        />
      ) : null}

      {!loading ? (
        <Absolute>
          Показано: {count} из {total}
        </Absolute>
      ) : null}
    </Relative>
  );
};

export default Pagination;

const Relative = styled.div`
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  min-height: 40px;

  button {
    min-width: 159px;

    span {
      max-height: 24px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    height: auto;
    background: #fff;
    z-index: 2;
    margin-bottom: 0;
  }
`;

const Absolute = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 768px) {
    position: relative;
    justify-content: center;
    margin-bottom: 10px;
  }
`;
