import Joi from "joi";

export const DtoRecalculationsListScheme = Joi.array().items(
  Joi.object({
    contract: {
      contractor: Joi.string().required().allow(""),
      id: Joi.number().required(),
      number: Joi.number().required(),
    },
    id: Joi.number().required(),
    newPricePerTon: Joi.number().required(),
    oldPricePerTon: Joi.number().required(),
    recalculationDate: Joi.string().required(),
    region: {
      id: Joi.number().required(),
      name: Joi.string().required().allow(""),
    },
  })
);
