import { HeaderController } from "./connector/UIViewController";
import { IHeaderController } from "./connector/__types__/UIViewController";
import { IconAlert, IconBurger } from "@shared/controls/icons";
import "./styles.scss";
import Profile from "@shared/controls/Profile/Profile";
import { logo, logoIcon } from "@assets/images";
import { useControllers } from "@view/hooks/useControllers";
import withControllers from "@view/providers/__proto__/withController";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { observer } from "mobx-react";

const Header = observer((): JSX.Element => {
  const windowWidth = useWindowWidth();

  const { UIViewController } = useControllers<{
    UIViewController: IHeaderController;
  }>();

  return (
    <div className="header-wrapper">
      {windowWidth > 768 ? (
        <Profile
          username={
            UIViewController.profileInfo?.lastName &&
            UIViewController.profileInfo?.firstName &&
            UIViewController.profileInfo?.middleName
              ? UIViewController.profileInfo.lastName +
                " " +
                UIViewController.profileInfo.firstName[0] +
                ". " +
                UIViewController.profileInfo.middleName[0] +
                "."
              : UIViewController.profileInfo?.lastName &&
                UIViewController.profileInfo?.firstName &&
                !UIViewController.profileInfo?.middleName
              ? UIViewController.profileInfo.lastName +
                " " +
                UIViewController.profileInfo.firstName[0] +
                "."
              : !UIViewController.profileInfo?.lastName &&
                UIViewController.profileInfo?.firstName &&
                !UIViewController.profileInfo?.middleName
              ? UIViewController.profileInfo?.firstName
              : UIViewController.profileInfo?.lastName &&
                !UIViewController.profileInfo?.firstName &&
                !UIViewController.profileInfo?.middleName
              ? UIViewController.profileInfo?.lastName
              : UIViewController.profileInfo?.email
          }
          image={UIViewController.profileInfo?.image}
        />
      ) : (
        <>
          <IconBurger onClick={UIViewController.toggleSideMenu} />
          <div className="logo">
            <img src={logoIcon} />
            <img src={logo} />
          </div>
        </>
      )}
      <div
        className={`notification ${
          UIViewController.notificationsCount ? "notification--active" : ""
        }`}
      >
        <div onClick={UIViewController.openNotifications}>
          <IconAlert />
        </div>
      </div>
    </div>
  );
});

export default withControllers(HeaderController)(Header);
