const IconInfo = (): JSX.Element => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1429 5.83366C9.86664 5.83366 9.60169 5.94341 9.40634 6.13876C9.21099 6.33411 9.10124 6.59906 9.10124 6.87532V9.47949C9.10124 9.75576 9.21099 10.0207 9.40634 10.2161C9.60169 10.4114 9.86664 10.5212 10.1429 10.5212C10.4192 10.5212 10.6841 10.4114 10.8795 10.2161C11.0748 10.0207 11.1846 9.75576 11.1846 9.47949V6.87532C11.1846 6.59906 11.0748 6.33411 10.8795 6.13876C10.6841 5.94341 10.4192 5.83366 10.1429 5.83366Z"
      fill="#303B57"
    />
    <path
      d="M10.1429 14.167C10.7182 14.167 11.1846 13.7006 11.1846 13.1253C11.1846 12.55 10.7182 12.0837 10.1429 12.0837C9.56761 12.0837 9.10124 12.55 9.10124 13.1253C9.10124 13.7006 9.56761 14.167 10.1429 14.167Z"
      fill="#303B57"
    />
    <path
      d="M10.1429 1.66699C8.49473 1.66699 6.88356 2.15573 5.51315 3.07141C4.14275 3.98709 3.07464 5.28858 2.44391 6.8113C1.81318 8.33401 1.64815 10.0096 1.9697 11.6261C2.29124 13.2426 3.08491 14.7274 4.25035 15.8929C5.41579 17.0583 6.90065 17.852 8.51715 18.1735C10.1337 18.4951 11.8092 18.33 13.3319 17.6993C14.8547 17.0686 16.1561 16.0005 17.0718 14.6301C17.9875 13.2597 18.4762 11.6485 18.4762 10.0003C18.4762 7.79019 17.5983 5.67057 16.0355 4.10777C14.4727 2.54497 12.353 1.66699 10.1429 1.66699ZM10.1429 16.2503C8.90677 16.2503 7.6984 15.8838 6.67059 15.197C5.64279 14.5102 4.84171 13.5341 4.36866 12.3921C3.89561 11.2501 3.77184 9.99339 4.013 8.78101C4.25416 7.56863 4.84941 6.45499 5.72349 5.58091C6.59757 4.70683 7.71121 4.11157 8.92359 3.87042C10.136 3.62926 11.3926 3.75303 12.5347 4.22608C13.6767 4.69913 14.6528 5.5002 15.3396 6.52801C16.0263 7.55582 16.3929 8.76419 16.3929 10.0003C16.3929 11.6579 15.7344 13.2476 14.5623 14.4197C13.3902 15.5918 11.8005 16.2503 10.1429 16.2503Z"
      fill="#303B57"
    />
  </svg>
);

export default IconInfo;
