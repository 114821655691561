import {
  IContractorsListFilters,
  IContractorsListViewModel,
} from "./__types__/IContractorsListViewModel";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
import {
  DateFilerType,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "../DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { DatePeriodFilterViewModel } from "../DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { ISearchFilterViewModel } from "../SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { SearchFilterViewModel } from "../SearchFilterViewModel/SearchFilterViewModel";
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";

import { ContractorsRepository } from "@repositories/contractors";
import { IContractorsRepository } from "@repositories/contractors/__types__/repository";
import _ from "lodash";
import {
  ContractorListItemEntity,
  IContractorListItemEntity,
} from "@entities/ContractorListItemEntity";
import { AxiosRequestClient, Utils } from "@modules/index";

export class ContractorsListViewModel implements IContractorsListViewModel {
  public statuses: Statuses = new Statuses(["fetchItems"]);

  public privateFilters: {
    contract: null | { id: number; number: string };
    contractor: null | { id: number; name: string };
    region: null | { id: number; name: string };
    per_page: number;
  } = {
    contract: null,
    contractor: null,
    per_page: 12,
    region: null,
  };

  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing),
    400
  );

  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  private SearchFilterViewModel: ISearchFilterViewModel;

  private repository: IContractorsRepository = new ContractorsRepository(
    new AxiosRequestClient()
  );

  /** Pagination list of items */
  private _pagination: IPagination<IContractorListItemEntity> = new Pagination(
    undefined
  );

  public constructor(
    private model: IRootTreeModel,
    params?: {
      per_page: number;
      dateType?:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  ) {
    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params?.dateType,
    });

    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: "",
    });

    if (params?.per_page) this.privateFilters.per_page = params?.per_page;
  }

  /**
   * List of filters applied to request. When some of the filters is changed list
   * will be automatically re-fetched with new parameters. Filters should not give ability to
   * be change from external directly so we should keep it private
   */
  public get filters(): IContractorsListFilters {
    return {
      contract: this.privateFilters.contract,
      contractor: this.privateFilters.contractor,
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,
      per_page: this.privateFilters.per_page,
      region: this.privateFilters.region,
      search: this.SearchFilterViewModel.state.search,
    };
  }

  /**
   * Get the meta data of the pagination.
   *
   * @returns The meta data of the pagination.
   */
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  /**
   * Get the pagination data.
   *
   * @returns The pagination data.
   */
  public get metadata(): IPagination<IContractorListItemEntity> {
    return this._pagination;
  }

  /**
   * Get the list of contractors.
   *
   * @returns The list of contractors.
   */
  public get list(): IContractorListItemEntity[] {
    return this._pagination.data;
  }

  /**
   * Set the contract filter.
   *
   * @param {{id: number; number: string;} | null} contract - The contract filter.
   */
  public setContract = async (
    contract: {
      id: number;
      number: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contract = contract;
  };

  /**
   * Set the contractor filter.
   *
   * @param {{id: number; name: string;} | null} contractor - The contractor filter.
   */
  public setContractor = async (
    contractor: {
      id: number;
      name: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contractor = contractor;
  };

  /**
   * Set the region filter.
   *
   * @param {{ id: number; name: string } | null} region - The region filter.
   */
  public setRegion = (region: { id: number; name: string } | null): void => {
    this.privateFilters.region = region;
  };

  /**
   * Set the search filter.
   *
   * @param {string} search - The search filter.
   */
  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  /**
   * Fetch the list of contractors.
   *
   * @param {number} page - The page number to fetch.
   * @param {boolean} refreshing - Indicates if the fetch is a refresh.
   */
  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }
      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      const response = await this.repository.getContractorsList({
        contract: this.filters.contract?.id,
        contractor: this.filters.contractor?.id,
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        page,
        per_page: this.filters.per_page,

        region: this.filters.region?.id,
        search: this.filters.search,
      });

      if (page === 1) {
        this._pagination.metadata = {
          data: response.data.map(
            (item): IContractorListItemEntity =>
              ContractorListItemEntity.create(item)
          ),
          meta: response.meta,
        };
      } else {
        this._pagination.metadata = {
          data: _.unionBy(
            this._pagination.data,
            response.data.map(
              (item): IContractorListItemEntity =>
                ContractorListItemEntity.create(item)
            ),
            (item) => item.id
          ),
          meta: response.meta,
        };
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clear the list of contractors.
   */
  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  /**
   * Clean up any resources before the class instance is destroyed.
   */
  public beforeDestroy(): void {
    this.repository.beforeDestroy();
  }
}
