import { RegionTableOutput } from "./components";
import {
  IconArrowDown,
  IconChat,
  IconComment,
  IconCross,
  IconImage,
  IconInfo,
  IconOption,
  IconSort,
} from "../icons";
import Button from "../Button/Button";
import { Screens } from "@constants/screens";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DropdownButton from "react-bootstrap/DropdownButton";
import Offcanvas from "react-bootstrap/Offcanvas";
import { TranslationService } from "@services/translate";

type Props<T> = {
  headerObj: any;
  list: any[];
  sortBy?: T;
  sortDir?: "asc" | "desc";
  onChangeOrder?: (sortBy: T, sortDir: "asc" | "desc") => void;
  docListFromContractorId?: any[];
  isOpenContractorId?: { id: number; regionId: number }[];
  handleOpenContractor?: any;
  isLoading?: boolean;
  resetFilters?: () => Promise<void>;
};

const TableOutput = observer(
  <T,>({
    headerObj,
    list,
    sortBy,
    sortDir,
    onChangeOrder,
    docListFromContractorId,
    isOpenContractorId,
    handleOpenContractor,
    isLoading,
    resetFilters,
  }: Props<T>): JSX.Element => {
    const [listWidth, setListWidth] = useState<number[]>(
      Array(Object.keys(headerObj).length).fill(100)
    );
    const [isOpenRegionId, setIsOpenRegionId] = useState<number[]>([]);
    const [isOpenOption, setIsOpenOption] = useState<number | null>(null);

    const windowWidth = useWindowWidth();

    const headerRef = useRef(new Array(Object.keys(headerObj).length));

    const handleOpenRegion = useCallback(
      (id: number) => {
        let newArr: number[] = [];
        if (isOpenRegionId.includes(id))
          newArr = isOpenRegionId.filter((el) => el !== id);
        else {
          newArr = [...isOpenRegionId];
          newArr.push(id);
        }
        setIsOpenRegionId(newArr);
      },
      [isOpenRegionId]
    );

    const onHeaderPress = useCallback(
      (header: T) => {
        let direction: "desc" | "asc";
        if (sortBy === header) {
          direction = sortDir === "asc" ? "desc" : "asc";
        } else {
          direction = "asc";
        }

        if (onChangeOrder) onChangeOrder(header as T, direction);
      },
      [sortBy, sortDir]
    );

    const handleFuelTransferRegion = useCallback((func: any) => {
      if (func) {
        func();
        setIsOpenOption(null);
      } else undefined;
    }, []);

    const history = useNavigate();

    useEffect(() => {
      const startArray: any[] = [];
      if (headerRef.current && windowWidth > 1024)
        headerRef.current.forEach((el) => {
          startArray.push(el?.clientWidth);
        });
      setListWidth(startArray);
    }, [headerRef, windowWidth, list]);

    if (list.length)
      return (
        <Col className="table table-print">
          {windowWidth > 1024 ? (
            <HeaderRow>
              {Object.keys(headerObj)?.map((elem: string, ind: number) => {
                const headerElRef = (element: any) =>
                  (headerRef.current[ind] = element);
                return (
                  <h5
                    key={elem + ind + "header"}
                    ref={headerElRef}
                    onClick={() => {
                      if (headerObj[elem][1]) onHeaderPress(elem as T);
                    }}
                    className={
                      sortBy === elem && sortDir === "asc"
                        ? "down"
                        : sortBy === elem && sortDir === "desc"
                        ? "up"
                        : ""
                    }
                    style={{
                      maxWidth:
                        elem === "remains" ||
                        elem === "expense" ||
                        elem === "income"
                          ? "110px"
                          : elem === "option"
                          ? "24px"
                          : elem === "price" ||
                            elem === "volume" ||
                            elem === "weight" ||
                            elem === "deliveryDate" ||
                            elem === "conclusionDate" ||
                            elem === "termDate" ||
                            elem === "createdAt"
                          ? "fit-content"
                          : "auto",
                      minWidth:
                        elem === "region"
                          ? "85px"
                          : elem === "region_contractor"
                          ? "213px"
                          : "auto",
                    }}
                  >
                    <span>{headerObj[elem][0]}</span>
                    {headerObj[elem][1] ? <IconSort /> : null}
                    {elem === "comment" ? <CommentHeader /> : null}
                  </h5>
                );
              })}
            </HeaderRow>
          ) : null}
          {list?.map((row: any, indRow: number) => (
            <>
              {windowWidth > 1024 ? (
                <Row
                  key={row + indRow}
                  className={isOpenRegionId.includes(row.id) ? "isOpen" : ""}
                >
                  {Object.keys(headerObj)?.map((elem, ind) => {
                    if (elem === "region_contractor")
                      return (
                        <Arrow
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                          className="arrow"
                        >
                          <IconArrowDown
                            onClick={() => handleOpenRegion(row.id)}
                          />
                          {row[elem]}
                        </Arrow>
                      );
                    else if (elem === "status")
                      return (
                        <p
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                          className={"status" + row[elem][1]}
                        >
                          {row[elem][1] === 1 && row[elem][0]
                            ? "Отправлена"
                            : row[elem][1] === 1 && !row[elem][0]
                            ? "Новая"
                            : null}
                          {row[elem][1] === 2 ? "Исправлена" : null}
                          {row[elem][1] === 3 ? "Отклонена" : null}
                        </p>
                      );
                    else if (elem === "file")
                      return (
                        <p
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                        >
                          {row[elem]?.mime ? (
                            <ImageType className="row">
                              {row[elem].mime.split("/")[1]}
                              <IconImage />
                            </ImageType>
                          ) : (
                            "Отсутствует"
                          )}
                        </p>
                      );
                    else if (elem === "document")
                      if (row?.status)
                        return (
                          <Link
                            to={Screens.ApplicationComment.path(row?.id)}
                            className="document-link"
                            key={elem + ind}
                            style={{
                              width:
                                windowWidth > 1024 ? listWidth[ind] : "auto ",
                            }}
                            title={row[elem][0] + " от " + row[elem][1]}
                          >
                            {row[elem][0]} от {row[elem][1]}{" "}
                          </Link>
                        );
                      else
                        return (
                          <p
                            key={elem + ind}
                            style={{
                              width:
                                windowWidth > 1024 ? listWidth[ind] : "auto ",
                            }}
                            title={row[elem][0] + " от " + row[elem][1]}
                          >
                            {row[elem][0]} от {row[elem][1]}
                          </p>
                        );
                    else if (elem === "option")
                      return (
                        <DropCol
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                          className={
                            isOpenRegionId?.includes(row.id) ? "isOpen" : ""
                          }
                        >
                          <DropdownButton
                            id="dropdown-basic"
                            align={{ lg: "end" }}
                            title={<IconOption />}
                          >
                            <div
                              className="dropdown-item"
                              onClick={() =>
                                handleFuelTransferRegion(row[elem])
                              }
                            >
                              Перенести
                            </div>
                            <Link to="/reportregion" className="dropdown-item">
                              Сформировать отчёт
                            </Link>
                          </DropdownButton>
                        </DropCol>
                      );
                    else if (
                      isOpenRegionId.includes(row.id) &&
                      (elem === "expense" ||
                        elem === "income" ||
                        elem === "status_value")
                    )
                      return (
                        <p
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                        ></p>
                      );
                    else if (elem === "income" && row[elem] > 0)
                      return (
                        <p
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                          className="status2"
                          title={"+" + row[elem]}
                        >
                          +{row[elem]}
                        </p>
                      );
                    else if (elem === "status_value")
                      if (row[elem][1])
                        return (
                          <p
                            key={elem + ind}
                            style={{
                              width:
                                windowWidth > 1024 ? listWidth[ind] : "auto ",
                            }}
                            className={row[elem][1] === 1 ? "status3" : ""}
                          >
                            &#60;
                            {row[elem][0]} кг
                            <IconInfo />
                          </p>
                        );
                      else
                        return (
                          <p
                            key={elem + ind}
                            style={{
                              width:
                                windowWidth > 1024 ? listWidth[ind] : "auto ",
                            }}
                          ></p>
                        );
                    else
                      return (
                        <p
                          key={elem + ind}
                          style={{
                            width:
                              windowWidth > 1024 ? listWidth[ind] : "auto ",
                          }}
                          className={
                            elem === "expense"
                              ? "status3"
                              : elem === "income"
                              ? "status2"
                              : ""
                          }
                          title={row[elem]}
                        >
                          {elem === "term" && "до "} {row[elem]}
                          {elem === "comment" && (
                            <Comment
                              onClick={
                                row[elem] ? () => row[elem]() : undefined
                              }
                            >
                              <IconComment />
                            </Comment>
                          )}
                        </p>
                      );
                  })}
                </Row>
              ) : (
                <Row
                  key={row + indRow}
                  className={isOpenRegionId.includes(row.id) ? "isOpen" : ""}
                  onClick={() =>
                    row?.status &&
                    row?.comment_text &&
                    row?.id &&
                    windowWidth <= 1024
                      ? history(Screens.ApplicationComment.path(row?.id))
                      : null
                  }
                >
                  <div className="col gap6 justify-between">
                    {/* dashboard */}
                    {row?.region_contractor ? (
                      <>
                        <Value className="main">{row?.region_contractor}</Value>
                        {isOpenRegionId.includes(row.id) ? null : (
                          <>
                            <SubTitle>Расход</SubTitle>
                            {row?.expense ? (
                              <Value className="important status3">
                                {row?.expense} кг
                              </Value>
                            ) : null}
                            <SubTitle>Приход</SubTitle>
                            {row?.income ? (
                              <Value className="important status2">
                                +{row.income} кг
                              </Value>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : row?.comment_text ? (
                      <div className="col align-start">
                        <div className="row gap8">
                          <Value className="fs-14 fw-600">№ {row?.id}</Value>
                          <Value className="fs-14">{row?.createdAt}</Value>
                        </div>

                        <Value className="fs-14">{row?.comment_text}</Value>
                        {row?.file?.mime ? (
                          <ImageType className="row">
                            {row.file.mime.split("/")[1]}
                            <IconImage />
                          </ImageType>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        {row?.contract || row?.document ? (
                          <div className="col">
                            <SubTitle>Договор</SubTitle>
                            {(row?.contract || row?.document) &&
                            !row.comment ? (
                              <Value className="important">
                                {row?.contract || row?.document}
                              </Value>
                            ) : (
                              <Value>
                                {row?.document[0]} от {row?.document[1]}
                              </Value>
                            )}
                          </div>
                        ) : null}
                        {row?.vehicle && row?.reportRegion ? (
                          <Value>{row.vehicle}</Value>
                        ) : null}
                        {row?.pilot && row?.reportContract ? (
                          <Value>{row.pilot}</Value>
                        ) : null}
                        {row?.requirement || row?.comment ? (
                          <div className="col">
                            <SubTitle>Топ. требование</SubTitle>
                            <Value>{row?.requirement || row?.number}</Value>
                          </div>
                        ) : null}
                        <div className="col">
                          {row?.weight ? (
                            <Value className={row?.score ? "" : "important"}>
                              {row.weight} кг
                            </Value>
                          ) : null}
                          {row?.price ? (
                            <Value>{row.price} &#8381;</Value>
                          ) : null}
                        </div>
                        {(row?.supplier || row?.contractor) &&
                        (row?.region || row?.regionMobile) &&
                        !row?.deliveryDate ? (
                          <div className="col">
                            <Value>
                              {row?.supplier}
                              {row?.contractor}, {row?.region}
                              {row?.regionMobile}
                            </Value>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className="col gap6 justify-between align-end">
                    {row?.comment ? (
                      <Option
                        className="row align-center justify-center"
                        onClick={row?.comment ? () => row.comment() : undefined}
                      >
                        <IconChat />
                      </Option>
                    ) : null}
                    {row?.comment_text ? (
                      <Value
                        className={`status${row.status[1]}`}
                        style={{
                          justifyContent: "flex-end",
                          minWidth: "100px",
                        }}
                      >
                        {row?.status[1] === 1 && row?.status[0]
                          ? "Отправлена"
                          : row?.status[1] === 1 && !row?.status[0]
                          ? "Новая"
                          : null}
                        {row?.status[1] === 2 ? "Исправлена" : null}
                        {row?.status[1] === 3 ? "Отклонена" : null}
                      </Value>
                    ) : null}
                    <div className="col align-end">
                      {row?.score ? (
                        <div className="col align-end">
                          <SubTitle>Счет</SubTitle>
                          <Value className="important">{row.score}</Value>
                        </div>
                      ) : null}
                      {((row?.reportRegion && row?.pilot) ||
                        (row?.pilot && row?.requirement)) &&
                      !row?.reportKVS ? (
                        <Value>{row.pilot}</Value>
                      ) : null}
                      {(row?.vehicle && row?.requirement && !row?.report) ||
                      row?.reportContract ? (
                        <Value>{row.vehicle}</Value>
                      ) : null}
                      {row?.conclusionDate && row?.termDate ? (
                        <div className="col align-end">
                          <SubTitle>Дата заключения</SubTitle>
                          <Value>{row?.conclusionDate}</Value>
                        </div>
                      ) : null}
                      {row?.reportKVS ? <Value>{row?.vehicle}</Value> : null}
                    </div>
                    {row?.deliveryDate ? (
                      <Value>{row.deliveryDate}</Value>
                    ) : null}
                    <div className="col align-end gap6">
                      {row?.volume ? (
                        <Value className="important">{row.volume} л</Value>
                      ) : null}
                      {row?.createdAt && !row?.comment_text ? (
                        <Value>{row.createdAt}</Value>
                      ) : null}
                      {row?.conclusionDate && row?.termDate ? (
                        <div className="col align-end">
                          <SubTitle>Срок договора</SubTitle>
                          <Value>до {row?.termDate}</Value>
                        </div>
                      ) : null}
                      {row?.deliveryDate && row?.contractor && !row?.score ? (
                        <Value>{row.contractor}</Value>
                      ) : null}
                      {row?.score ? <Value>{row?.region}</Value> : null}
                    </div>
                    {/* dashboard */}
                    {row?.region_contractor ? (
                      <>
                        <Option
                          className={`row align-center justify-center ${
                            isOpenRegionId?.includes(row.id) ? "isOpen" : ""
                          }`}
                          onClick={() =>
                            setIsOpenOption(row?.id + row?.remains)
                          }
                        >
                          <IconOption />
                        </Option>
                        {isOpenOption === row?.id + row?.remains ? (
                          <Offcanvas
                            show={isOpenOption}
                            onHide={() => setIsOpenOption(null)}
                            placement={"bottom"}
                            name={"bottom"}
                          >
                            <Offcanvas.Header>
                              <Offcanvas.Title>Дополнительно</Offcanvas.Title>
                              <IconCross
                                onClick={() => setIsOpenOption(null)}
                              />
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div className="col gap10">
                                <Button
                                  text={"Перенести"}
                                  type={"button"}
                                  size="small"
                                  className="secondary"
                                  onClick={() =>
                                    handleFuelTransferRegion(row.option)
                                  }
                                />
                                <Link
                                  to="/reportregion"
                                  className="dropdown-item secondary"
                                >
                                  Сформировать отчёт
                                </Link>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        ) : null}
                        <div className="col align-end">
                          {row?.status_value[1] &&
                          !isOpenRegionId.includes(row.id) ? (
                            <Value
                              className={
                                row.status_value[1] === 1 ? "status3" : ""
                              }
                            >
                              &#60;
                              {row.status_value[0]} кг <IconInfo />
                            </Value>
                          ) : null}
                          <SubTitle>Остаток</SubTitle>
                          {row?.remains ? (
                            <Value>{row?.remains} кг</Value>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                  {row?.region_contractor ? (
                    <ArrowRow
                      className={`row align-center justify-center arrow`}
                      onClick={() => handleOpenRegion(row.id)}
                    >
                      <IconArrowDown />
                    </ArrowRow>
                  ) : null}
                </Row>
              )}
              {row?.regionsList && (
                <>
                  {isOpenRegionId.includes(row.id) ? (
                    <>
                      {row?.regionsList?.map(
                        (region: any, regionInd: number) => (
                          <RegionTableOutput
                            key={region + regionInd}
                            region={region}
                            headerObj={headerObj}
                            isOpenContractorId={isOpenContractorId}
                            listWidth={listWidth}
                            handleOpenContractor={handleOpenContractor}
                            rowId={row.id}
                            handleFuelTransferRegion={handleFuelTransferRegion}
                            docListFromContractorId={docListFromContractorId}
                          />
                        )
                      )}
                    </>
                  ) : null}
                </>
              )}
            </>
          ))}
        </Col>
      );
    else if (!isLoading)
      return (
        <ResetFilters className="col align-center gap8 justify-end">
          <p>{TranslationService.t("txt_reset_filters_msg")}</p>
          <Button
            text={TranslationService.t("txt_reset_filters_btn")}
            type={"button"}
            size="small"
            onClick={resetFilters}
          />
        </ResetFilters>
      );
    else return <></>;
  }
);

export default TableOutput;

const ResetFilters = styled.div`
  min-height: 250px;
  p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  @media (max-width: 768px) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 10px;

  h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: fit-content;
    flex-grow: 1;

    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;

      path {
        fill: #c5c7ce;
      }
    }

    &.up svg path:first-child {
      fill: #ef5d58;
    }

    &.down svg path:last-child {
      fill: #ef5d58;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #dbdce1;
  transition: all 0.2s ease-in-out;

  p,
  .document-link {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    // flex-direction: column;
    overflow: hidden;
    gap: 10px;
    word-break: break-all;
    word-wrap: break-word;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1024px) {
      width: auto;
    }
  }

  .document-link {
    text-decoration: underline !important;
    cursor: pointer;
  }

  @media (min-width: 1024.1px) {
    .region_contractor {
      padding-left: 60px !important;
    }
  }

  .status1 {
    color: #ff9900 !important;
  }

  .status2 {
    color: #417d24 !important;
  }

  .status3 {
    color: #ef5d58;
    svg path {
      fill: #ef5d58;
    }
  }

  &.isOpen {
    background: linear-gradient(0deg, #f0f2f8, #f0f2f8);

    .arrow svg,
    .arrow-region svg {
      background: none;
      transform: rotate(180deg);
    }
  }

  @media (max-width: 1024px) {
    align-items: stretch;
    position: relative;
  }
`;

const CommentHeader = styled.div`
  content: "";
  width: 30px;
`;

const Comment = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f0f2f8;
  width: 24px;
  height: 24px;
  align-self: flex-end;
`;

export const Arrow = styled.p`
  flex-direction: row !important;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  svg {
    background: #f0f2f8;
    padding: 6px;
    border-radius: 50%;
    width: 24px !important;
    height: 24px !important;
  }
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 24px !important;

  &.arrow-region {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
  transition: all 0.2s ease-in-out;
`;

export const DropCol = styled.div`
  overflow: visible !important;

  &.isOpen button {
    background: #fcfcfd;
  }
`;

const ImageType = styled.span`
  gap: 10px;
  align-items: center;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    gap: 4px;
    background: #f0f2f8;
    padding: 2px 6px;
    border-radius: 4px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const SubTitle = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
`;

export const Value = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  &.important {
    color: #ef5d58;
    font-size: 16px;
    font-weight: 500;
  }

  &.main {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Option = styled.div`
  padding: 0;
  background: #f0f2f8;
  width: 24px;
  height: 24px;
  border-radius: 50% !important;
  &.isOpen {
    background: #fcfcfd;
  }
`;

export const ArrowRow = styled.div`
  padding: 2px 10px;

  border: 1px solid #dbdce1;
  border-left: none;
  border-right: none;

  position: absolute;
  width: 100%;
  left: 0;
  bottom: -16px;
  background: #f8f8fb;
  z-index: 1;

  svg {
    width: 10px;
    height: 10px;
    transition: all 0.3s ease-in;
  }

  &.isOpen svg {
    transform: rotate(180deg);
  }
`;
