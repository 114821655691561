import Joi from "joi";
import { IPaginationInfo } from "@viewModels/pagination/types";

export const JoiPaginationScheme = <T extends any>(
  schemeItem: Joi.Schema<T>
): Joi.Schema<IPaginationInfo<T>> =>
  Joi.object({
    data: Joi.array().items(schemeItem).required(),
    meta: Joi.object({
      current_page: Joi.number().required(),
      last_page: Joi.number().required(),
      per_page: Joi.number().required(),
      total: Joi.number().required(),
    }).required(),
  });
