import { IRootTreeModel, RootTreeModel } from "@models/RootTreeModel";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
} from "react";

export const modelTree = RootTreeModel.create(undefined, {});

const RootStoreContext = createContext<null | IRootTreeModel>(null);

export const ModelTreeProvider = RootStoreContext.Provider;

/**
 *
 */
export const useRootTreeModel = (): IRootTreeModel => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
};

export const RootTreeModelProvider = React.memo(
  (props: PropsWithChildren<{}>) => {
    const context = useRef(modelTree).current;

    return (
      <ModelTreeProvider value={context}>{props.children}</ModelTreeProvider>
    );
  },
  () => true
);
