import { IScreenControllerDocContract } from "@screens/DocContractsScreen/connector/__types__/UIViewController";
import Pagination from "@shared/controls/Pagination/Pagination";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import React from "react";

export const ContractsPagination = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerDocContract;
  }>();

  return (
    <Pagination
      count={UIViewController.listOfContractsMetadata.data.length}
      total={UIViewController.listOfContractsMetadata.meta.total}
      per_page={UIViewController.listOfContractsMetadata.meta.per_page}
      loading={UIViewController.isRequestInProgress}
      onLoad={UIViewController.fetchListOfContracts}
    />
  );
});
