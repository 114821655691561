import { IScreenControllerReportByKVS } from "@screens/ReportByKVSScreen/connector/__types__/UIViewController";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";

export const ReportsByKVSTotals = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportByKVS;
  }>();

  return (
    <RowGap className="row">
      Общий расход топлива — {UIViewController.listOfFuelingTotals.totalWeight}{" "}
      кг, {UIViewController.listOfFuelingTotals.totalVolume} л
    </RowGap>
  );
});

const RowGap = styled.div`
  gap: 14px;
`;
