import {
  IUISceneController,
  TFuelingRequestSceneParams,
  TFuelingRequestSceneResult,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import {
  IFuelingRequestCommentState,
  IFuelingRequestCommentViewModel,
} from "@viewModels/FuelingRequestCommentViewModel/__types__/IFuelingRequestCommentViewModel";
import { FuelingRequestCommentViewModel } from "@viewModels/FuelingRequestCommentViewModel/FuelingRequestCommentViewModel";

export class UISceneController<
    T extends TFuelingRequestSceneResult,
    P extends TFuelingRequestSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "FuelingRequestCommentScene";

  private FuelingRequestCommentViewModel: IFuelingRequestCommentViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TFuelingRequestSceneParams
  ) {
    super(options);

    this.FuelingRequestCommentViewModel = new FuelingRequestCommentViewModel(
      params.model,
      { fuelingRequest: params.fuelingRequest }
    );
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get state(): IFuelingRequestCommentState {
    return this.FuelingRequestCommentViewModel.state;
  }

  public get isRequestInProgress(): boolean {
    return this.FuelingRequestCommentViewModel.statuses.isInProgress;
  }

  public setComment = (comment: string): void => {
    try {
      this.FuelingRequestCommentViewModel.setComment(comment);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFile = (file: File | undefined): void => {
    try {
      this.FuelingRequestCommentViewModel.setFile(file);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public setFuelingRequest = (fuelingRequest: number): void => {
    try {
      this.FuelingRequestCommentViewModel.setFuelingRequest(fuelingRequest);
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public createFuelingComment = async (): Promise<void> => {
    try {
      // Create contract
      await this.FuelingRequestCommentViewModel.createFuelingComment(
        this.FuelingRequestCommentViewModel.state
      );

      // @ts-ignore
      this.displays.handleResolve({});
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public clearFueling = (): void => {
    try {
      this.FuelingRequestCommentViewModel.clearState();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {
    this.clearFueling();
  };
}
