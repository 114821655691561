type ErrorSceneOptions = ErrorOptions | { cause: ScenesErrors };

export enum ScenesErrors {
  UserClosedScene,
  SceneClosedByCallback,
}

export class ErrorScene extends Error {
  public constructor(message: string, options: ErrorSceneOptions) {
    super(message, options);
  }
}
