import { IGetMessageFromError } from "./types";
import { TranslationService } from "../../../services/translate";
import { TxKeyPath } from "../../../services/translate/__types__";

export const getMessageFromError: IGetMessageFromError = (
  error: any,
  defaultMessage?: TxKeyPath
): string => {
  if (!error)
    return TranslationService.t(defaultMessage || "txt_unknown_error");

  if (error.isAxiosError && error.response?.data) {
    if (error.response.data.errors) {
      return Object.values(error.response.data.errors).reduce(
        (acc: string, item: any) => (acc += ` \n ${item?.[0]}`),
        ""
      );
    }

    if (error.response.data.message) {
      return error.response.data.message;
    }
  }
  if (error.message) {
    return error.message;
  }
  return TranslationService.t(defaultMessage || "txt_request_error");
};
