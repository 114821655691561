// import { TranslationService } from "@services/translate";
import NotificationItem from "./NotificationItem/NotificationItem";
import {
  IUISceneController,
  TNotificationsSceneParams,
  TNotificationsSceneResult,
} from "../connector/UISceneController/types";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import { TranslationService } from "@services/translate";
import FilterComponent from "@shared/helpers/SideForm/SideForm";
import Button from "@shared/controls/Button/Button";
import "./styles.scss";

const NotificationsList = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TNotificationsSceneResult, TNotificationsSceneParams>
    >();

  // Fetch initial data to the form fields
  useEffect(() => {
    UISceneController.fetchListOfNotifications();
  }, []);

  return (
    <FilterComponent
      title={TranslationService.t("txt_notifications")}
      onClose={UISceneController.closeScene}
    >
      <div className="custom-form notifications">
        <div className="custom-field notifications__list">
          {UISceneController.isNotificationsLoading ? (
            <Button text={""} type="button" className="" loading />
          ) : null}
          {UISceneController.listOfNotificationMetadata.data.map(
            (notification, index) => (
              <NotificationItem key={index} notification={notification} />
            )
          )}
        </div>
      </div>
    </FilterComponent>
  );
});

export default NotificationsList;
