import { TDtoPaginationEntry } from "./__types__/TDtoPaginationEntry";
import { TParseDto } from "../__types__/dto";
import { isArray, isNumber } from "lodash";

export const DtoPaginationParser = <
  P extends (...args: any) => any = TParseDto
>(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  itemParser: P
): TDtoPaginationEntry<ReturnType<P>> => ({
  data:
    (isArray(data.data) && data.data.map(itemParser)) ||
    (isArray(data.list) && data.list.map(itemParser)) ||
    [],
  meta: {
    current_page: isNumber(data.pager?.current) ? data.pager.current : 0,
    last_page: isNumber(data.pager?.pages) ? data.pager.pages : 1,
    per_page: isNumber(data.pager?.limit) ? data.pager.limit : 10,
    total: isNumber(data.pager?.total) ? data.pager.total : 0,
  },
});
