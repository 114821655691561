import { ISearchFilterViewModel } from "./__types__/ISearchFilterViewModel";
import { Utils } from "@modules/utils";
import { observable } from "mobx";

/**
 * Represents the parameters for the field order filter view model.
 */
export type IFieldOrderFilterViewModelParams = {
  keys: string[];
};

/**
 * Represents the parameters for the search filter view model.
 */
export type TSearchFilterViewModelParams = {
  search: string;
  debounce: number;
};

/**
 * Represents a search filter view model.
 */
export class SearchFilterViewModel implements ISearchFilterViewModel {
  /**
   * The state of the search filter view model.
   */
  public state: {
    search: string;
  };

  /**
   * Sets the search filter with a debounce.
   *
   * @param value - The search filter value.
   */
  public setSearchFilterDebounce = Utils.debounce(
    (value) => this.setSearchFilter(value),
    700
  );

  /**
   * Constructs a new search filter view model.
   *
   * @param params - The parameters for the search filter view model.
   */
  public constructor(private params: TSearchFilterViewModelParams) {
    this.state = observable.object({
      search: params.search,
    });
  }

  /**
   * Sets the search filter.
   *
   * @param search - The search filter value.
   */
  public setSearchFilter = (search: string): void => {
    this.state.search = search;
  };

  /**
   * Executes any necessary cleanup before the view model is destroyed.
   */
  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
  }
}
