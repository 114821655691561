import Joi from "joi";

export const DtoScoreListItemScheme = Joi.object({
  contractor: {
    id: Joi.number().required(),
    name: Joi.string().required(),
  },
  deliveryDate: Joi.string().required(),
  id: Joi.number().required(),
  number: Joi.string().required(),
  numberScore: Joi.string().required(),
  price: Joi.string().required(),
  regions: Joi.array().items({
    // contractors: Joi.array().items(Joi.string().allow("")),
    id: Joi.number().required(),
    name: Joi.string().required().allow(""),
  }),
  weight: Joi.string().required(),
});
