import "./App.scss";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { RootProvider } from "@view/providers/RootProvider/RootProvider";
import RootRouter from "@routers/RootRouter";
import { ToastContainer } from "react-toastify";
import RootScene from "@view/providers/RootScene/RootScene";

const App = (): JSX.Element => (
  <RootProvider>
    <ToastContainer />
    <RootRouter />
    <RootScene />
  </RootProvider>
);

export default App;
