import { SceneDisplay } from "./displays";
import {
  ISceneDisplay,
  TSceneDisplayOptions,
} from "../../../domain/viewModels/scenes/__types__/SceneDisplay";
import {
  IScenesInstance,
  TSceneConstructorParams,
} from "@viewModels/scenes/__types__/ScenesInstance";

export class ScenesInstance<T, P extends TSceneConstructorParams>
  implements IScenesInstance<T, P>
{
  public static _name: string = "Prototype";

  public displays: ISceneDisplay<T>;

  public constructor(options: TSceneDisplayOptions) {
    this.displays = new SceneDisplay(options);
  }

  public get _name(): string {
    return ScenesInstance._name;
  }

  /** Please overwrite this getter to implement scene progress dependencies */
  public get progress(): boolean {
    return false;
  }

  public beforeDestroy = (): void => {};
}
