import { DtoPaginationParser } from "./parsers/DtoPaginationParser";
import { JoiPaginationScheme } from "./schemes/PaginationScheme";
import { IDto } from "./__types__/dto";
import { transform } from "./transform";
import { DtoUserParser } from "./parsers/DtoUserParser";
import { DtoAuthUserScheme } from "./schemes/DtoAuthUserScheme";
import { DtoAuthUserParser } from "./parsers/DtoAuthUserParser";
import { DtoUserScheme } from "./schemes/DtoUserScheme";
import { DtoFuelsByRegionReportParser } from "./parsers/DtoFuelsByRegionReportParser";
import { DtoFuelsByRegionReportScheme } from "./schemes/DtoFuelsByRegionReportScheme";
import { DtoRegionListItemParser } from "./parsers/DtoRegionListItemParser";
import { DtoNewContractScheme } from "./schemes/DtoNewContractScheme";
import { DtoContractListParser } from "./parsers/DtoContractListParser";
import { DtoContractListScheme } from "./schemes/DtoContractScheme";
import { DtoContractListItemScheme } from "./schemes/DtoContractListItemScheme";
import { DtoRegionListItemScheme } from "./schemes/DtoRegionListItemScheme";
import { DtoContractListItemParser } from "./parsers/DtoContractListItemParser";
import { DtoContractorListItemParser } from "./parsers/DtoContractorListItemParser";
import { DtoContractorListItemScheme } from "./schemes/DtoContractorListItemScheme";
import { DtoNewScoreParser } from "./parsers/DtoNewScoreParser";
import { DtoScoreListItemParser } from "./parsers/DtoScoreListItemParser";
import { DtoScoreListParser } from "./parsers/DtoScoreListParser";
import { DtoNewScoreScheme } from "./schemes/DtoNewScoreScheme";
import { DtoScoreListItemScheme } from "./schemes/DtoScoreListItemScheme";
import { DtoScoreListScheme } from "./schemes/DtoScoreScheme";
import { DtoFuelingRequestParser } from "./parsers/DtoFuelingRequestParser";
import { DtoFuelingRequestScheme } from "./schemes/DtoFuelingRequestScheme";
import { DtoFuelingListParser } from "./parsers/DtoFuelingListParser";
import { DtoFuelingListScheme } from "./schemes/DtoFuelingListScheme";
import { DtoFuelingListItemParser } from "./parsers/DtoFuelingListItemParser";
import { DtoFuelingListItemScheme } from "./schemes/DtoFuelingListItemScheme";
import { DtoVehicleListItemScheme } from "./schemes/DtoVehicleListItemScheme";
import { DtoVehicleListItemParser } from "./parsers/DtoVehicleListItemParser";
import { DtoFuelingCommentListItemScheme } from "./schemes/DtoFuelingCommentListItemScheme";
import { DtoFuelingCommentListItemParser } from "./parsers/DtoFuelingCommentListItemParser";
import { DtoProfileScheme } from "./schemes/DtoProfileScheme";
import { DtoProfileParser } from "./parsers/DtoProfileParser";
import { DtoFuelingTurnoverListItemScheme } from "./schemes/DtoFuelingTurnoverListItemScheme";
import { DtoFuelingTurnoverListItemParser } from "./parsers/DtoFuelingTurnoverListItemParser";
import { DtoFuelingTurnoverContractListItemParser } from "./parsers/DtoFuelingTurnoverContractListItemParser";
import { DtoFuelingTurnoverContractListItemScheme } from "./schemes/DtoFuelingTurnoverContractListItemScheme";
import { DtoFuelTransferParser } from "./parsers/DtoFuelTransferParser";
import { DtoFuelTransferScheme } from "./schemes/DtoFuelTransferScheme";
import { DtoPilotListItemParser } from "./parsers/DtoPilotListItemParser";
import { DtoPilotListItemScheme } from "./schemes/DtoPilotListItemScheme";
import { DtoFuelingTotalsParser } from "./parsers/DtoFuelingTotalsParser";
import { DtoFuelingTotalsScheme } from "./schemes/DtoFuelingTotalsScheme";
import { DtoNotificationListItemScheme } from "./schemes/DtoNotificationListItemScheme";
import { DtoNotificationListItemParser } from "./parsers/DtoNotificationListItemParser";
import { DtoRecalculationsListParser } from "./parsers/DtoRecalculationsListParser";
import { DtoRecalculationsListItemParser } from "./parsers/DtoRecalculationsListItemParser";
import { DtoRecalculationsListScheme } from "./schemes/DtoRecalculationsListSheme";
import { DtoRecalculationsListItemScheme } from "./schemes/DtoRecalculationsListItemSheme";

export const dto: IDto = {
  Parsers: {
    AuthUser: DtoAuthUserParser,
    ContractList: DtoContractListParser,
    ContractListItem: DtoContractListItemParser,
    ContractorListItem: DtoContractorListItemParser,
    FuelTransfer: DtoFuelTransferParser,
    FuelingCommentListItem: DtoFuelingCommentListItemParser,
    FuelingList: DtoFuelingListParser,
    FuelingListItem: DtoFuelingListItemParser,
    FuelingRequest: DtoFuelingRequestParser,
    FuelingTotals: DtoFuelingTotalsParser,
    FuelingTurnoverContractListItem: DtoFuelingTurnoverContractListItemParser,
    FuelingTurnoverListItem: DtoFuelingTurnoverListItemParser,
    FuelsByRegionReport: DtoFuelsByRegionReportParser,
    NewScore: DtoNewScoreParser,
    NotificationListItem: DtoNotificationListItemParser,
    Pagination: DtoPaginationParser,
    PilotListItem: DtoPilotListItemParser,
    Profile: DtoProfileParser,
    RecalculationsList: DtoRecalculationsListParser,
    RecalculationsListItem: DtoRecalculationsListItemParser,
    RegionListItem: DtoRegionListItemParser,
    ScoreList: DtoScoreListParser,
    ScoreListItem: DtoScoreListItemParser,
    User: DtoUserParser,
    VehicleListItem: DtoVehicleListItemParser,
  },
  Schemes: {
    AuthUser: DtoAuthUserScheme,
    ContractList: DtoContractListScheme,
    ContractListItem: DtoContractListItemScheme,
    ContractorListItem: DtoContractorListItemScheme,
    FuelTransfer: DtoFuelTransferScheme,
    FuelingCommentListItem: DtoFuelingCommentListItemScheme,
    FuelingList: DtoFuelingListScheme,
    FuelingListItem: DtoFuelingListItemScheme,
    FuelingRequest: DtoFuelingRequestScheme,
    FuelingTotals: DtoFuelingTotalsScheme,
    FuelingTurnoverContractListItem: DtoFuelingTurnoverContractListItemScheme,
    FuelingTurnoverListItem: DtoFuelingTurnoverListItemScheme,
    FuelsByRegionReport: DtoFuelsByRegionReportScheme,
    NewContract: DtoNewContractScheme,
    NewScore: DtoNewScoreScheme,
    NotificationListItem: DtoNotificationListItemScheme,
    Pagination: JoiPaginationScheme,
    PilotListItem: DtoPilotListItemScheme,
    Profile: DtoProfileScheme,
    RecalculationsList: DtoRecalculationsListScheme,
    RecalculationsListItem: DtoRecalculationsListItemScheme,
    RegionListItem: DtoRegionListItemScheme,
    ScoreList: DtoScoreListScheme,
    ScoreListItem: DtoScoreListItemScheme,
    User: DtoUserScheme,
    VehicleListItem: DtoVehicleListItemScheme,
  },
  transform,
};
