import { memoize } from "../memoize";

type LatLng = {
  lat: number;
  lng: number;
};

export const calculateDistance = memoize(
  (start: LatLng, end: LatLng): number => {
    const R = 6371; // km
    const dLat = toRad(end.lat - start.lat);
    const dLon = toRad(end.lng - start.lng);
    const lat1 = toRad(start.lat);
    const lat2 = toRad(end.lat);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    // Use toFixed if d is number
    return d ? Number(d.toFixed(2)) : d;
  }
);

// Converts numeric degrees to radians
const toRad = (value: number): number => (value * Math.PI) / 180;
