import {
  IFuelingListFilters,
  IFuelingListViewModel,
  TFuelingFieldsOrder,
  TFuelingTotals,
} from "./__types__/IFuelingListViewModel";
import { RequestStatus } from "../../../constants/repositories";
import { Pagination } from "../pagination";
import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
import {
  DateFilerType,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "../DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { DatePeriodFilterViewModel } from "../DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { ISearchFilterViewModel } from "../SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { SearchFilterViewModel } from "../SearchFilterViewModel/SearchFilterViewModel";
import { IFieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/__types__/IFieldOrderFilterViewModel";
import { FieldOrderFilterViewModel } from "../FieldOrderFilterViewModel/FieldOrderFilterViewModel";
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";
import { FuelingsRepository } from "@repositories/fuelings";
import { IFuelingsRepository } from "@repositories/fuelings/__types__/repository";
import {
  FuelingListItemEntity,
  IFuelingListItemEntity,
} from "@entities/FuelingListItemEntity";
import _ from "lodash";
import { AxiosRequestClient, Utils } from "@modules/index";
import { observable } from "mobx";

export class FuelingListViewModel implements IFuelingListViewModel {
  public statuses: Statuses = new Statuses([
    "fetchItems",
    "fetchItemsDocument",
    "fetchItemsTotals",
  ]);

  public fetchItemsBatchDebounce: _.DebouncedFuncLeading<
    (page?: number, refreshing?: boolean) => Promise<void>
  > = Utils.debounce(
    (page: number = 1, refreshing: boolean = false): Promise<void> =>
      this.fetchItemsBatch(page, refreshing, true),
    400
  );

  public privateFilters: {
    contractor: null | { id: number; name: string };
    contract: null | { id: number; number: string };
    region: null | { id: number; name: string };
    pilot: null | { id: number; name: string };
    number: number | null;
    fuelingType: number | null;
    fuelingNumber: string | null;
    vehicle: { id: number; number: string; name: string } | null;
    per_page: number;
  } = observable({
    contract: null,
    contractor: null,
    fuelingNumber: null,
    fuelingType: null,
    number: null,
    per_page: 12,
    pilot: null,
    region: null,
    vehicle: null,
  });

  public fuelingTotals: TFuelingTotals = observable({
    totalVolume: "0",
    totalWeight: "0",
  });

  public FieldOrderFilterViewModel: IFieldOrderFilterViewModel<TFuelingFieldsOrder>;

  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  private SearchFilterViewModel: ISearchFilterViewModel;

  private repository: IFuelingsRepository = new FuelingsRepository(
    new AxiosRequestClient()
  );

  /** Pagination list of items */
  private _pagination: IPagination<IFuelingListItemEntity> = new Pagination(
    undefined
  );

  public constructor(
    private model: IRootTreeModel,
    params: {
      search: string;
      per_page?: number;
      dateType?:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  ) {
    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params?.dateType,
    });

    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: params.search,
    });

    this.FieldOrderFilterViewModel =
      new FieldOrderFilterViewModel<TFuelingFieldsOrder>("asc", "createdAt");

    if (params.per_page) this.privateFilters.per_page = params.per_page;
  }

  /**
   * List of filters applied to request. When some of the filters is changed list
   * will be automatically re-fetched with new parameters. Filters should not give ability to
   * be change from external directly so we should keep it private
   */
  public get filters(): IFuelingListFilters<TFuelingFieldsOrder> {
    return {
      contract: this.privateFilters.contract,
      contractor: this.privateFilters.contractor,
      date_filter: this.DatePeriodFilterViewModel.state.type,
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,
      direction: this.FieldOrderFilterViewModel.state.direction,
      fuelingNumber: this.privateFilters.fuelingNumber,
      fuelingType: this.privateFilters.fuelingType,
      number: this.privateFilters.number,
      order: this.FieldOrderFilterViewModel.state.field,
      per_page: this.privateFilters.per_page,
      pilot: this.privateFilters.pilot,
      region: this.privateFilters.region,
      search: this.SearchFilterViewModel.state.search,
      vehicle: this.privateFilters.vehicle,
    };
  }

  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  public get metadata(): IPagination<IFuelingListItemEntity> {
    return this._pagination;
  }

  public get list(): IFuelingListItemEntity[] {
    return this._pagination.data;
  }

  public setVehicle = async (
    vehicle: { id: number; number: string; name: string } | null
  ): Promise<void> => {
    this.privateFilters.vehicle = vehicle;
  };

  public setFuelingType = async (type: number | null): Promise<void> => {
    this.privateFilters.fuelingType = type;
  };

  public setContractor = async (
    contractor: {
      id: number;
      name: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contractor = contractor;
  };

  public setContract = async (
    contract: {
      id: number;
      number: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contract = contract;
  };

  public setRegion = (region: { id: number; name: string } | null): void => {
    this.privateFilters.region = region;
  };

  public setPilot = (pilot: { id: number; name: string } | null): void => {
    this.privateFilters.pilot = pilot;
  };

  public setFuelingNumber = (fuelingNumber: string | null): void => {
    this.privateFilters.fuelingNumber = fuelingNumber;
  };

  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  public setFieldOrder = (
    field: TFuelingFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    this.FieldOrderFilterViewModel.setFieldFilter(field);
    this.FieldOrderFilterViewModel.setDirectionFilter(direction);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  public fetchItemsBatch = async (
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean,
    isEmptyDocument: boolean
  ): Promise<void> => {
    try {
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItems") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }

      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Pending);

      if (refreshing || page === 1) {
        this._pagination.metadata = {};
      }

      let response;

      if (isEmptyDocument) response = [];
      else {
        response = await this.repository.getFuelingsList({
          contract: this.filters.contract?.id,
          contractor: this.filters.contractor?.id,
          date_from: this.filters.date_from,
          date_to: this.filters.date_to,
          direction: this.filters.direction,
          fuelingType: this.filters.fuelingType || undefined,
          number: this.filters.fuelingNumber || undefined,
          order: this.filters.order,
          page,
          per_page: this.filters.per_page,
          pilot: this.filters.pilot?.id || undefined,
          region: this.filters.region?.id || undefined,
          search: this.filters.search,
          vehicle: this.filters.vehicle?.id,
        });

        if (response.meta.current_page === 1) {
          this._pagination.metadata = {
            data: response.data.map(
              (item: any): IFuelingListItemEntity =>
                FuelingListItemEntity.create(item)
            ),
            meta: response.meta,
          };
        } else {
          this._pagination.metadata = {
            data: _.unionBy(
              this._pagination.data,
              response.data.map(
                (item: any): IFuelingListItemEntity =>
                  FuelingListItemEntity.create(item)
              )
            ),
            meta: response.meta,
          };
        }
      }

      this.statuses.setStatus("fetchItems", RequestStatus.Success);
    } catch (error) {
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItems", RequestStatus.Error);
      throw error;
    }
  };

  public fetchItemsDocument = async (
    format: "pdf" | "xls",
    type: string,
    isEmptyDocument: boolean
  ): Promise<Blob | null> => {
    try {
      this.statuses.setStatus("fetchItemsDocument", RequestStatus.Pending);

      let response;

      if (!isEmptyDocument)
        response = await this.repository.getFuelingsListDocument({
          contract: this.filters.contract?.id,
          contractor: this.filters.contractor?.id,
          date_from: this.filters.date_from,
          date_to: this.filters.date_to,
          direction: this.filters.direction,
          format,
          fuelingType: this.filters.fuelingType || undefined,
          number: this.filters.fuelingNumber || undefined,
          order: this.filters.order,
          // page: 1,
          // per_page: 10000,
          pilot: this.filters.pilot?.id || undefined,
          region: this.filters.region?.id || undefined,
          search: this.filters.search,
          type,
          vehicle: this.filters.vehicle?.id || undefined,
        });
      else
        response = await this.repository.getFuelingsListDocument({
          contract: undefined,
          contractor: undefined,
          date_from: 964986260000,
          date_to: 974986260000,
          direction: this.filters.direction,
          format,
          fuelingType: undefined,
          number: undefined,
          order: this.filters.order,
          // page: 1,
          // per_page: 10000,
          pilot: undefined,
          region: undefined,
          search: this.filters.search,
          type,
          vehicle: undefined,
        });

      this.statuses.setStatus("fetchItemsDocument", RequestStatus.Success);

      return response;
    } catch (error) {
      if (isCancel(error)) {
        return null;
      }
      this.statuses.setStatus("fetchItemsDocument", RequestStatus.Error);
      throw error;
    }
  };

  public fetchItemsTotals = async (isEmptyDocument: boolean): Promise<void> => {
    try {
      this.statuses.setStatus("fetchItemsTotals", RequestStatus.Pending);

      const response = await this.repository.getFuelingTotals({
        contract: this.filters.contract?.id,
        contractor: this.filters.contractor?.id,
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        direction: this.filters.direction,
        fuelingType: this.filters.fuelingType || undefined,
        number: this.filters.fuelingNumber || undefined,
        order: this.filters.order,
        // page,
        // per_page: this.filters.per_page,
        pilot: this.filters.pilot?.id || undefined,
        region: this.filters.region?.id || undefined,
        search: this.filters.search,
        vehicle: this.filters.vehicle?.id || undefined,
      });

      if (isEmptyDocument) {
        this.fuelingTotals.totalVolume = "0";
        this.fuelingTotals.totalWeight = "0";
      } else {
        this.fuelingTotals.totalVolume = response.totals.totalVolume;
        this.fuelingTotals.totalWeight = response.totals.totalWeight;
      }
      this.statuses.setStatus("fetchItemsTotals", RequestStatus.Success);
    } catch (error) {
      if (isCancel(error)) {
        return;
      }
      this.statuses.setStatus("fetchItemsTotals", RequestStatus.Error);
      throw error;
    }
  };

  public clearList = (): void => {
    this._pagination.metadata = {};
  };

  public beforeDestroy(): void {
    // Object.values(this.reactions).forEach((r) => r());
    this.repository.beforeDestroy();
  }
}
