import { IViewModel } from "../../__types__/IViewModel.types";

export enum DateFilerType {
  All = "All",
  Year = "Year",
  Month = "Month",
  Week = "Week",
  Custom = "Custom",
}

export type IDateFilterState = {
  end: number;
  start: number;
  type: DateFilerType;
};

export type IDatePeriodFilterViewModelParams =
  | {
      end: number;
      start: number;
      type: DateFilerType.Custom;
    }
  | {
      type:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  | {
      end?: undefined;
      start?: undefined;
      type: undefined;
    };

export declare class IDatePeriodFilterViewModel implements IViewModel {
  public state: IDateFilterState;

  public setCustomFilter(start: number, end: number): void;

  public setAllFilter(): void;

  public setYearFilter(): void;

  public setMonthFilter(): void;

  public setWeekFilter(): void;

  public setFilter(params: IDatePeriodFilterViewModelParams | null): void;

  public beforeDestroy(): void;
}
