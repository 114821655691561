import { IScreenControllerReportByKVS } from "@screens/ReportByKVSScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import SelectComponent from "@shared/controls/Select/Select";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import styled from "styled-components";

export const ReportByKVSsAdditionalFilters = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportByKVS;
  }>();

  return (
    <ColGap16>
      <label>{TranslationService.t("txt_pilot")}</label>
      <SelectComponent
        isClearable
        onFocus={UIViewController.fetchListOfPilots}
        options={UIViewController.listOfPilotsMetadata.data.map(
          (pilot, index) => ({
            id: pilot.id,
            index,
            label: pilot.name,
            name: pilot.name,
            value: pilot.id,
          })
        )}
        onMenuScrollToBottom={() => UIViewController.fetchListOfPilots()}
        onInputChange={UIViewController.setPilotSearch}
        isLoading={UIViewController.isPilotsLoading}
        // value={UIViewController.listOfFuelingFilters.pilot}
        value={
          UIViewController.listOfFuelingFilters.pilot
            ? {
                label: UIViewController.listOfFuelingFilters.pilot.name,
                value: UIViewController.listOfFuelingFilters.pilot.id,
              }
            : null
        }
        onChange={(data: any) => UIViewController.setPilot(data)}
      />
    </ColGap16>
  );
});

const RowGap = styled.div`
  gap: 14px;
`;

const ColGap16 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
