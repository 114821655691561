/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const RecalculationsListContractorItemEntity = types.model({
  id: types.number,
  number: types.optional(types.number, 0),
});
const RecalculationsListRegionItemEntity = types.model({
  id: types.number,
  name: types.optional(types.string, ""),
});

export const RecalculationsListItemEntity = types
  .model({
    contract: types.optional(RecalculationsListContractorItemEntity, {
      id: 0,
      number: 0,
    }),
    id: types.identifierNumber,
    newPricePerTon: types.optional(types.number, 0),
    number: types.optional(types.string, ""),
    oldPricePerTon: types.optional(types.number, 0),
    recalculationDate: types.optional(types.string, ""),
    region: types.optional(RecalculationsListRegionItemEntity, {
      id: 0,
      name: "",
    }),
  })
  .named("RecalculationsListItemEntity");

export interface IRecalculationsListItemEntity
  extends Instance<typeof RecalculationsListItemEntity> {}
export interface IRecalculationsListItemEntityIn
  extends SnapshotIn<IRecalculationsListItemEntity> {}
export interface IRecalculationsListItemEntityOut
  extends SnapshotOut<IRecalculationsListItemEntity> {}
