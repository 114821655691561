const IconAlert = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.10528 17.625H17.8979C18.5115 17.6243 19.111 17.447 19.621 17.1154C20.131 16.7839 20.5285 16.3129 20.7634 15.762C20.9983 15.2111 21.06 14.6049 20.9409 14.0199C20.8217 13.4349 20.5269 12.8973 20.0938 12.475L18.4316 10.8562V8.25C18.4316 6.5924 17.7542 5.00269 16.5483 3.83058C15.3424 2.65848 13.7069 2 12.0016 2C10.2963 2 8.66076 2.65848 7.4549 3.83058C6.24903 5.00269 5.57159 6.5924 5.57159 8.25V10.8562L3.90943 12.4719C3.47517 12.8941 3.17945 13.4319 3.05965 14.0174C2.93985 14.603 3.00136 15.2099 3.23638 15.7614C3.47141 16.313 3.86941 16.7844 4.38007 17.1161C4.89072 17.4478 5.49109 17.6249 6.10528 17.625ZM5.72912 14.2406L8.14359 11.8938V8.25C8.14359 7.25544 8.55006 6.30161 9.27358 5.59835C9.9971 4.89509 10.9784 4.5 12.0016 4.5C13.0248 4.5 14.0061 4.89509 14.7296 5.59835C15.4531 6.30161 15.8596 7.25544 15.8596 8.25V11.8938L18.2773 14.2437C18.3505 14.3166 18.4001 14.4089 18.4198 14.5089C18.4394 14.609 18.4283 14.7125 18.3879 14.8065C18.3474 14.9005 18.2794 14.9808 18.1922 15.0375C18.1051 15.0941 18.0027 15.1245 17.8979 15.125H6.10528C6.00008 15.1245 5.89737 15.0939 5.81005 15.0368C5.72273 14.9798 5.6547 14.8989 5.61451 14.8045C5.57432 14.71 5.56375 14.606 5.58414 14.5057C5.60454 14.4054 5.65498 14.3132 5.72912 14.2406Z"
      fill="#303B57"
    />
    <path
      d="M10.7156 19.5C10.3745 19.5 10.0474 19.6317 9.80626 19.8661C9.56509 20.1005 9.4296 20.4185 9.4296 20.75C9.4296 21.0815 9.56509 21.3995 9.80626 21.6339C10.0474 21.8683 10.3745 22 10.7156 22H13.2876C13.6287 22 13.9558 21.8683 14.1969 21.6339C14.4381 21.3995 14.5736 21.0815 14.5736 20.75C14.5736 20.4185 14.4381 20.1005 14.1969 19.8661C13.9558 19.6317 13.6287 19.5 13.2876 19.5H10.7156Z"
      fill="#303B57"
    />
  </svg>
);

export default IconAlert;
