import {
  IFiltersState,
  IFiltersViewModel,
} from "./__types__/IFiltersViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";

import { observable } from "mobx";

export class FiltersViewModel implements IFiltersViewModel {
  /**
   * The statuses object for managing view model status.
   *
   * @type {Statuses}
   */
  public statuses: Statuses = new Statuses(["createContract"]);

  /**
   * The state object for storing filter values.
   *
   * @type {IFiltersState}
   */
  public state: IFiltersState;

  /**
   * Constructor for FiltersViewModel.
   *
   * @param {IRootTreeModel} model - The root tree model.
   * @param {Partial<IFiltersState>} params - Optional parameters to initialize the state.
   */
  public constructor(
    private model: IRootTreeModel,
    params: Partial<IFiltersState>
  ) {
    this.state = observable({ ...this.initialState, ...params });
  }

  /**
   * Get the initial state for the view model.
   *
   * @returns {IFiltersState} - The initial state object.
   * @public
   */
  public get initialState(): IFiltersState {
    return {
      conclusionDate: null,
      contract: null, // The selected contract object
      contractor: null, // The selected contractor object
      filterCount: 0,
      fueling: null, // The selected fueling type ID
      fuelingNumber: "", // The fueling number filter
      fuelingType: null, // The selected fueling type ID
      pilot: null, // The selected pilot object
      region: null, // The selected region object
      score: null, // The selected score filter
      termDate: null,
      vehicle: null, // The selected vehicle object,
    };
  }

  public setFilterCount(count: number): void {
    this.state.filterCount = count;
  }

  public setConclusionDate = (
    date: { date_from: number; date_to: number } | null
  ): void => {
    this.state.conclusionDate = date;
  };

  public setTermDate = (
    date: { date_from: number; date_to: number } | null
  ): void => {
    this.state.termDate = date;
  };

  /**
   * Set the selected contract.
   *
   * @param {object | null} data - The selected contract object or null.
   */
  public setContract = (data: { id: number; number: string } | null): void => {
    this.state.contract = data;
  };

  /**
   * Set the selected score filter.
   *
   * @param {string|null} data - The selected score filter or null.
   */
  public setScore = (data: string | null): void => {
    this.state.score = data;
  };

  /**
   * Set the selected fueling type.
   *
   * @param {number|null} fuelingType - The selected fueling type ID or null.
   */
  public setFuelingType = (fuelingType: number | null): void => {
    this.state.fuelingType = fuelingType;
  };

  /**
   * Set the fueling number filter.
   *
   * @param {string|null} fuelingNumber - The fueling number filter or null.
   */
  public setFuelingNumber = (fuelingNumber: string | null): void => {
    this.state.fuelingNumber = fuelingNumber;
  };

  public setFueling(fueling: { id: number; number: string } | null): void {
    this.state.fueling = fueling;
  }

  /**
   * Set the selected vehicle.
   *
   * @param {object | null} data - The selected vehicle object or null.
   */
  public setVehicle = (data: { id: number; number: string } | null): void => {
    this.state.vehicle = data;
  };

  /**
   * Set the selected contractor.
   *
   * @param {object | null} data - The selected contractor object or null.
   */
  public setContractor = (data: { id: number; name: string } | null): void => {
    this.state.contractor = data;
  };

  /**
   * Set the selected region.
   *
   * @param {object | null} data - The selected region object or null.
   */
  public setRegion = (data: { id: number; name: string } | null): void => {
    this.state.region = data || null;
  };

  /**
   * Set the selected pilot.
   *
   * @param {object | null} data - The selected pilot object or null.
   */
  public setPilot = (data: { id: number; name: string } | null): void => {
    this.state.pilot = data || null;
  };

  /**
   * Clear the state of the view model.
   */
  public clearState = (): void => {
    this.state = this.initialState;
  };

  /**
   * Cleanup function before destroying the view model instance.
   */
  public beforeDestroy(): void {
    // Clean up any subscriptions or reactions here
    // Object.values(this.reactions).forEach((r) => r());
    // Clean up the repository instance
    // this.repository.beforeDestroy();
  }
}
