import {
  TDtoFuelingTurnoverListItemEntry,
  TDtoFuelingTurnoverListSubItemEntry,
} from "./__types__/TDtoFuelingTurnoverListItemEntry.types";
import { DtoFuelingListItemParser } from "./DtoFuelingListItemParser";
import { TParseDto } from "../__types__/dto";
import _ from "lodash";

export const DtoFuelingTurnoverListSubItemParser: TParseDto<
  TDtoFuelingTurnoverListSubItemEntry
> = (data: any): TDtoFuelingTurnoverListSubItemEntry => ({
  conclusionDate:
    typeof data.conclusionDate === "string" ? data.conclusionDate : "",
  contractor: {
    id: data.contractor?.id !== undefined ? Number(data.contractor.id) : 0,
    name: typeof data.contractor?.name === "string" ? data.contractor.name : "",
  },
  id: data.id !== undefined ? Number(data.id) : 0,
  number: typeof data.number === "string" ? data.number : "",
  regions: Array.isArray(data.regions)
    ? data.regions.map((region: any) => ({
        criticalWeight:
          region.criticalWeight !== undefined
            ? Number(region.criticalWeight)
            : 0,
        id: region.id !== undefined ? Number(region.id) : 0,
        minimalWeight:
          region.minimalWeight !== undefined ? Number(region.minimalWeight) : 0,
        name: typeof region.name === "string" ? region.name : "",
      }))
    : [],
  requests: Array.isArray(data.requests)
    ? data.requests.map(DtoFuelingListItemParser)
    : [],
  termDate: typeof data.termDate === "string" ? data.termDate : "",
  totals: {
    total: data.totals?.total !== undefined ? Number(data.totals.total) : 0,
    totalIn:
      data.totals?.totalIn !== undefined ? Number(data.totals.totalIn) : 0,
    totalOut:
      data.totals?.totalOut !== undefined ? Number(data.totals.totalOut) : 0,
    transferIn:
      data.totals?.transferIn !== undefined
        ? Number(data.totals.transferIn)
        : 0,
    transferOut:
      data.totals?.transferOut !== undefined
        ? Number(data.totals.transferOut)
        : 0,
  },
});

export const DtoFuelingTurnoverListItemParser: TParseDto<
  TDtoFuelingTurnoverListItemEntry
> = (data: any): TDtoFuelingTurnoverListItemEntry => {
  const regionId = Number(data[0]);

  const values = Object.values(data[1]);

  const allRegions = _.uniqBy(
    values.reduce(
      (acc: any[], item: any) => (acc = [...acc, ...item.regions]),
      []
    ),
    (item) => item.id
  );

  return {
    id: regionId,
    items: Object.values(values).map(DtoFuelingTurnoverListSubItemParser),
    name:
      allRegions.find((region) => region.id === regionId)?.name || "Unknown",
    regions: allRegions,
  };
};
