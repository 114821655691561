import { IScreenControllerReportsByContract } from "./connector/__types__/UIViewController";
import { ScreenControllerReportsByContract } from "./connector/UIViewController";
import { ReportsByContractsTable } from "./components/Table/Table";
import { ReportsByContractDateFilter } from "./components/DateFilter/DateFilter";
import { ReportsByContractsActions } from "./components/Actions/Actions";
import { ReportsByContractsPagination } from "./components/Pagination/Pagination";
import { ReportsByContractsAdditionalFilters } from "./components/AdditionalFilters/AdditionalFilters";
import { ReportsByContractsTotals } from "./components/Totals/Totals";
import "./styles.scss";
import { SortingMobile } from "./components/SortingMobile/SortingMobile";
import styled from "styled-components";
import { useControllers } from "@view/hooks/useControllers";
import { useEffect } from "react";
import { observer } from "mobx-react";
import withControllers from "@view/providers/__proto__/withController";
import { TranslationService } from "@services/translate";
import Button from "@shared/controls/Button/Button";
import { IconFilter } from "@shared/controls/icons";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { Helmet } from "react-helmet";

const ReportByContractsScreen = observer((): JSX.Element => {
  const windowWidth = useWindowWidth();

  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportsByContract;
  }>();

  useEffect(() => {
    UIViewController.fetchListOfFueling(1, true);
  }, []);

  return (
    <Col32 className="col">
      <Helmet>
        <title>{TranslationService.t("txt_report_by_contract_title")}</title>
      </Helmet>
      <h2>{TranslationService.t("txt_report_by_contract_title")}</h2>
      <ReportsByContractsAdditionalFilters />
      <ReportsByContractsTotals />

      <ColParameters>
        <div className="row justify-between align-start">
          <RowGap className="row">
            {windowWidth <= 768 ? (
              <>
                <ReportsByContractDateFilter />
                <SortingMobile />
              </>
            ) : null}
            <Button
              className={`${
                UIViewController.filterCount ? "primary" : "secondary"
              } w-fit-content ${windowWidth > 768 ? "" : "gap0"}`}
              size="small"
              text={
                windowWidth > 768
                  ? UIViewController.filterCount
                    ? TranslationService.t("txt_filter") +
                      ` ${UIViewController.filterCount}`
                    : TranslationService.t("txt_filter")
                  : ""
              }
              type={"button"}
              iconLeft={<IconFilter />}
              onClick={UIViewController.openFuelingFiltersScene}
            />
            {windowWidth > 768 ? <ReportsByContractDateFilter /> : null}
          </RowGap>

          <ReportsByContractsActions />
        </div>
        {UIViewController.listOfFuelingFilters.contractor?.id ||
        UIViewController.listOfFuelingFilters.contract?.number ? (
          <ReportsByContractsTable />
        ) : null}
      </ColParameters>
      <ReportsByContractsPagination />
    </Col32>
  );
});

export default withControllers(ScreenControllerReportsByContract)(
  ReportByContractsScreen
);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;

  .custom-select {
    max-width: 325px;
  }
`;

const ColParameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const RowGap = styled.div`
  gap: 14px;
`;
