/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

// const FuelingListFuelingsItemEntity = types.string;

const FuelingListContractorItemEntity = types.model({
  id: types.number,
  name: types.optional(types.string, ""),
  region: types.optional(types.array(types.number), []),
});

const FuelingListVehicleItemEntity = types.model({
  id: types.number,
  number: types.optional(types.string, ""),
});

const FuelingListContractItemEntity = types.model({
  conclusionDate: types.optional(types.string, ""),
  id: types.number,
  number: types.optional(types.string, ""),
});

const FuelingListPilotItemEntity = types.model({
  id: types.number,
  name: types.optional(types.string, ""),
});

const FuelingListRegionItemEntity = types.model({
  contractors: types.optional(types.array(types.number), []),
  id: types.number,
  name: types.optional(types.string, ""),
});

export const FuelingListItemEntity = types
  .model({
    contract: FuelingListContractItemEntity,
    contractor: types.optional(FuelingListContractorItemEntity, {
      id: 0,
      name: "",
      // region: [],
    }),
    createdAt: types.optional(types.string, ""),
    id: types.identifierNumber,
    number: types.optional(types.string, ""),
    pilot: types.maybeNull(FuelingListPilotItemEntity),
    region: types.optional(FuelingListRegionItemEntity, {
      // contractors: [],
      id: 0,
      name: "",
    }),
    vehicle: types.maybeNull(FuelingListVehicleItemEntity),
    volume: types.optional(types.string, "0"),
    weight: types.optional(types.string, "0"),
  })
  .named("FuelingListItemEntity");

export interface IFuelingListItemEntity
  extends Instance<typeof FuelingListItemEntity> {}
export interface IFuelingListItemEntityIn
  extends SnapshotIn<IFuelingListItemEntity> {}
export interface IFuelingListItemEntityOut
  extends SnapshotOut<IFuelingListItemEntity> {}
