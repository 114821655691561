/* eslint-disable sort-keys/sort-keys-fix */
import { IScreenControllerRecalculations } from "./connector/__types__/UIViewController";
import { ScreenControllerRecalculations } from "./connector/UIViewController";
import { RecalculationsPagination } from "./components/Pagination/Pagination";
import { RecalculationsTable } from "./components/Table/Table";
import { RecalculationsDateFilter } from "./components/DateFilter/DateFilter";
import { SortingMobile } from "./components/SortingMobile/SortingMobile";
import { TranslationService } from "@services/translate";
import styled from "styled-components";
import Button from "@shared/controls/Button/Button";
import { IconFilter, IconPlus } from "@shared/controls/icons";
import { useEffect } from "react";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import withControllers from "@view/providers/__proto__/withController";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { Helmet } from "react-helmet";

const RecalculationsScreen = observer((): JSX.Element => {
  const windowWidth = useWindowWidth();

  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerRecalculations;
  }>();

  useEffect(() => {
    UIViewController.fetchListOfRecalculations();
  }, []);

  return (
    <Col32 className="col">
      <Helmet>
        <title>{TranslationService.t("txt_title_recalculations")}</title>
      </Helmet>
      <h2>{TranslationService.t("txt_title_recalculations")}</h2>
      <ColParameters>
        <div className="row justify-between align-start">
          <RowGap className="row">
            {windowWidth <= 768 ? (
              <>
                <RecalculationsDateFilter />
                <SortingMobile />
              </>
            ) : null}
            <Button
              className={`${
                UIViewController.filterCount ? "primary" : "secondary"
              } w-fit-content ${windowWidth > 768 ? "" : "gap0"}`}
              size="small"
              text={
                windowWidth > 768
                  ? UIViewController.filterCount
                    ? TranslationService.t("txt_filter") +
                      ` ${UIViewController.filterCount}`
                    : TranslationService.t("txt_filter")
                  : ""
              }
              type={"button"}
              iconLeft={<IconFilter />}
              onClick={UIViewController.openRecalculationsFiltersScene}
            />
            {windowWidth > 768 ? <RecalculationsDateFilter /> : null}
          </RowGap>

          <RowGap className="row">
            <Button
              onClick={() => UIViewController.openCreateRecalculationscene()}
              text={
                windowWidth > 1024
                  ? TranslationService.t("txt_recalculation_create_btn")
                  : TranslationService.t("txt_recalculation_create_btn_short")
              }
              type={"button"}
              className={"primary"}
              size="small"
              iconLeft={windowWidth > 768 ? <IconPlus /> : <></>}
            />
          </RowGap>
        </div>
        <RecalculationsTable />
      </ColParameters>
      <RecalculationsPagination />
    </Col32>
  );
});

export default withControllers(ScreenControllerRecalculations)(RecalculationsScreen);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const ColParameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  .custom-select {
    max-width: 325px;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const RowGap = styled.div`
  gap: 14px;
`;
