import { TDtoFuelingRequestEntry } from "./__types__/TDtoFuelingRequestEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoFuelingRequestParser: TParseDto<TDtoFuelingRequestEntry> = (
  data: any
): TDtoFuelingRequestEntry => ({
  coefficient: Number(data.coefficient),
  contractor: Utils.isString(data.contractor) ? data.contractor : "",
  fuelingType: Number(data.fuelingType),
  number: Utils.isString(data.number) ? data.number : "",
  region: Utils.isString(data.region) ? data.region : "",
  vehicle: Utils.isString(data.vehicle) ? data.vehicle : "",
  volume: Number(data.volume),
  weight: Number(data.weight),
});
