import {
  IUISceneController,
  TNotificationsSceneParams,
  TNotificationsSceneResult,
} from "./types";
import { toast } from "react-toastify";
import { ScenesInstance } from "@scenes/PrototypeScene";
import { Utils } from "@modules/utils";
import { getMessageFromError } from "@modules/utils/getMessageFromError";
import { TSceneDisplayOptions } from "@viewModels/scenes/__types__/SceneDisplay";
import { IPagination } from "@viewModels/pagination/types";
import { IFuelingCommentListItemEntity } from "@entities/FuelingCommentListItemEntity/FuelingCommentListItemEntity";
import { IFuelingCommentsListViewModel } from "@viewModels/FuelingCommentsListViewModel/__types__/IFuelingCommentsListViewMode";
import { FuelingCommentsListViewModel } from "@viewModels/FuelingCommentsListViewModel/FuelingCommentsListViewModel";

export class UISceneController<
    T extends TNotificationsSceneResult,
    P extends TNotificationsSceneParams
  >
  extends ScenesInstance<T, P>
  implements IUISceneController<T, P>
{
  public static _name: string = "NotificationsPilotScene";

  private FuelingCommentsListViewModel: IFuelingCommentsListViewModel;

  public constructor(
    options: TSceneDisplayOptions,
    private params: TNotificationsSceneParams
  ) {
    super(options);

    this.FuelingCommentsListViewModel = new FuelingCommentsListViewModel(
      params.model,
      {
        isNew: true,
        per_page: 100,
      }
    );
  }

  public get _name(): string {
    return UISceneController._name;
  }

  public get listOfNotificationMetadata(): IPagination<IFuelingCommentListItemEntity> {
    return this.FuelingCommentsListViewModel.metadata;
  }

  public get isNotificationsLoading(): boolean {
    return this.FuelingCommentsListViewModel.statuses.isInProgress;
  }

  public fetchListOfNotifications = (): void => {
    try {
      this.FuelingCommentsListViewModel.fetchItemsBatch(
        this.FuelingCommentsListViewModel.metadata.meta.current_page + 1,
        false
      );
    } catch (error) {
      toast.error(getMessageFromError(error));
    }
  };

  public closeScene = (): void => {
    try {
      this.displays.handleRejects();
    } catch (error) {
      toast.error(Utils.getMessageFromError(error));
    }
  };

  public beforeDestroy = (): void => {};
}
