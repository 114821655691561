import { IFindSubstring } from "./types";

/**
 * Function to find if childString is a substring of parentString
 *
 * @param {string} parentString string to search
 * @param {string} childString the string to be searched for
 * @param {boolean} samecase ignore letter cases. Default `true`
 * @returns {boolean} return `true` when `parentString` includes `childString`
 */
export const findSubstring: IFindSubstring = (
  parentString: string,
  childString: string,
  samecase: boolean = true
): boolean => {
  if (!parentString) return false;

  if (!childString) return true;

  const _parentString = samecase
    ? parentString.toLocaleLowerCase()
    : parentString;
  const _childString = samecase ? childString.toLocaleLowerCase() : childString;

  return _parentString
    .toString()
    .toLowerCase()
    .includes(_childString.toString().toLowerCase());
};
