import { Row, SubTitle, Value } from "../../TableOutput";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import styled from "styled-components";

type Props = {
  doc: any;
  headerObj: any;
  listWidth: any;
};

const ContractTableOutput = ({
  doc,
  headerObj,
  listWidth,
}: Props): JSX.Element => {
  const windowWidth = useWindowWidth();

  return (
    <>
      {windowWidth > 1024 ? (
        <Row>
          {Object.keys(headerObj)?.map((elem, ind) => {
            if (
              doc[elem] &&
              (elem === "contract" || elem === "document_number")
            )
              return (
                <p
                  key={elem + ind}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  title={doc[elem][0] + " от " + doc[elem][1]}
                >
                  {doc[elem][0]} от {doc[elem][1]}
                </p>
              );
            else if (elem === "income" && doc[elem] > 0)
              return (
                <p
                  key={elem + ind}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  title={"+" + doc[elem]}
                >
                  +{doc[elem]}
                </p>
              );
            else if (elem === "expense" && doc[elem] > 0)
              return (
                <p
                  key={elem + ind}
                  style={{
                    width: windowWidth > 768 ? listWidth[ind] : "auto ",
                  }}
                  title={"-" + doc[elem]}
                >
                  -{doc[elem]}
                </p>
              );
            else
              return (
                <p
                  key={elem + ind + "doc"}
                  style={{
                    paddingLeft:
                      elem === "region_contractor" && windowWidth > 768
                        ? "60px"
                        : "0",
                    width: listWidth[ind],
                  }}
                  className={
                    elem == "region_contractor" ? "region_contractor" : ""
                  }
                  title={doc[elem]}
                >
                  {doc[elem]}
                </p>
              );
          })}
        </Row>
      ) : (
        <ContractRow className="row justify-between">
          <div className="col gap6 justify-between">
            <Value>{doc?.region_contractor}</Value>
            <SubTitle>№ документа</SubTitle>
            <Value>
              {doc?.document_number[0]} от {doc?.document_number[1]}
            </Value>
            {doc?.expense ? (
              <div className="col">
                <SubTitle>Расход</SubTitle>
                <Value className="fs-16">-{doc?.expense} кг</Value>
              </div>
            ) : (
              <div className="col">
                <SubTitle>Приход</SubTitle>
                <Value className="fs-16">+{doc.income} кг</Value>{" "}
              </div>
            )}
          </div>
        </ContractRow>
      )}
    </>
  );
};

export default ContractTableOutput;

const ContractRow = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #dbdce1;
  &:nth-child(2) {
    padding-top: 26px;
  }
  padding-left: 40px;
`;
