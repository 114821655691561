import {
  // IFuelingTurnoverContractListFilters,
  IFuelingTurnoverContractListViewModel,
  TOpenContractListItem,
} from "./__types__/IFuelingTurnoverContractListViewModel";
import { RequestStatus } from "../../../constants/repositories";
// import { IPagination, IPaginationMeta } from "../pagination/types";
import { Statuses } from "../statuses";
// import {
//   DateFilerType,
//   IDatePeriodFilterViewModel,
import { isCancel } from "axios";
import { IRootTreeModel } from "@models/RootTreeModel";

import { AxiosRequestClient } from "@modules/index";
import { ReportsRepository } from "@repositories/reports/repository";
import { IReportsRepository } from "@repositories/reports/__types__/repository";
import { observable, runInAction } from "mobx";
import { TDtoFuelingTurnoverContractListSubItemEntry } from "@modules/utils/dto/parsers/__types__/TDtoFuelingTurnoverContractListItemEntry.types";
import {
  IFuelingTurnoverListFilters,
  IFuelingTurnoverListLocalFilters,
  TFuelingTurnoverFieldsOrder,
} from "@viewModels/FuelingTurnoverListViewModel/__types__/IFuelingTurnoverListViewModel";
import {
  DateFilerType,
  IDatePeriodFilterViewModel,
  IDatePeriodFilterViewModelParams,
} from "@viewModels/DatePeriodFilterViewModel/__types__/IDatePeriodFilterViewModel.types";
import { IFieldOrderFilterViewModel } from "@viewModels/FieldOrderFilterViewModel/__types__/IFieldOrderFilterViewModel";
import { ISearchFilterViewModel } from "@viewModels/SearchFilterViewModel/__types__/ISearchFilterViewModel";
import { IFuelingTurnoverListItemEntity } from "@entities/FuelingTurnoverListItemEntity/FuelingTurnoverListItemEntity";
import { DatePeriodFilterViewModel } from "@viewModels/DatePeriodFilterViewModel/DatePeriodFilterViewModel";
import { FieldOrderFilterViewModel } from "@viewModels/FieldOrderFilterViewModel/FieldOrderFilterViewModel";
import { SearchFilterViewModel } from "@viewModels/SearchFilterViewModel/SearchFilterViewModel";
import { Pagination } from "@viewModels/pagination";
import { IPagination, IPaginationMeta } from "@viewModels/pagination/types";

/**
 * The `FuelingTurnoverContractListViewModel` class is responsible for managing a list of regions.
 * It provides functionality to fetch and filter regions based on various criteria.
 */
export class FuelingTurnoverContractListViewModel
  implements IFuelingTurnoverContractListViewModel
{
  public statuses: Statuses = new Statuses(["fetchItemsBatch"]);

  public state: TOpenContractListItem;

  // Observables
  public _localFilter: IFuelingTurnoverListLocalFilters = observable({
    contract: null,
    contractor: null,
    fueling: null,
    per_page: 12,
    region: null,
  });

  public privateFilters: {
    contract: null | { id: number; number: string };
    region: null | { id: number; name: string };
    fueling: null | { id: number; number: string };
    per_page: number;
  } = {
    contract: null,
    fueling: null,
    per_page: 12,
    region: null,
  };

  public FieldOrderFilterViewModel: IFieldOrderFilterViewModel<TFuelingTurnoverFieldsOrder>;

  // View models
  private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  private SearchFilterViewModel: ISearchFilterViewModel;

  // View models
  // private DatePeriodFilterViewModel: IDatePeriodFilterViewModel;

  // private SearchFilterViewModel: ISearchFilterViewModel;

  // Repository
  private repository: IReportsRepository = new ReportsRepository(
    new AxiosRequestClient()
  );

  // Pagination
  private _pagination: IPagination<IFuelingTurnoverListItemEntity> =
    new Pagination(undefined);

  // Pagination
  // private _pagination: IPagination<IFuelingTurnoverContractListItemEntity> =
  //   new Pagination(undefined);

  /**
   * Constructor for the `FuelingTurnoverContractListViewModel` class.
   *
   * @param model - The root tree model.
   */
  public constructor(
    private model: IRootTreeModel,
    params?: {
      per_page?: number;
      dateType?:
        | DateFilerType.All
        | DateFilerType.Month
        | DateFilerType.Week
        | DateFilerType.Year;
    }
  ) {
    // Initialize date period filter view model
    this.DatePeriodFilterViewModel = new DatePeriodFilterViewModel({
      type: params?.dateType,
    });

    // Initialize search filter view model
    this.SearchFilterViewModel = new SearchFilterViewModel({
      debounce: 700,
      search: "",
    });

    this.FieldOrderFilterViewModel =
      new FieldOrderFilterViewModel<TFuelingTurnoverFieldsOrder>(
        "asc",
        "expense"
      );

    this.state = observable(this.initialState);
    if (params?.per_page) this.privateFilters.per_page = params?.per_page;
  }

  /**
   * Get the list of filters applied to the request.
   *
   * @returns The filters object.
   */
  public get filters(): IFuelingTurnoverListFilters<TFuelingTurnoverFieldsOrder> {
    return {
      contract: this.privateFilters.contract,
      date_filter: this.DatePeriodFilterViewModel.state.type,
      date_from: this.DatePeriodFilterViewModel.state.start,
      date_to: this.DatePeriodFilterViewModel.state.end,
      direction: this.FieldOrderFilterViewModel.state.direction,
      fueling: this.privateFilters.fueling,
      order: this.FieldOrderFilterViewModel.state.field,
      per_page: this._localFilter.per_page,
      region: this.privateFilters.region,
      search: this.SearchFilterViewModel.state.search,
    };
  }

  /**
   * Get the meta data of the pagination.
   *
   * @returns The pagination meta data.
   */
  public get meta(): IPaginationMeta {
    return this._pagination.meta;
  }

  /**
   * Get the metadata of the pagination.
   *
   * @returns The pagination metadata.
   */
  public get metadata(): IPagination<IFuelingTurnoverListItemEntity> {
    return this._pagination;
  }

  public get listOfOpenContractList(): TOpenContractListItem[] | [] {
    return this.listOfOpenContractList;
  }

  private get initialState(): TOpenContractListItem {
    return {
      fetchContractList: { list: [] },
      openList: [],
    };
  }

  public setOpenContractList(
    data:
      | {
          id: number;
          regionId: number;
          list: TDtoFuelingTurnoverContractListSubItemEntry[] | [];
        }[]
      | []
  ): void {
    runInAction(() => {
      this.state.openList = data;
    });
  }

  /**
   * Set the search filter.
   *
   * @param search - The search keyword.
   */
  public setSearch = (search: string): void => {
    this.SearchFilterViewModel.setSearchFilter(search);
  };

  /**
   * Sets the date filter for the DatePeriodFilterViewModel.
   *
   * @param {IDatePeriodFilterViewModelParams} params - The parameters for the date filter.
   * @returns {Promise<void>} A promise that resolves when the date filter is set.
   */
  public setDateFilter = async (
    params: IDatePeriodFilterViewModelParams
  ): Promise<void> => {
    // Set the date filter using the provided parameters
    this.DatePeriodFilterViewModel.setFilter(params);
  };

  public setContract = async (
    contract: {
      id: number;
      number: string;
    } | null
  ): Promise<void> => {
    this.privateFilters.contract = contract;
  };

  public setRegion = (region: { id: number; name: string } | null): void => {
    this.privateFilters.region = region;
  };

  public setFueling = (
    fueling: { id: number; number: string } | null
  ): void => {
    this.privateFilters.fueling = fueling;
  };

  public setFieldOrder = (
    field: TFuelingTurnoverFieldsOrder,
    direction: "asc" | "desc"
  ): void => {
    this.FieldOrderFilterViewModel.setFieldFilter(field);
    this.FieldOrderFilterViewModel.setDirectionFilter(direction);
  };

  /**
   * Fetch a batch of items.
   *
   * @param page - The page number.
   * @param refreshing - True if the page is being refreshed, false otherwise.
   */
  public fetchItemsBatch = async (
    id: number,
    regionId: number,
    page: number = this._pagination.meta.current_page + 1,
    refreshing: boolean
  ): Promise<void> => {
    try {
      // Check if previous request is ongoing
      if (
        !refreshing &&
        this.statuses.getStatus("fetchItemsBatch") === RequestStatus.Pending
      ) {
        console.warn("Could not make request until previous not finished");
        return;
      }

      // Check if maximum number of pages already loaded
      if (
        !refreshing &&
        this._pagination.meta.current_page >= this._pagination.meta.last_page
      ) {
        console.warn(
          "Could not make request because maximum count of pages already loaded"
        );
        return;
      }

      // Set status to pending
      this.statuses.setStatus("fetchItemsBatch", RequestStatus.Pending);

      // Make the API request
      const response = await this.repository.getFuelingTurnoverContract({
        contract: this.filters.contract?.id,
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        direction: this.filters.direction,
        fueling: this.filters.fueling?.id,
        id,
        // number: this.filters.fuelingNumber || undefined,
        order: this.filters.order,
        page,
        per_page: this.filters.per_page,
        region: regionId,
        search: this.filters.search,
      });

      this.state.fetchContractList = response;

      // Set status to success
      this.statuses.setStatus("fetchItemsBatch", RequestStatus.Success);
    } catch (error) {
      // Check if request was cancelled
      if (isCancel(error)) {
        return;
      }

      // Set status to error
      this.statuses.setStatus("fetchItemsBatch", RequestStatus.Error);
      throw error;
    }
  };

  /**
   * Clear the list of regions.
   */
  public clearList = (): void => {
    this._pagination.metadata = {};
    this.state.fetchContractList = { list: [] };
    this.state.openList = [];
  };

  /**
   * Clean up resources before destroying the view model.
   */
  public beforeDestroy(): void {
    this.repository.beforeDestroy();
  }
}
