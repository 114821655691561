import {
  useState,
  useCallback,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import "./styles.scss";
// eslint-disable-next-line import/order
import { IconEyeClose, IconEye } from "../icons";

type IInputRef = HTMLInputElement;

type Props = {
  type?: string;
  readOnly?: boolean;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  onChange?: (value: any) => void;
  max?: number;
  min?: number;
  newValue?: any
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Input = forwardRef<IInputRef, Props>(
  (
    { value, newValue, type, disabled, readOnly, placeholder, onChange, max, min },
    ref
  ) => {
    const [passwordOpen, setPasswordOpen] = useState(false);
    const passwordRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => passwordRef.current
    );

    const handleOpenPassword = useCallback(() => {
      setPasswordOpen(!passwordOpen);
    }, [passwordOpen]);

    const handleChangeText = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(event.target.value || "");
      },
      []
    );

    useEffect(() => {
      if (passwordRef.current)
        if (passwordOpen) passwordRef.current.type = "text";
        else passwordRef.current.type = "password";
      return;
    }, [passwordOpen]);

    if (type === "password")
      return (
        <div className="relative password">
          <input
            type={type}
            readOnly={readOnly}
            placeholder={placeholder}
            defaultValue={value}
            disabled={disabled}
            ref={passwordRef}
            onChange={handleChangeText}
          />
          <span onClick={handleOpenPassword} className="iconEye">
            {passwordOpen ? <IconEyeClose /> : <IconEye />}
          </span>
        </div>
      );
    else if (type === "date")
      return (
        <input
          type={"date"}
          readOnly={readOnly}
          placeholder={placeholder}
          defaultValue={value}
          disabled={disabled}
          onChange={handleChangeText}
          lang="ru"
        />
      );
    else if (type === "number")
      return (
        <input
          type={"number"}
          readOnly={readOnly}
          placeholder={placeholder}
          max={max}
          min={min}
          defaultValue={value}
          disabled={disabled}
          onChange={handleChangeText}
          value={newValue}
        />
      );
    else
      return (
        <input
          type={type || "text"}
          readOnly={readOnly}
          placeholder={placeholder}
          defaultValue={value}
          disabled={disabled}
          onChange={handleChangeText}
          value={newValue}
        />
      );
  }
);

export default Input;
