import { IContractsRepository } from "./__types__/repository";
import { ContractsRepositoryResponseDto } from "./dtoResponse";
import { TDtoContractListEntry } from "@modules/utils/dto/parsers/__types__/TDtoContractListEntry.types";
import { IRequestClient } from "@modules/request/types/instance";
import { TDtoPaginationEntry } from "@modules/utils/dto/parsers/__types__/TDtoPaginationEntry";
import moment from "moment";

export class ContractsRepository implements IContractsRepository {
  public readonly abortControllers = {
    createContract: new AbortController(),
    getContractsList: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getContractsList = async (params: {
    page: number;
    per_page: number;
    search?: string | undefined;
    // Contract number
    contract?: string;
    contractor?: number;
    region?: number;
    date_from?: number;
    date_to?: number;
    order?: string;
    direction?: "asc" | "desc";
    conclusionDate?: { date_from?: number; date_to?: number };
    termDate?: { date_from?: number; date_to?: number };
  }): Promise<TDtoPaginationEntry<TDtoContractListEntry>> => {
    if (this.abortControllers.getContractsList) {
      this.abortControllers.getContractsList.abort();
    }
    this.abortControllers.getContractsList = new AbortController();

    const response: any = await this.requestClient.get(`/contract`, {
      params: {
        asc: params.direction === "asc",
        conclusionDate: params.conclusionDate
          ? {
              end: moment(params.conclusionDate.date_to).format("YYYY-MM-DD"),
              start: moment(params.conclusionDate.date_from).format(
                "YYYY-MM-DD"
              ),
            }
          : undefined,
        contractor: params.contractor,
        limit: params.per_page,
        number: params.search || params.contract,
        order: params.order,
        page: params.page,
        region: params.region,
        termDate: params.termDate
          ? {
              end: moment(params.termDate.date_to).format("YYYY-MM-DD"),
              start: moment(params.termDate.date_from).format("YYYY-MM-DD"),
            }
          : undefined,
      },
      signal: this.abortControllers.getContractsList.signal,
    });

    return ContractsRepositoryResponseDto.getListOfContract(response);
  };

  public createContract = async (data: {
    number: string;
    conclusionDate: string;
    termDate: string;
    contractor: number;
    regions: number[];
  }): Promise<{ id: number }> => {
    if (this.abortControllers.getContractsList) {
      this.abortControllers.getContractsList.abort();
    }
    this.abortControllers.getContractsList = new AbortController();

    const response: { id: number } = await this.requestClient.post(
      `/contract`,
      {
        ...data,
      },
      {
        signal: this.abortControllers.getContractsList.signal,
      }
    );

    return response;
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
