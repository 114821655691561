import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import App from "./App";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";

Sentry.init({
  debug: true,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.NODE_ENV,
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as Element);

root.render(
  <Router>
    <App />
  </Router>
);
