import { TDtoFuelTransferEntry } from "./__types__/TDtoFuelTransferEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoFuelTransferParser: TParseDto<TDtoFuelTransferEntry> = (
  data: any
): TDtoFuelTransferEntry => ({
  contractor: Utils.isString(data.contractor) ? data.contractor : "",
  regionFrom: Number(data.regionFrom),
  regionTo: Number(data.regionTo),
  weight: Number(data.weight),
});
