import {
  IContractCreateState,
  IContractCreateViewModel,
} from "./__types__/IContractsListViewModel";
import { Statuses } from "../statuses";
import { IRootTreeModel } from "@models/RootTreeModel";
import { ContractsRepository } from "@repositories/contracts";
import { IContractsRepository } from "@repositories/contracts/__types__/repository";

import moment, { Moment } from "moment";
import { TranslationService } from "@services/translate";
import { observable } from "mobx";
import { RequestStatus } from "@constants/repositories";
import { AxiosRequestClient } from "@modules/request/libs/axios";

/**
 * Represents the contract creation view model that implements the IViewModel interface.
 */
export class ContractCreateViewModel implements IContractCreateViewModel {
  public statuses: Statuses = new Statuses(["createContract"]);

  public state: IContractCreateState = observable(this.initialState);

  private repository: IContractsRepository = new ContractsRepository(
    new AxiosRequestClient()
  );

  public constructor(private model: IRootTreeModel) {}

  /**
   * Returns the initial state of the contract create form.
   */
  private get initialState(): IContractCreateState {
    return {
      conclusionDate: moment(),
      contractor: null,
      number: "",
      regions: null,
      termDate: moment(),
    };
  }

  /**
   * Sets the contract number.
   *
   * @param {string} number - The contract number to set.
   */
  public setContractNumber = (number: string): void => {
    this.state.number = number;
  };

  /**
   * Sets the conclusion date.
   *
   * @param {Moment} date - The conclusion date to set.
   */
  public setConclusionDate = (date: Moment): void => {
    if (!moment.isMoment(date))
      throw new Error("txt_please_choose_correct_date");
    this.state.conclusionDate = date;
  };

  /**
   * Sets the term date.
   *
   * @param {Moment} date - The term date to set.
   */
  public setTermDate(date: Moment): void {
    if (!moment.isMoment(date))
      throw new Error("txt_please_choose_correct_date");
    this.state.termDate = date;
  }

  /**
   * Sets the contractor.
   *
   * @param {{ id: number; name: string } | null} data - The contractor data to set.
   */
  public setContractor = (data: { id: number; name: string } | null): void => {
    this.state.contractor = data;
  };

  /**
   * Sets the regions.
   *
   * @param {{ id: number; name: string }[] | null} data - The regions data to set.
   */
  public setRegions = (data: { id: number; name: string }[] | null): void => {
    this.state.regions = data ? data : null;
  };

  /**
   * Creates a contract using the provided data.
   *
   * @param {IContractCreateState} data - The contract creation data.
   * @returns A promise that resolves to an object containing the ID of the created contract.
   * @throws Error if any validation check fails or if there is an error during the API request.
   */
  public createContract = async (
    data: IContractCreateState
  ): Promise<{ id: number }> => {
    try {
      if (!data.number) {
        throw new Error(
          TranslationService.t("txt_wrong_contract_number_format")
        );
      }

      if (!data.contractor)
        throw new Error(TranslationService.t("txt_error_missing_contractor"));

      if (!data.regions?.length)
        throw new Error(TranslationService.t("txt_error_missing_region"));

      if (!data.conclusionDate)
        throw new Error(TranslationService.t("txt_wrong_conclusion_date"));

      if (!data.conclusionDate.isValid())
        throw new Error(TranslationService.t("txt_wrong_conclusion_date"));

      if (!data.termDate)
        throw new Error(TranslationService.t("txt_wrong_term_date"));

      if (!data.termDate.isValid())
        throw new Error(TranslationService.t("txt_wrong_term_date"));

      if (data.termDate.isSameOrBefore(data.conclusionDate))
        throw new Error(
          TranslationService.t("txt_term_date_cant_be_before_conclusion_date")
        );

      this.statuses.setStatus("createContract", RequestStatus.Pending);

      const response = await this.repository.createContract({
        conclusionDate: moment(data.conclusionDate).format("YYYY-MM-DD"),
        contractor: data.contractor.id,
        number: data.number,
        regions: data.regions.map((region) => region.id),
        termDate: moment(data.termDate).format("YYYY-MM-DD"),
      });

      this.statuses.setStatus("createContract", RequestStatus.Success);

      return response;
    } catch (error) {
      this.statuses.setStatus("createContract", RequestStatus.Error);

      throw error;
    }
  };

  /**
   * Clears the state of the contract creation form.
   */
  public clearState(): void {
    this.state = this.initialState;
  }

  /**
   * Performs cleanup tasks before destroying the view model object.
   */
  public beforeDestroy(): void {
    this.repository.beforeDestroy();
  }
}
