export const Screens = {
  All: {
    name: "All",
    path: (): string => "*",
  },
  Application: {
    name: "Application",
    path: (): string => "/application",
  },
  ApplicationComment: {
    name: "ApplicationComment",
    path: (id: string = ":id"): string => `/application/${id}`,
  },
  DocContracts: {
    name: "DocContracts",
    path: (): string => "/doccontracts",
  },
  DocScores: {
    name: "DocScores",
    path: (): string => "/docscores",
  },
  Recalculations: {
    name: "Recalculations",
    path: (): string => "/recalculations",
  },

  // eslint-disable-next-line sort-keys/sort-keys-fix
  FuelingComment: {
    name: "FuelingComment",
    path: (id: string | number = ":id"): string => `/application/${id}`,
  },
  FuelingCommentEdit: {
    name: "FuelingCommentEdit",
    path: (id: string | number = ":id"): string => `/application/${id}/edit`,
  },
  FuelingCreate: {
    name: "FuelingCreate",
    path: (): string => `/fuelings/create`,
  },
  Fuelings: {
    name: "Fuelings",
    path: (): string => "/",
  },
  Login: {
    name: "Login",
    path: (): string => "login",
  },
  PasswordReset: {
    name: "PasswordReset",
    path: (token: string = ":token"): string => `/reset-password/${token}`,
  },
  ProfileScreen: {
    name: "ProfileScreen",
    path: (): string => "/profile",
  },
  ReportContract: {
    name: "ReportContract",
    path: (): string => "/reportcontract",
  },
  ReportKvs: {
    name: "ReportKvs",
    path: (): string => "/reportkvs",
  },
  ReportRegion: {
    name: "ReportRegion",
    path: (): string => "/reportregion",
  },
  ReportVs: {
    name: "ReportVs",
    path: (): string => "/reportvs",
  },
  Root: {
    name: "Root",
    path: (): string => "/",
  },
};
