import "./styles.scss";
import logoIcon from "./images/logoIcon.svg";
import logo from "./images/logo.svg";
import artu from "./images/artu.svg";
import { ScreenControllerAuth } from "./connector/UIViewController";
import { IScreenControllerAuth } from "./connector/__types__/UIViewController";
import { Authorization } from "./components/Authorization/Authorization";
import { ForgetPassword } from "./components/ForgetPassword/ForgetPassword";
import { SendingPassword } from "./components/SendingPassword/SendingPassword";
import withControllers from "@view/providers/__proto__/withController";
import { observer } from "mobx-react";
import { useControllers } from "@view/hooks/useControllers";
import styled from "styled-components";
import { useWindowWidth } from "@view/hooks/useWindowWidth";

const AuthorizationScreen = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();

  const windowWidth = useWindowWidth();

  return (
    <div className="wrapper-auth">
      <div className="container col">
        {windowWidth > 768 || UIViewController.page === "main" ? (
          <div className="row between">
            <h1>
              Система управления <br /> запасами и расходами ГСМ
            </h1>
            <div className="colLogo col">
              <div className="row">
                <img src={logoIcon} />
                <img src={logo} />
              </div>
            </div>
          </div>
        ) : (
          <div className="row w-100 align-end justify-end">
            <div className="colLogo col">
              <div className="row">
                <img src={logoIcon} />
                <img src={logo} />
              </div>
            </div>
          </div>
        )}

        {UIViewController.page === "main" ? <Authorization /> : null}
        {UIViewController.page === "forget" ||
        (UIViewController.isRequestInProgress &&
          UIViewController.page === "forget") ? (
          <ForgetPassword />
        ) : null}
        {!UIViewController.isRequestInProgress ? (
          UIViewController.page === "sending" ? (
            <SendingPassword />
          ) : null
        ) : null}

        <div className="row align-end">
          <Gap8 className="row justify-center align-center">
            <p>Разработано</p>
            <img src={artu} />
          </Gap8>
        </div>
      </div>
    </div>
  );
});

export default withControllers(ScreenControllerAuth)(AuthorizationScreen);

const Gap8 = styled.div`
  gap: 8px;
`;
