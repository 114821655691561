import { navList } from "./navList";
import { LinkComponent } from "./components";
import { SidebarController } from "./connector/UIViewController";
import { ISidebarController } from "./connector/__types__/UIViewController";
import { useEffect } from "react";
import styled from "styled-components";
import { IconSettings, IconLogout, IconCross } from "@shared/controls/icons";
import { artu, logo, logoIcon } from "@assets/images";
import { NavLink, useLocation } from "react-router-dom";
import Profile from "@shared/controls/Profile/Profile";
import withControllers from "@view/providers/__proto__/withController";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { UserRoles } from "@constants/roles";
import { Screens } from "@constants/screens";
import Modal from "react-bootstrap/Modal";
import "./styles.scss";
import Button from "@shared/controls/Button/Button";
import { TranslationService } from "@services/translate";

interface IStyledProps {
  $hideMenuOpen?: boolean;
}

interface NavLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  to: string;
  className?: any;
}

const Sidebar = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: ISidebarController;
  }>();
  const windowWidth = useWindowWidth();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (windowWidth <= 768) UIViewController.closeSideMenu();
  }, [location]);

  useEffect(() => {
    if (windowWidth <= 768) UIViewController.closeSideMenu();
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth <= 768) {
      if (UIViewController.isOpenedSideMenu)
        document.body.style.overflow = "hidden";
      else document.body.style.overflow = "scroll";
    }
  }, [UIViewController.isOpenedSideMenu, windowWidth]);

  return (
    <>
      {UIViewController.isOpenedSideMenu && windowWidth <= 768 && <BgModal />}
      <Wrapper
        $hideMenuOpen={UIViewController.isOpenedSideMenu}
        style={{
          left: UIViewController.isOpenedSideMenu ? "0" : "-100%",
          maxWidth: UIViewController.isOpenedSideMenu ? "270px" : "105px",
        }}
      >
        <Main>
          <ColTop>
            <LogoRow
              style={{
                justifyContent: UIViewController.isOpenedSideMenu
                  ? "flex-start"
                  : "center",
              }}
            >
              <LogoIcon src={logoIcon} />
              {UIViewController.isOpenedSideMenu && <LogoText src={logo} />}
              {windowWidth <= 768 && (
                <CrossBtn onClick={UIViewController.toggleSideMenu}>
                  <IconCross />
                </CrossBtn>
              )}
            </LogoRow>
            <NavList>
              {UIViewController.userinfo?.roles.includes(UserRoles.EmployeeGSM)
                ? navList.ROLE_EMPLOYEE_GSM?.map(
                    (element: any, ind: number) => (
                      <LinkComponent
                        key={`${element}${ind}`}
                        element={element}
                        hideMenuOpen={UIViewController.isOpenedSideMenu}
                      />
                    )
                  )
                : navList.ROLE_KVS?.map((element: any, ind: number) => (
                    <LinkComponent
                      key={`${element}${ind}`}
                      element={element}
                      hideMenuOpen={UIViewController.isOpenedSideMenu}
                    />
                  ))}
            </NavList>
          </ColTop>
          <Col>
            {windowWidth <= 768 && (
              <Profile
                username={
                  UIViewController.profileInfo?.lastName &&
                  UIViewController.profileInfo?.firstName &&
                  UIViewController.profileInfo?.middleName
                    ? UIViewController.profileInfo.lastName +
                      " " +
                      UIViewController.profileInfo.firstName[0] +
                      ". " +
                      UIViewController.profileInfo.middleName[0] +
                      "."
                    : UIViewController.profileInfo?.lastName &&
                      UIViewController.profileInfo?.firstName &&
                      !UIViewController.profileInfo?.middleName
                    ? UIViewController.profileInfo.lastName +
                      " " +
                      UIViewController.profileInfo.firstName[0] +
                      "."
                    : !UIViewController.profileInfo?.lastName &&
                      UIViewController.profileInfo?.firstName &&
                      !UIViewController.profileInfo?.middleName
                    ? UIViewController.profileInfo?.firstName
                    : UIViewController.profileInfo?.lastName &&
                      !UIViewController.profileInfo?.firstName &&
                      !UIViewController.profileInfo?.middleName
                    ? UIViewController.profileInfo?.lastName
                    : UIViewController.profileInfo?.email
                }
                image={UIViewController.profileInfo?.image}
              />
            )}
            <ul>
              <LinkRow
                style={{
                  justifyContent: UIViewController.isOpenedSideMenu
                    ? "flex-start"
                    : "center",
                }}
                to={Screens.ProfileScreen.path()}
                className={({ isActive }:any):any => (isActive ? "active" : "")}
              >
                <IconSettings />
                {UIViewController.isOpenedSideMenu && <p>Настройки</p>}
              </LinkRow>
              <Row
                style={{
                  justifyContent: UIViewController.isOpenedSideMenu
                    ? "flex-start"
                    : "center",
                }}
                // onClick={UIViewController.logout}
                onClick={UIViewController.setIsOpenedModalLogout}
                $hideMenuOpen={UIViewController.isOpenedSideMenu}
              >
                <IconLogout />
                {UIViewController.isOpenedSideMenu && (
                  <p>{TranslationService.t("txt_exit")}</p>
                )}
              </Row>
              <Row
                style={{
                  justifyContent: UIViewController.isOpenedSideMenu
                    ? "flex-start"
                    : "center",
                }}
                $hideMenuOpen={UIViewController.isOpenedSideMenu}
              >
                {UIViewController.isOpenedSideMenu && <p>Разработано</p>}
                <img src={artu} />
              </Row>
            </ul>
          </Col>
        </Main>
        <Hide
          onClick={UIViewController.toggleSideMenu}
          $hideMenuOpen={UIViewController.isOpenedSideMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.88326 8.75L6.875 7.99157L4.67459 5L6.875 2.00843L5.88326 1.25L3.125 5L5.88326 8.75Z"
              fill="#303B57"
            />
          </svg>
        </Hide>
      </Wrapper>
      {UIViewController.isOpenedModalLogout && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={UIViewController.isOpenedModalLogout}
          onHide={UIViewController.setIsOpenedModalLogout}
          className="modal-logout"
        >
          <Modal.Header>
            <IconCross onClick={UIViewController.setIsOpenedModalLogout} />
          </Modal.Header>
          <Modal.Body>
            <TextLogout>Подтвердите выход из системы</TextLogout>
            <Gap16 className="row justify-between">
              <Button
                text={TranslationService.t("txt_stay")}
                type={"button"}
                className={"secondary w-100"}
                size="small"
                onClick={UIViewController.setIsOpenedModalLogout}
              />
              <Button
                text={TranslationService.t("txt_logout")}
                type={"button"}
                size="small"
                className={"primary w-100"}
                onClick={UIViewController.logout}
              />
            </Gap16>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
});

export default withControllers(SidebarController)(Sidebar);

const Wrapper = styled.div<Pick<IStyledProps, "$hideMenuOpen">>`
  height: 100vh;
  overflow-y: scroll;
  max-width: ${({ $hideMenuOpen }) => ($hideMenuOpen ? 270 : 105)}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #fff;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 769px) {
    position: sticky;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100vh;
    float: left;
  }

  @media (max-width: 768px) {
    transition: left 0.3s ease-in;
    position: fixed;
    top: 0;
    // left: -100%;
    z-index: 100;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  content: "";
  background: #12171c66;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`;

const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 44px 0px 32px 0px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Hide = styled.div<Pick<IStyledProps, "$hideMenuOpen">>`
  border-width: 0px, 1px, 0px, 1px;
  border-style: solid;
  border-color: #dbdce1;
  background: #f8f8fb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  svg {
    transition: transform 0.2s ease-in-out;
    transform: ${({ $hideMenuOpen }) => !$hideMenuOpen && "rotate(180deg)"};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    ul li,
    ul a {
      border-top: 1px solid #dbdce1;
    }
  }
`;

const ColTop = styled(Col)`
  gap: 56px;
`;

const LogoRow = styled.div`
  max-height: 32px;
  width: fit-content;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 100%;
`;

const LogoIcon = styled.img``;

const LogoText = styled.img`
  max-height: 19px;
`;

const NavList = styled.ul`
  overflow-y: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Row = styled.li<Pick<IStyledProps, "$hideMenuOpen">>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: ${({ $hideMenuOpen }) => (!$hideMenuOpen ? "16px 0" : "16px 20px")};
  color: #303b57;
  cursor: pointer;
`;

export const LinkRow = styled(NavLink)<Pick<NavLinkProps, "className">>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  color: #303b57;
  &.active {
    background: #f0f2f8;
    color: #ef5d58;
    p {
      color: #ef5d58;
    }
    svg path {
      fill: #ef5d58;
    }
  }
`;

const CrossBtn = styled.div`
  position: absolute;
  background: #f0f2f8;
  border-radius: 50%;
  top: 8px;
  right: 20px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const TextLogout = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
`;

const Gap16 = styled.div`
  gap: 16px;
`;
