import { IProfileRepository } from "./__types__/repository";
import { ProfileRepositoryResponseDto } from "./dtoResponse";
import { TDtoProfileEntry } from "../../modules/utils/dto/parsers/__types__/TDtoProfileEntry.type";
import { IRequestClient } from "@modules/request/types/instance";
import { Utils } from "@modules/utils";

export class ProfileRepository implements IProfileRepository {
  public readonly abortControllers = {
    getProfile: new AbortController(),
    updateProfile: new AbortController(),
    updateProfileImage: new AbortController(),
    updateProfilePassword: new AbortController(),
  };

  public constructor(private requestClient: IRequestClient) {}

  public getProfile = async (): Promise<TDtoProfileEntry> => {
    if (this.abortControllers.getProfile) {
      this.abortControllers.getProfile.abort();
    }
    this.abortControllers.getProfile = new AbortController();

    const response: any = await this.requestClient.get(`/profile`, {
      signal: this.abortControllers.getProfile.signal,
    });

    return ProfileRepositoryResponseDto.getProfile(response);
  };

  public updateProfile = async (
    data: Partial<Omit<TDtoProfileEntry, "image">> & {
      image?: File | undefined;
    }
  ): Promise<void> => {
    if (this.abortControllers.updateProfile) {
      this.abortControllers.updateProfile.abort();
    }
    this.abortControllers.updateProfile = new AbortController();

    await this.requestClient.post(`/profile`, data, {
      signal: this.abortControllers.updateProfile.signal,
    });
  };

  public updateProfilePassword = async (data: {
    oldPassword: string;
    password: string;
  }): Promise<void> => {
    if (this.abortControllers.updateProfilePassword) {
      this.abortControllers.updateProfilePassword.abort();
    }
    this.abortControllers.updateProfilePassword = new AbortController();

    await this.requestClient
      .post(`/profile/change-password`, data, {
        signal: this.abortControllers.updateProfilePassword.signal,
      })
      .catch((error) => {
        localStorage.setItem(
          "PROFILE_ERROR_MSG",
          error?.response?.data?.message
        );

        return error?.response?.data?.message;
      });
  };

  public updateProfileImage = async (data: { image: File }): Promise<void> => {
    if (this.abortControllers.updateProfileImage) {
      this.abortControllers.updateProfileImage.abort();
    }
    this.abortControllers.updateProfileImage = new AbortController();

    await this.requestClient.post(
      `/profile/upload-image`,
      Utils.objectToFormData(data),
      {
        signal: this.abortControllers.updateProfileImage.signal,
      }
    );
  };

  public beforeDestroy = (): void => {
    Object.values(this.abortControllers).forEach((abortController) =>
      abortController.abort()
    );
  };
}
