import {
  IAuthResetViewModel,
  IAuthResetViewModelParams,
} from "./__types__/ResetPasswordViewModel"; // Importing necessary types from IAuthViewModel
import { IReactionDisposer, observable, runInAction } from "mobx"; // Importing required Mobx functionalities
// Importing the Joi library for data validation
import { AuthRepository } from "@repositories/auth"; // Importing AuthRepository from the "auth" repository
import { AxiosRequestClient } from "@modules/request"; // Importing AxiosRequestClient from the "request" module
// Importing Utils from the "utils" module
// Importing IUserEntity and UserEntity from the "UserEntity" entity
import { IRootTreeModel } from "@models/RootTreeModel"; // Importing IRootTreeModel from "RootTreeModel" model
import { isAxiosError } from "axios";
import { TranslationService } from "@services/translate";
// import { TranslationService } from "@services/translate";
// import { isAxiosError } from "axios";

// Define the AuthViewModel class implementing IAuthViewModel interface

export class AuthResetViewModel implements IAuthResetViewModel {
  // Define a reactions object to store Mobx reaction disposers

  public readonly reactions: {
    [key: string]: IReactionDisposer;
  } = {};

  // Define the state object to store the email, password, error, and loading properties

  public state: {
    plainPassword: string;
    plainPasswordRepeat: string;
    loading: boolean;
  };

  // Define repositories object containing the auth repository

  public repositories = {
    auth: new AuthRepository(new AxiosRequestClient()),
  };

  // Define the constructor of AuthViewModel class

  public constructor(
    private model: IRootTreeModel, // Inject the model instance of IRootTreeModel
    params?: IAuthResetViewModelParams // Optional parameter of type IAuthViewModelParams
  ) {
    // Initialize the state object as an observable with the provided parameters or default values

    this.state = observable.object({
      loading: false,
      plainPassword: params?.plainPassword || "",
      plainPasswordRepeat: params?.plainPasswordRepeat || "",
    });
  }

  public setPlainPassword = (password: string): void => {
    runInAction(() => {
      this.state.plainPassword = password;
    });
  };

  public setPlainPasswordRepeat = (password: string): void => {
    runInAction(() => {
      this.state.plainPasswordRepeat = password;
    });
  };

  public changePassword = async (
    token: string,
    plainPassword: string
  ): Promise<any> => {
    try {
      // Set the loading property to true using Mobx runInAction

      runInAction(() => {
        this.state.loading = true;
      });

      // Call the loginByEmail method of AuthRepository to authenticate the user

      const response = await this.repositories.auth.changingPassword(token, {
        plainPassword,
      });

      runInAction(() => {
        this.state.loading = false;
      });

      // Return the authenticated user

      return response;
    } catch (error: any) {
      // Set the loading property to false using Mobx runInAction in case of an error

      runInAction(() => {
        this.state.loading = false;
      });

      if (isAxiosError(error)) {
        if (error.status === 404)
          throw new Error(
            TranslationService.t("txt_user_with_credentials_not_exist")
          );
      }

      // Throw the error to the caller

      throw error;
    }
  };

  // Define a no-op method beforeDestroy

  public beforeDestroy = (): void => {
    // No-op method
  };
}
