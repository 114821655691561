import React, { useContext } from "react";
import {
  IScenesInstance,
  TSceneConstructorParams,
} from "@viewModels/scenes/__types__/ScenesInstance";

export const ScenesContext = React.createContext<
  [string, IScenesInstance<any, TSceneConstructorParams>][] | null
>(null);

export const useScenes = (): [
  string,
  IScenesInstance<any, TSceneConstructorParams>
][] => {
  const context = useContext(ScenesContext);
  if (context === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return context;
};
