const IconImage = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M5.11039 18.6654H16.0882C16.5055 18.6654 16.9187 18.5832 17.3043 18.4235C17.6898 18.2638 18.0401 18.0297 18.3352 17.7346C18.6303 17.4395 18.8644 17.0892 19.0241 16.7037C19.1838 16.3181 19.2659 15.9049 19.2659 15.4876V4.50981C19.2659 4.0925 19.1838 3.67927 19.0241 3.29373C18.8644 2.90818 18.6303 2.55786 18.3352 2.26278C18.0401 1.9677 17.6898 1.73362 17.3043 1.57393C16.9187 1.41423 16.5055 1.33203 16.0882 1.33203H5.11039C4.2676 1.33203 3.45932 1.66683 2.86337 2.26278C2.26742 2.85873 1.93262 3.66701 1.93262 4.50981V15.4876C1.93262 15.9049 2.01481 16.3181 2.17451 16.7037C2.33421 17.0892 2.56828 17.4395 2.86337 17.7346C3.15845 18.0297 3.50877 18.2638 3.89431 18.4235C4.27986 18.5832 4.69308 18.6654 5.11039 18.6654ZM3.66595 4.50981C3.66595 4.12672 3.81813 3.75932 4.08902 3.48843C4.3599 3.21755 4.7273 3.06536 5.11039 3.06536H16.0882C16.4713 3.06536 16.8387 3.21755 17.1095 3.48843C17.3804 3.75932 17.5326 4.12672 17.5326 4.50981V10.5071L15.9553 8.92981C15.5311 8.50589 14.956 8.26776 14.3563 8.26776C13.7566 8.26776 13.1815 8.50589 12.7573 8.92981L9.86839 11.8187L8.71284 10.6631C8.28867 10.2392 7.71353 10.0011 7.11384 10.0011C6.51415 10.0011 5.93901 10.2392 5.51484 10.6631L3.66595 12.5294V4.50981ZM3.66595 14.9791L6.75995 11.888C6.86217 11.7946 6.99561 11.7429 7.13406 11.7429C7.27251 11.7429 7.40596 11.7946 7.50817 11.888L8.52506 12.9049C8.88369 13.2634 9.37 13.4648 9.87706 13.4648C10.3841 13.4648 10.8704 13.2634 11.2291 12.9049L13.9822 10.1518C14.0821 10.0539 14.2164 9.99903 14.3563 9.99903C14.4962 9.99903 14.6305 10.0539 14.7304 10.1518L17.5326 12.9569V15.4876C17.5326 15.8707 17.3804 16.2381 17.1095 16.509C16.8387 16.7798 16.4713 16.932 16.0882 16.932H5.11039C4.7273 16.932 4.3599 16.7798 4.08902 16.509C3.81813 16.2381 3.66595 15.8707 3.66595 15.4876V14.9791Z"
      fill="#303B57"
    />
    <path
      d="M9.09928 7.66536C9.74362 7.66536 10.266 7.14303 10.266 6.4987C10.266 5.85437 9.74362 5.33203 9.09928 5.33203C8.45495 5.33203 7.93262 5.85437 7.93262 6.4987C7.93262 7.14303 8.45495 7.66536 9.09928 7.66536Z"
      fill="#303B57"
    />
  </svg>
);

export default IconImage;
