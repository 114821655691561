import { TDtoRegionListItemEntry } from "./__types__/TDtoRegionListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoRegionListItemParser: TParseDto<TDtoRegionListItemEntry> = (
  data: any
): TDtoRegionListItemEntry => ({
  // contractors: Array.isArray(data.contractors)
  //   ? data.contractors.map((contractor: any) => ({
  //       id:
  //         typeof data.id === "number"
  //           ? Number(contractor.id)
  //           : Utils.getRandomInt(0, 1000000),
  //       name: Utils.isString(contractor.name) ? data.name : "",
  //       regions: Array.isArray(contractor.regions)
  //         ? contractor.regions.filter(Boolean).map((region: any) => region)
  //         : [],
  //     }))
  //   : [],
  contractors: Array.isArray(data.contractors)
    ? data.contractors.map((contractor: any) => Number(contractor))
    : [],
  id:
    typeof data.id === "number"
      ? Number(data.id)
      : Utils.getRandomInt(0, 1000000),
  name: Utils.isString(data.name) ? data.name : "",
});
