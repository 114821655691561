import {
  Instance,
  applySnapshot,
  types,
  SnapshotIn,
  SnapshotOut,
} from "mobx-state-tree";

export const SystemTreeModel = types
  .model({
    isOpenedModalLogout: types.optional(types.boolean, false),
    isOpenedSideMenu: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    closeSideMenu: (): void => {
      applySnapshot(self, {
        ...self,
        isOpenedSideMenu: false,
      });
    },
    openSideMenu: (): void => {
      applySnapshot(self, {
        ...self,
        isOpenedSideMenu: true,
      });
    },
    toggleModalLogout: (): void => {
      applySnapshot(self, {
        ...self,
        isOpenedModalLogout: !self.isOpenedModalLogout,
      });
    },
    toggleSideMenu: (): void => {
      applySnapshot(self, {
        ...self,
        isOpenedSideMenu: !self.isOpenedSideMenu,
      });
    },
  }));

export type ISystemTreeModel = Instance<typeof SystemTreeModel>;
export type ISystemTreeModelIn = SnapshotIn<ISystemTreeModel>;
export type ISystemTreeModelOut = SnapshotOut<ISystemTreeModel>;
