import { TDtoContractListEntry } from "./__types__/TDtoContractListEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoContractListParser: TParseDto<TDtoContractListEntry> = (
  data: any
): TDtoContractListEntry =>
  Array.isArray(data.list)
    ? data.list.map((listElement: any) => ({
        conclusionDate: Utils.isString(listElement.conclisionDate)
          ? listElement.conclusionDate
          : "",
        contractor: {
          id: Number(listElement.contractor.id),
          name: Utils.isString(listElement.name) ? listElement.name : "",
          regions: Array.isArray(listElement.regions)
            ? listElement.regions.map((region: any) => ({
                contractors: Array.isArray(region.contractors)
                  ? region.map((element: any) =>
                      Utils.isString(element) ? element : ""
                    )
                  : [],
                id: Utils.isString(region.id) ? region.id : "",
                name: Utils.isString(region.name) ? region.name : "",
              }))
            : [],
        },
        id: Number(listElement.id),
        number: Utils.isString(listElement.number) ? listElement.number : "",
        region: {
          contractors: Array.isArray(listElement.region.contractors)
            ? listElement.region.map((element: any) =>
                Utils.isString(element) ? element : ""
              )
            : [],
          id: Number(listElement.region.id),
          name: Utils.isString(listElement.region.name)
            ? listElement.region.name
            : "",
        },
        termDate: Utils.isString(listElement.termDate)
          ? listElement.termDate
          : "",
      }))
    : [];
