import { IScreenControllerFuelingTurnover } from "@screens/HomeScreen/connector/__types__/UIViewController";
import Pagination from "@shared/controls/Pagination/Pagination";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";

export const FuelingTurnoverPagination = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingTurnover;
  }>();

  return (
    <Pagination
      count={UIViewController.listOfItemsForTable.length}
      total={UIViewController.listOfItemsForTable.length}
      per_page={UIViewController.listOfItemsForTable.length}
      loading={UIViewController.isRequestInProgress}
    />
  );
});
