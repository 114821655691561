import { TDtoVehicleListItemEntry } from "./__types__/TDtoVehicleListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoVehicleListItemParser: TParseDto<TDtoVehicleListItemEntry> = (
  data: any
): TDtoVehicleListItemEntry => ({
  id:
    typeof data.id === "number"
      ? Number(data.id)
      : Utils.getRandomInt(0, 1000000),
  number: Utils.isString(data.number) ? data.number : "",
});
