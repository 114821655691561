import Joi from "joi";

export const DtoFuelingTurnoverContractListSubItemScheme = Joi.object({
  dt: Joi.string().required().allow(""),
  number: Joi.string().required().allow(""),
  remains: Joi.number().required().allow(""),
  type: Joi.string().required().allow(""),
  volume: Joi.number().required().allow(""),
  weight: Joi.number().required().allow(""),
});

export const DtoFuelingTurnoverContractListItemScheme = Joi.object({
  list: Joi.array().items(DtoFuelingTurnoverContractListSubItemScheme),
});
