import { IScreenControllerAuth } from "@screens/AuthorizationScreen/connector/__types__/UIViewController";
import { TranslationService } from "@services/translate";
import Button from "@shared/controls/Button/Button";
import Input from "@shared/controls/Input/Input";
import { IconArrowLeftLong } from "@shared/controls/icons";
import { useControllers } from "@view/hooks/useControllers";
import { observer } from "mobx-react";
import { useCallback } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

export const ForgetPassword = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerAuth;
  }>();

  const errorEmail = false;

  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      UIViewController.submitResetEmail(UIViewController.changingEmail);
      if (UIViewController.setPage) UIViewController.setPage("sending");
    },
    [UIViewController.changingEmail]
  );

  return (
    <>
      <Helmet>
        <title>{TranslationService.t("txt_forget_password")}</title>
      </Helmet>
      <form onSubmit={onSubmit}>
        <Back className="row" onClick={() => UIViewController.setPage("main")}>
          <IconArrowLeftLong />
          <span>{TranslationService.t("txt_back")}</span>
        </Back>
        <div className="container col">
          <h2>{TranslationService.t("txt_forget_password")}</h2>
          <div className="col gap20">
            <Hint>{TranslationService.t("txt_forget_password_info")}</Hint>
            <div className={`customField ${errorEmail ? "error" : ""}`}>
              <label>{TranslationService.t("txt_email_field")}</label>
              <Input
                type="email"
                value={UIViewController.changingEmail}
                placeholder={TranslationService.t("txt_input_email")}
                onChange={UIViewController.setChangingEmail}
              />
              {errorEmail ? (
                <p className="error-msg">
                  {TranslationService.t("txt_error_no_user_email")}
                </p>
              ) : null}
            </div>
          </div>
          <Button
            loading={UIViewController.isRequestInProgress}
            text={TranslationService.t("txt_send_link")}
            disabled={!UIViewController.changingEmail}
            className={"primary"}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
});

export const Back = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Hint = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
`;
