import { TDtoNewScoreEntry } from "./__types__/TDtoNewScoreEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoNewScoreParser: TParseDto<TDtoNewScoreEntry> = (
  data: any
): TDtoNewScoreEntry => ({
  contract: Number(data.number),
  deliveryDate: Utils.isString(data.deliveryDate) ? data.deliveryDate : "",
  number: Utils.isString(data.number) ? data.number : "",
  price: Number(data.price),
  region: Number(data.region),
  weight: Number(data.weight),
});
