import { UserRoles } from "@constants/roles";
import Joi from "joi";

export const DtoUserScheme = Joi.object({
  email: Joi.string().required().allow(""),
  // id: Joi.number().required(),
  name: Joi.string().required().allow(""),
  roles: Joi.array().items(
    Joi.string().allow(UserRoles.User, UserRoles.EmployeeGSM, UserRoles.Kvs)
  ),
  username: Joi.string().required().allow(""),
});
