import { IRootTreeModel } from "..";
import { VehiclesListViewModel } from "@viewModels/VehiclesListViewModel/VehiclesListViewModel";
import { ScoresListViewModel } from "@viewModels/ScoreListViewModel/ScoresListViewModel";
import {
  Instance,
  types,
  SnapshotIn,
  SnapshotOut,
  getRoot,
  IAnyStateTreeNode,
} from "mobx-state-tree";
import { ContractsListViewModel } from "@viewModels/ContractsListViewModel/ContractsListViewModel";
import { ContractorsListViewModel } from "@viewModels/ContractorsListViewModel/ContractorsListViewModel";
import { RegionsListViewModel } from "@viewModels/RegionsListViewModel/RegionsListViewModel";

export const StorageTreeModel = types
  .model({
    scores: types.optional(types.boolean, true),
  })
  .extend((_extendable: IAnyStateTreeNode) => {
    const root: IRootTreeModel = getRoot(_extendable);

    const viewModelList = {
      contractorsViewModel: new ContractorsListViewModel(root, {
        per_page: 1000,
      }),
      contractsViewModel: new ContractsListViewModel(root, {
        per_page: 1000,
        search: "",
      }),
      regionsViewModel: new RegionsListViewModel(root, {
        per_page: 1000,
      }),
      scoresViewModel: new ScoresListViewModel(root, {
        per_page: 1000,
        search: "",
      }),
      vehiclesViewModel: new VehiclesListViewModel(root, {
        per_page: 1000,
      }),
    };

    return {
      actions: {},
      state: {
        _viewModelList: viewModelList,
      },
      views: {
        /** Request statuses for current model */
        // _viewModelList: viewModelList,
      },
    };
  })
  .views((self) => ({
    get storage() {
      return {
        contractors: self._viewModelList.contractorsViewModel.metadata,
        contracts: self._viewModelList.contractsViewModel.metadata,
        regions: self._viewModelList.regionsViewModel.metadata,
        scores: self._viewModelList.scoresViewModel.metadata,
        vehicles: self._viewModelList.vehiclesViewModel.metadata,
      };
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // this.fetchScores();
      // this.fetchVehicles();
      // this.fetchContractors();
      // this.fetchContracts();
      // this.fetchRegions();
    },

    fetchContractors: async () => {
      try {
        await self._viewModelList.contractorsViewModel.fetchItemsBatch(1, true);
      } catch (error) {
        throw error;
      }
    },

    fetchContracts: async () => {
      try {
        await self._viewModelList.contractsViewModel.fetchItemsBatch(1, true);
      } catch (error) {
        throw error;
      }
    },

    fetchRegions: async () => {
      try {
        await self._viewModelList.regionsViewModel.fetchItemsBatch(1, true);
      } catch (error) {
        throw error;
      }
    },

    fetchScores: async () => {
      try {
        await self._viewModelList.scoresViewModel.fetchItemsBatch(1, true);
      } catch (error) {
        throw error;
      }
    },

    fetchVehicles: async () => {
      try {
        await self._viewModelList.vehiclesViewModel.fetchItemsBatch(1, true);
      } catch (error) {
        throw error;
      }
    },
  }));

export type IStorageTreeModel = Instance<typeof StorageTreeModel>;
export type IStorageTreeModelIn = SnapshotIn<IStorageTreeModel>;
export type IStorageTreeModelOut = SnapshotOut<IStorageTreeModel>;
