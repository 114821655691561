import { TDtoScoreListItemEntry } from "./__types__/TDtoScoreListItemEntry.types";
import { TParseDto } from "../__types__/dto";
import { Utils } from "../..";

export const DtoScoreListItemParser: TParseDto<TDtoScoreListItemEntry> = (
  data: any
): TDtoScoreListItemEntry => ({
  contractor: {
    id: Number(data.contract.contractor.id),
    name: Utils.isString(data.contract.contractor.name)
      ? data.contract.contractor.name
      : "",
  },
  deliveryDate: Utils.isString(data.deliveryDate) ? data.deliveryDate : "",
  id: Number(data.id),
  number: Utils.isString(data.contract.number) ? data.contract.number : "",
  numberScore: Utils.isString(data.number) ? data.number : "",
  price: String(data.price) || "0",
  regions: Array.isArray(data.regions)
    ? data.regions.map((region: any) => ({
        // contractors: Array.isArray(region.contractors)
        //   ? region.map((element: any) =>
        //       Utils.isString(element) ? element : ""
        //     )
        //   : [],
        id: Number(region.id),
        name: Utils.isString(region.name) ? region.name : "",
      }))
    : [],
  weight: String(data.weight) || "0",
});
