import { IScreenControllerFueling } from "./connector/__types__/UIViewController";
import { ScreenControllerFueling } from "./connector/UIViewController";
import { FuelingPagination } from "./components/Pagination/Pagination";
import { FuelingTable } from "./components/Table/Table";
import { FuelingDateFilter } from "./components/DateFilter/DateFilter";
import "./styles.scss";
import { SortingMobile } from "./components/SortingMobile/SortingMobile";
import styled from "styled-components";
import { useControllers } from "@view/hooks/useControllers";
import { useEffect } from "react";
import { observer } from "mobx-react";
import withControllers from "@view/providers/__proto__/withController";
import { TranslationService } from "@services/translate";
import { IconFilter, IconPlus } from "@shared/controls/icons";
import Button from "@shared/controls/Button/Button";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Screens } from "@constants/screens";

const FuelingScreen = observer((): JSX.Element => {
  const windowWidth = useWindowWidth();

  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFueling;
  }>();

  useEffect(() => {
    UIViewController.fetchListOfFueling();
    UIViewController.setFieldOrderBy("createdAt", "desc");
  }, []);

  return (
    <Col32 className="col">
      <Helmet>
        <title>{TranslationService.t("txt_title_fueling")}</title>
      </Helmet>
      <h2>{TranslationService.t("txt_title_fueling")}</h2>
      <ColParameters>
        <div className="row justify-between align-start">
          <RowGap className="row">
            {windowWidth <= 768 ? (
              <>
                <FuelingDateFilter />
                <SortingMobile />
              </>
            ) : null}
            <Button
              className={`${
                UIViewController.filterCount ? "primary" : "secondary"
              } w-fit-content ${windowWidth > 768 ? "" : "gap0"}`}
              size="small"
              text={
                windowWidth > 768
                  ? UIViewController.filterCount
                    ? TranslationService.t("txt_filter") +
                      ` ${UIViewController.filterCount}`
                    : TranslationService.t("txt_filter")
                  : ""
              }
              type={"button"}
              iconLeft={<IconFilter />}
              onClick={UIViewController.openFuelingFiltersScene}
            />
            {windowWidth > 768 ? <FuelingDateFilter /> : null}
          </RowGap>
          <RowGap className="row">
            <Link to={Screens.FuelingCreate.path()}>
              <Button
                text={
                  windowWidth > 1024
                    ? TranslationService.t("txt_fueling_add_btn")
                    : TranslationService.t("txt_doc_add_btn_short")
                }
                type={"button"}
                className={"primary"}
                size="small"
                iconLeft={windowWidth > 768 ? <IconPlus /> : <></>}
              />
            </Link>
          </RowGap>
        </div>
        <FuelingTable />
      </ColParameters>
      <FuelingPagination />
    </Col32>
  );
});

export default withControllers(ScreenControllerFueling)(FuelingScreen);

const Col32 = styled.div`
  gap: 32px;
  height: 100%;
`;

const ColParameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  .custom-select {
    max-width: 325px;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const RowGap = styled.div`
  gap: 14px;
`;
