import { IScreenControllerReportsByContract } from "@screens/ReportByContractsScreen/connector/__types__/UIViewController";
import Button from "@shared/controls/Button/Button";
import { IconCross, IconDownload } from "@shared/controls/icons";
import { useControllers } from "@view/hooks/useControllers";
import { useWindowWidth } from "@view/hooks/useWindowWidth";
import { observer } from "mobx-react";
import { useState } from "react";
import styled from "styled-components";
import Offcanvas from "react-bootstrap/Offcanvas";

export const ReportsByContractsActions = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerReportsByContract;
  }>();

  const [isOpenPrint, setIsOpenPrint] = useState(false);

  const windowWidth = useWindowWidth();

  return (
    <RowGap className="row">
      {windowWidth > 1024 ? (
        <>
          <Button
            type="button"
            text={"PDF"}
            iconLeft={<IconDownload />}
            onClick={UIViewController.downloadPDFReport}
            size="small"
          />
          <Button
            type="button"
            text={"Excel"}
            iconLeft={<IconDownload />}
            onClick={UIViewController.downloadExcelReport}
            size="small"
          />
          <Button
            className="primary"
            type="button"
            text={"Печать"}
            onClick={UIViewController.downloadPrintReport}
            size="small"
          />
        </>
      ) : (
        <>
          <Button
            className="secondary gap0"
            type="button"
            iconLeft={<IconDownload />}
            onClick={() => setIsOpenPrint(true)}
          />
          <Offcanvas
            show={isOpenPrint}
            onHide={setIsOpenPrint}
            placement={"bottom"}
          >
            <Offcanvas.Header>
              <Offcanvas.Title>Скачать</Offcanvas.Title>
              <IconCross onClick={() => setIsOpenPrint(false)} />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="col w-100 align-stretch gap10">
                <Button
                  className="secondary"
                  type="button"
                  text={"PDF"}
                  onClick={UIViewController.downloadPDFReport}
                  size="small"
                />
                <Button
                  className="secondary"
                  type="button"
                  text={"Excel"}
                  onClick={UIViewController.downloadExcelReport}
                  size="small"
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </RowGap>
  );
});

const RowGap = styled.div`
  gap: 14px;
  button {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 768px) {
    button {
      padding: 9px 12px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
