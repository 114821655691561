const IconOption = (): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.95801 8.22608C3.95801 7.70985 4.42778 7.28581 4.99967 7.28581C5.57157 7.28581 6.04134 7.70985 6.04134 8.22608C6.04134 8.7423 5.57157 9.16634 4.99967 9.16634C4.42778 9.16634 3.95801 8.7423 3.95801 8.22608ZM4.99967 2.71354C5.57157 2.71354 6.04134 2.2895 6.04134 1.77327C6.04134 1.25705 5.57157 0.833008 4.99967 0.833008C4.42778 0.833008 3.95801 1.25705 3.95801 1.77327C3.95801 2.2895 4.42778 2.71354 4.99967 2.71354ZM4.99967 5.93994C5.57157 5.93994 6.04134 5.5159 6.04134 4.99968C6.04134 4.48345 5.57157 4.05941 4.99967 4.05941C4.42778 4.05941 3.95801 4.48345 3.95801 4.99968C3.95801 5.5159 4.42778 5.93994 4.99967 5.93994Z"
      fill="#303B57"
    />
  </svg>
);
export default IconOption;
